import {APIService} from './APIService';

export class TransactionsService extends APIService {

    static async purchaseClassPass(programPricingUid, quantity, accessToken) {

        return await this.makeRequest(
            'api/transactions/purchase-class-pass',
            {
                programPricingUid,
                quantity
            },
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_POST,
        );
    }

    static async joinMembership(planUid, accessToken) {

        return await this.makeRequest(
            'api/transactions/join-membership',
            {
                planUid,
            },
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_POST,
        );
    }

    static async purchaseRecording(recordingUid, recordingType, accessToken) {

        return await this.makeRequest(
            'api/transactions/purchase-recording',
            {
                recordingUid,
                recordingType
            },
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_POST,
        );
    }

    static async fetchTransactionStatus(stripeCheckoutSessionId, accessToken) {

        return await this.makeRequest(
            'api/transactions/fetch-status',
            {
                stripeCheckoutSessionId
            },
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_POST,
        );
    }

}
