import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    IconButton, Select, TextField,
    Typography
} from "@mui/material";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import {Facebook, Google, Twitter} from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {AuthService} from "../../../services/AuthService";
import {StorageService} from "../../../services/StorageService";
import {useTranslation} from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import useJumboTheme from "../../../../@jumbo/hooks/useJumboTheme";
import {SUPPORTED_LOCALES} from "../../../utils/locales";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {
    trackActivateAccountEvent,
    trackPageViewEvent,
    trackRequestFailedEvent,
    trackSignUpEvent
} from "../../../utils/tracking";

const Signup2 = ({disableSmLogin}) => {
    const {t, i18n} = useTranslation();

    const validationSchema = yup.object({
        name: yup
            .string(t('app.nameFieldFormValidationLabel'))
            .required(t('app.nameFieldFormValidationError')),
        email: yup
            .string(t('app.emailFieldFormValidationLabel'))
            .email(t('app.emailFieldFormValidationMessage'))
            .required(t('app.emailFieldFormValidationError')),
        password: yup
            .string(t('app.passwordFieldFormValidationLabel'))
            .min(6, t('app.passwordFieldFormValidationMessage'))
            .required(t('app.passwordFieldFormValidationError')),
    });

    const {setAuthToken} = useJumboAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const returnUrl = searchParams.get('returnUrl') || null;
    const { locale } = useParams();


    const defaultLanguage = SUPPORTED_LOCALES[0]


    const [languages, setLanguages] = useState(SUPPORTED_LOCALES);
    const [selectedLanguage, setSelectedLanguage] = useState(StorageService.loadAppLocale()?.locale || defaultLanguage.locale);
    const [selectedLanguageObject, setSelectedLanguageObject] = useState(defaultLanguage);

    const [isStepOneVisible, setIsStepOneVisible] = useState(true);
    const [isStepTwoVisible, setIsStepTwoVisible] = useState(false);
    const [isStepThreeVisible, setIsStepThreeVisible] = useState(false);
    const [accountActivationCode, setAccountActivationCode] = useState('');
    const [isLoadingActivateAccount, setIsLoadingActivateAccount] = useState(false);

    const {setMuiLocale} = useJumboTheme();
    const [activeLocale, setActiveLocale] = React.useState(languages[1]);

    const [user, setUser] = useState();

    useEffect(() => {
        trackPageViewEvent('sign_up', '/signup');
        changeLanguage(locale || selectedLanguage);

    }, []);

    const changeLanguage = (value) => {
        setSelectedLanguage(value);

        const localeIndex = languages.findIndex(language => language.locale === value);
        if (localeIndex !== -1) {
            i18n.changeLanguage(languages[localeIndex].locale).then(() => {
                setActiveLocale(languages[localeIndex]);
                setMuiLocale(languages[localeIndex].muiLocale);

                StorageService.saveAppLocale(languages[localeIndex]);

                setSelectedLanguageObject(languages[localeIndex]);
            });
        }
    }


    const handleLanguageChange = useCallback(
        (e) => {
            const value = e.target.value;
            setSelectedLanguage(value);

            const localeIndex = languages.findIndex(language => language.locale === value);
            if (localeIndex !== -1) {
                i18n.changeLanguage(languages[localeIndex].locale).then(() => {
                    setActiveLocale(languages[localeIndex]);
                    setMuiLocale(languages[localeIndex].muiLocale);

                    StorageService.saveAppLocale(languages[localeIndex]);

                    setSelectedLanguageObject(languages[localeIndex]);
                });
            }
        },
        [setSelectedLanguage],
    );

    const handleNext = () => {
        setIsStepOneVisible(false);
        setIsStepTwoVisible(true);
        setIsStepThreeVisible(false);

    }

    const handleBack = () => {
        setIsStepOneVisible(true);
        setIsStepTwoVisible(false);
        setIsStepThreeVisible(false);

    }

    const onSignUp = async (name, email, password) => {

        const response = await AuthService.signUp(name, email, password, selectedLanguageObject.locale,
            selectedLanguageObject.language, selectedLanguageObject.localizedLanguage);

        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        // @ts-ignore
        if (response.ok) {
            if (data) {

                trackSignUpEvent();
                setUser(data.user);


                setIsStepOneVisible(false);
                setIsStepTwoVisible(false);
                setIsStepThreeVisible(true);
            } else {
                trackRequestFailedEvent('sign_up')
                alert(data.message);
            }
        } else {
            trackRequestFailedEvent('sign_up')
            alert(message);
        }
    };

    const handleFieldChange = useCallback(
        (callback, e) => {
            const value = e.target.value;
            callback(value);

        },
        [setAccountActivationCode],
    );

    const activateAccount = async () => {

        setIsLoadingActivateAccount(true);

        const response = await AuthService.activateAccount(accountActivationCode, user?.token);

        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoadingActivateAccount(false);
        // @ts-ignore
        if (response.ok) {
            if (data) {
                trackActivateAccountEvent();
                setAuthToken(data.user.token)
                StorageService.saveUser(data.user);
                StorageService.saveSupportedLocales(SUPPORTED_LOCALES);

                navigate(returnUrl ? `/welcome?returnUrl=${encodeURIComponent(returnUrl)}` :"/welcome", {replace: true});


            } else {
                trackRequestFailedEvent('activate_account');
                alert(data.message);
            }
        } else {
            trackRequestFailedEvent('activate_account');
            alert(message);
        }
    };
    return (
        <>
            <Div sx={{
                flex: 1,
                flexWrap: 'wrap',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: theme => theme.spacing(4),
            }}>
                <Box sx={{
                    width: 720,
                    maxWidth: '100%',
                    margin: 'auto',
                    marginBottom: '20px',
                    p: 4}}>
                    <Box align={'center'}>
                        <img src={`${ASSET_IMAGES}/drehmoment_logo.svg`} alt="Drehmoment" />

                    </Box>
                    <Card>
                        <CardContent>
                            {/*<Typography variant="h3">*/}
                            {/*    {t('app.welcomeMessage')} {t('app.name')}*/}

                            {/*</Typography>*/}
                            <Typography
                                variant="h1"
                                mb={.25}
                                sx={{ fontWeight: 'bold', textTransform:'uppercase' }}
                                gutterBottom>
                                {t('app.createYourAccountMessage')}
                            </Typography>

                            <Box mt={5}>
                                {isStepOneVisible && (
                                    <Box>
                                        <Box sx={{minHeight: 300, height: 'auto'}}>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                {t('app.selectYourLanguageLabel')}
                                            </Typography>
                                            <Box>
                                                <Select
                                                    labelId="tone-label"
                                                    value={selectedLanguage}
                                                    onChange={handleLanguageChange}
                                                    fullWidth
                                                    label={'app.selectLanguageLabel'}
                                                    sx={{ marginBottom: 2 }}
                                                >
                                                    {SUPPORTED_LOCALES.map( language => (
                                                        <MenuItem value={language.locale}>{language.localizedLanguage}</MenuItem>
                                                    ))}
                                                </Select>
                                            </Box>
                                        </Box>

                                        <Div>
                                            <Button variant={'outlined'} onClick={handleNext}>
                                                {t('app.nextButtonLabel')}
                                            </Button>
                                        </Div>
                                    </Box>
                                )}

                                {isStepTwoVisible && (
                                    <Box>
                                        <Formik
                                            validateOnChange={true}
                                            initialValues={{
                                                name: '',
                                                email: '',
                                                password: '',
                                            }}
                                            validationSchema={validationSchema}
                                            onSubmit={(data, {setSubmitting}) => {
                                                setSubmitting(true);
                                                onSignUp(data.name, data.email, data.password);
                                                setSubmitting(false);
                                            }}
                                        >
                                            {({isSubmitting}) => (
                                                <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                                                    <Div sx={{mt: 1, mb: 3}}>
                                                        <JumboTextField
                                                            fullWidth
                                                            name="name"
                                                            label={t('app.nameLabel')}
                                                            type="text"
                                                        />
                                                    </Div>

                                                    <Div sx={{mt: 1, mb: 3}}>
                                                        <JumboTextField
                                                            fullWidth
                                                            name="email"
                                                            label={t('app.emailLabel')}
                                                            type="email"
                                                        />
                                                    </Div>
                                                    <Div sx={{mt: 1, mb: 2}}>
                                                        <JumboTextField
                                                            fullWidth
                                                            name="password"
                                                            label={t('app.passwordLabel')}
                                                            type="password"
                                                        />
                                                    </Div>
                                                    <LoadingButton
                                                        fullWidth
                                                        type="submit"
                                                        variant="contained"
                                                        size="large"
                                                        sx={{mb: 3}}
                                                        loading={isSubmitting}
                                                    >
                                                        {t('app.signUpButtonLabel')}
                                                    </LoadingButton>

                                                    <Typography variant={"body1"}>
                                                        {t('app.alreadyHaveAnAccountLabel')}
                                                        <Link
                                                            href={"/login"}
                                                            color={"inherit"}
                                                            // underline={'none'}
                                                        >
                                                             {t('app.logInHereLinkLabel')}
                                                        </Link>
                                                    </Typography>

                                                    {
                                                        !disableSmLogin && (
                                                            <React.Fragment>
                                                                <Typography variant={"body1"} mb={2}>Or sign in with</Typography>
                                                                <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                                                    <IconButton sx={{
                                                                        bgcolor: '#385196',
                                                                        color: 'common.white',
                                                                        p: theme => theme.spacing(1.25),

                                                                        '&:hover': {
                                                                            backgroundColor: '#385196',
                                                                        }
                                                                    }} aria-label="Facebook">
                                                                        <Facebook fontSize="small"/>
                                                                    </IconButton>
                                                                    <IconButton sx={{
                                                                        bgcolor: '#00a8ff',
                                                                        color: 'common.white',
                                                                        p: theme => theme.spacing(1.25),

                                                                        '&:hover': {
                                                                            backgroundColor: '#00a8ff',
                                                                        }
                                                                    }} aria-label="Twitter">
                                                                        <Twitter fontSize="small"/>
                                                                    </IconButton>
                                                                    <IconButton sx={{
                                                                        bgcolor: '#23272b',
                                                                        color: 'common.white',
                                                                        p: theme => theme.spacing(1.25),

                                                                        '&:hover': {
                                                                            backgroundColor: '#23272b',
                                                                        }
                                                                    }} aria-label="Twitter">
                                                                        <Google fontSize="small"/>
                                                                    </IconButton>
                                                                </Stack>
                                                            </React.Fragment>
                                                        )
                                                    }

                                                </Form>
                                            )}
                                        </Formik>

                                        <Div>
                                            <Button variant={'outlined'} onClick={handleBack}
                                                    sx={{marginRight:1}}>
                                                {t('app.backButtonLabel')}
                                            </Button>
                                        </Div>
                                    </Box>
                                )}

                                {isStepThreeVisible && (
                                    <Box>
                                        <Box sx={{minHeight: 300, height: 'auto'}}>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                {t('app.enterAccountActivationCode')}
                                            </Typography>
                                            <Box>
                                                <TextField
                                                    label={t('app.accountActivationCodeLabel')}
                                                    value={accountActivationCode}
                                                    fullWidth
                                                    onChange={(e) =>
                                                    {handleFieldChange(setAccountActivationCode, e)}}
                                                    sx={{ marginBottom: 2  }}
                                                />
                                            </Box>
                                        </Box>
                                        <Div>
                                            <LoadingButton loading={isLoadingActivateAccount}  variant={'contained'} onClick={activateAccount}>
                                                {t('app.activateAccountButtonLabel')}
                                            </LoadingButton>
                                        </Div>
                                    </Box>
                                )}

                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Div>


        </>

    );
};

export default Signup2;
