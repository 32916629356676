export const STATUS_ACTIVE = 'Active';
export const STATUS_CANCELED = 'Canceled';
export const STATUS_IN_PROGRESS = 'In Progress';
export const STATUS_STARTING_SOON = 'Starting Soon';
export const STATUS_ENDED = 'Ended';
export const STATUS_UPCOMING = 'Upcoming';

export const EURO_SIGN= '€';

export const PRICING_TYPE_INSTALMENTAL = 'Instalmental';
export const PRICING_TYPE_ONE_TIME = 'One Time';

export const PROGRAM_TYPE_FIXED_COURSE = 'Fixed Course';
export const PROGRAM_TYPE_DROP_IN = 'Drop In';

export const IS_MEMBERSHIP_CONTENT = 1;
export const IS_NOT_MEMBERSHIP_CONTENT = 0;