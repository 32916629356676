import React from 'react';
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import {ASSET_IMAGES} from "../../utils/constants/paths";
import {Typography} from "@mui/material";


const Logo = ({mini, mode, sx}) => {
    return (
        <Div sx={{display: "inline-flex", ...sx}}>
            {/*<Link href={'/'}>*/}
            {/*    {*/}
            {/*        !mini ?*/}
            {/*            <img src={ mode === "light" ? `${ASSET_IMAGES}/grayscript_logo.png` : `${ASSET_IMAGES}/grayscript_logo_white.png`} alt="Grayscript" />*/}
            {/*            :*/}
            {/*            <img src={mode === "light" ? `${ASSET_IMAGES}/grayscript_logo.png` : `${ASSET_IMAGES}/grayscript_logo_white.png`} alt="Grayscript" />*/}
            {/*    }*/}
            {/*</Link>*/}

            {/*<Link href={'/'}>*/}
            {/*    <Typography variant={'h5'} sx={{marginTop: 1}}>*/}
            {/*        Drehmoment*/}
            {/*    </Typography>*/}
            {/*</Link>*/}

            <img src={`${ASSET_IMAGES}/drehmoment_sidebar_logo.svg`} alt="Drehmoment" />

        </Div>
    );
};

Logo.defaultProps = {
    mode: "light"
};

export default Logo;
