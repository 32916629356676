import React from "react";

import Page from "@jumbo/shared/Page";
import Profile from "../pages/profile";


const dashboardRoutes = [

    {
        path: "/profile",
        element: <Page component={Profile} />,
    }
];

export default dashboardRoutes;
