import {APIService} from "./APIService";

export class SubscriptionService extends APIService {
    static async getMembershipPlans(accessToken) {

        return await this.makeRequest(
            'api/subscriptions/plans',
            {
            },
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_POST,
        );
    }
}