import React from "react";

import Page from "@jumbo/shared/Page";
import Home from "../pages/home";
import Library from "../pages/library";
import Course from "../pages/course";
import Video from "../pages/video";
import Category from "../pages/category";
import Welcome from "../pages/welcome";
import Programs from "../pages/programs";
import Program from "../pages/program";
import Bookings from "../pages/bookings";
import AllCourses from "../pages/all-courses";
import AllVideos from "../pages/all-videos";
import Schedules from "../pages/schedules";
import {Box} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Memberships from "../pages/memberships";

const EmbeddedSchedulesComponent = () => {
    return <Div
        sx={{
            width: '100%',
            maxWidth: '100%',
            margin: 'auto',
            p: 4,
        }}
    >
        <Box>
            <Schedules/>
        </Box>
    </Div>
}
const publicRoutes = [
    {
        path: "/",
        element: <Page component={Home} />,
    },
    {
        path: "/library",
        element: <Page component={Library} />,
    },
    {
        path: "/courses",
        element: <Page component={AllCourses}/>,
    },
    {
        path: "/courses/:id",
        element: <Page component={Course}/>,
    },
    {
        path: "/videos",
        element: <Page component={AllVideos}/>,
    },
    {
        path: "/videos/:id",
        element: <Page component={Video}/>,
    },
    {
        path: "/categories/:id",
        element: <Page component={Category}/>,
    },
    {
        path: "/welcome",
        element: <Page component={Welcome} layout={"solo-page"} />,
    },
    {
        path: "/schedules",
        element: <Page component={Schedules} />,
    },
    {
        path: "/embeds/schedules",
        element: <Page component={EmbeddedSchedulesComponent} layout={"solo-page"} />,
    },
    {
        path: "/classes",
        element: <Page component={Programs} />,
    },
    {
        path: "/memberships",
        element: <Page component={Memberships} />,
    },
    {
        path: "/classes/:id",
        element: <Page component={Program}/>,
    },
    {
        path: "/bookings",
        element: <Page component={Bookings} />,
    },
];

export default publicRoutes;
