import React from 'react';
import ReactDOM from 'react-dom';
import App from "./app/App";
import './app/config/i18n';
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {firebaseConfig} from "./firebase";

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

