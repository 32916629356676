import React from "react";
import authRoutes from "./authRoutes";
import dashboardRoutes from "./dashboardRoutes";
import publicRoutes from "./publicRoutes";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
    ...publicRoutes

];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
     ...dashboardRoutes
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    ...authRoutes
];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export {routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly};