import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, Checkbox, FormControlLabel, IconButton, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import {Facebook, Google, Twitter} from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {AuthService} from "../../../services/AuthService";
import {StorageService} from "../../../services/StorageService";
import {getSupportedLocale, SUPPORTED_LOCALES} from "../../../utils/locales";
import {useTranslation} from "react-i18next";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {
    trackLoginEvent,
    trackLoginFailedEvent,
    trackPageViewEvent,
    trackRequestFailedEvent
} from "../../../utils/tracking";


const Login2 = ({disableSmLogin}) => {
    const {setAuthToken} = useJumboAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const returnUrl = searchParams.get('returnUrl') || null;
    const {t, i18n} = useTranslation();

    const validationSchema = yup.object({
        email: yup
            .string(t('app.emailFieldFormValidationLabel'))
            .email(t('app.emailFieldFormValidationMessage'))
            .required(t('app.emailFieldFormValidationError')),
        password: yup
            .string(t('app.passwordFieldFormValidationLabel'))
            .required(t('app.passwordFieldFormValidationError')),
    });

    const { locale } = useParams();

    const defaultLanguage = SUPPORTED_LOCALES[0]

    const [languages, setLanguages] = useState(SUPPORTED_LOCALES);
    const [selectedLanguage, setSelectedLanguage] = useState(StorageService.loadAppLocale()?.locale || defaultLanguage.locale);
    const [selectedLanguageObject, setSelectedLanguageObject] = useState(defaultLanguage);
    const {setMuiLocale} = useJumboTheme();
    const [activeLocale, setActiveLocale] = React.useState(languages[1]);


    useEffect(() => {
        trackPageViewEvent('login', '/login');
        changeLanguage(locale || selectedLanguage);

    }, []);

    const changeLanguage = (value) => {
        setSelectedLanguage(value);

        const localeIndex = languages.findIndex(language => language.locale === value);
        if (localeIndex !== -1) {
            i18n.changeLanguage(languages[localeIndex].locale).then(() => {
                setActiveLocale(languages[localeIndex]);
                setMuiLocale(languages[localeIndex].muiLocale);

                StorageService.saveAppLocale(languages[localeIndex]);

                setSelectedLanguageObject(languages[localeIndex]);
            });
        }
    }


    const onSignIn = async (email, password) => {

        const response = await AuthService.login(email, password);

        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        // @ts-ignore
        if (response.ok) {
            if (data) {
                trackLoginEvent();
                setAuthToken(data.user.token)
                StorageService.saveUser(data.user);

                const appLocale = getSupportedLocale(data.user.locale);
                StorageService.saveAppLocale(appLocale);

                if (data.user.hasEnteredUserInfo === 0) {
                    navigate(returnUrl ? `/welcome?returnUrl=${encodeURIComponent(returnUrl)}` :"/welcome", {replace: true});
                    return;
                }

                const postLoginUrl = returnUrl || '/';

                navigate(decodeURIComponent(postLoginUrl), {replace: true});

               // navigate('/', {replace: true});

            } else {
                trackLoginFailedEvent();
                alert(data.message);
            }
        } else {
            trackRequestFailedEvent('login')
            alert(message);
        }
    };

    return (
        <Div
            sx={{
                width: 720,
                maxWidth: '100%',
                margin: 'auto',
                p: 4,
            }}
        >
            <Box
                align={'center'}>
                {/*<Typography variant={'h2'}>*/}
                {/*    Drehmoment*/}
                {/*</Typography>*/}

                <img src={`${ASSET_IMAGES}/drehmoment_logo.svg`} alt="Drehmoment" />

            </Box>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: {xs: 'column', md: 'row'}
                }}
            >

                <CardContent sx={{flex: 1, p: 4}}
                >
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => {
                            setSubmitting(true);
                            onSignIn(data.email, data.password);
                            setSubmitting(false);
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                                <Div sx={{mt: 1, mb: 3}}>
                                    <JumboTextField
                                        fullWidth
                                        name="email"
                                        label= {t('app.emailLabel')}
                                        type="email"
                                    />
                                </Div>
                                <Div sx={{mt: 1, mb: 2}}>
                                    <JumboTextField
                                        fullWidth
                                        name="password"
                                        label= {t('app.passwordLabel')}
                                        type="password"
                                    />
                                </Div>
                                <Div sx={{mb: 2}}>
                                    <FormControlLabel control={<Checkbox/>} label={t('app.rememberMeLabel')}/>
                                </Div>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    loading={isSubmitting}
                                >{t('app.signInButtonLabel')}</LoadingButton>

                                <Typography variant={"body1"} mb={2}>
                                    <Link
                                        href={"/forgot-password"}
                                        color={"inherit"}
                                        // underline={'none'}
                                    >{t('app.forgotPasswordLabel')}</Link>
                                </Typography>

                                <Typography variant={"body1"}>
                                    {t('app.dontHaveAnAccountLabel')}
                                    <Link
                                        href={returnUrl ? `/signup?returnUrl=${encodeURIComponent(returnUrl)}` :"/signup"}
                                        color={"inherit"}
                                        // underline={'none'}
                                    >{t('app.signUpHereLabel')}</Link>
                                </Typography>

                                {
                                    !disableSmLogin && (
                                        <React.Fragment>
                                            <Typography variant={"body1"} mb={2}>Or sign in with</Typography>
                                            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                                <IconButton sx={{
                                                    bgcolor: '#385196',
                                                    color: 'common.white',
                                                    p: theme => theme.spacing(1.25),

                                                    '&:hover': {
                                                        backgroundColor: '#385196',
                                                    }
                                                }} aria-label="Facebook">
                                                    <Facebook fontSize="small"/>
                                                </IconButton>
                                                <IconButton sx={{
                                                    bgcolor: '#00a8ff',
                                                    color: 'common.white',
                                                    p: theme => theme.spacing(1.25),

                                                    '&:hover': {
                                                        backgroundColor: '#00a8ff',
                                                    }
                                                }} aria-label="Twitter">
                                                    <Twitter fontSize="small"/>
                                                </IconButton>
                                                <IconButton sx={{
                                                    bgcolor: '#23272b',
                                                    color: 'common.white',
                                                    p: theme => theme.spacing(1.25),

                                                    '&:hover': {
                                                        backgroundColor: '#23272b',
                                                    }
                                                }} aria-label="Twitter">
                                                    <Google fontSize="small"/>
                                                </IconButton>
                                            </Stack>
                                        </React.Fragment>
                                    )
                                }

                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </Div>
    );
};

export default Login2;
