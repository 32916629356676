import {APIService} from './APIService';

export class VideoService extends APIService {
    static async getPublishedVideos(accessToken, page = 1, pageSize = 8) {
        return await this.makeRequest(
            `api/videos/published?page=${page}&pageSize=${pageSize}`,
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }

    static async getOnDemandVideos(accessToken, page = 1, pageSize = 6) {
        return await this.makeRequest(
            `api/videos/on-demand?page=${page}&pageSize=${pageSize}`,
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }

    static async getVideo(uid, accessToken) {
        return await this.makeRequest(
            `api/videos/${uid}`,
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }
}
