import {APIService} from './APIService';

export class SectionService extends APIService {

    static async getPublishedSections(accessToken) {
        return await this.makeRequest(
            'api/sections/published',
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }

}
