import React, {useEffect, useState} from 'react';
import {
    Box,
    Card, CardActionArea,
    CardContent, CardMedia, Chip,
    Divider,
    Grid,
    Typography
} from "@mui/material";
import {Skeleton} from "@mui/lab";
import {useNavigate, useParams} from "react-router-dom";
import {StorageService} from "../../services/StorageService";
import {useTranslation} from "react-i18next";
import Vimeo from "@u-wave/react-vimeo";
import CATEGORY_MAP from "../../utils/categoryMap";
import {CategoryService} from "../../services/CategoryService";
import {trackPageViewEvent} from "../../utils/tracking";
import {LOCALE_DE} from "../../utils/locales";


const Category = () => {

    const {t} = useTranslation();

    const { id } = useParams();

    const categoryName = CATEGORY_MAP.get(id);


    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [courses, setCourses] = useState([]);
    const [videos, setVideos] = useState([]);

    const user = StorageService.loadUser();
    const appLocale = StorageService.loadAppLocale();


    useEffect(
        async ()  => {
            trackPageViewEvent(`${categoryName} Category`, '/categories/*');

            await getCourses();
            await getVideos();
        },
        [id],
    );


    const getCourses = async () => {

        setIsLoading(true);
        const response =  await CategoryService.getOnDemandCategoryCourses(id, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setCourses(data.courses);
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const getVideos = async () => {

        setIsLoading(true);
        const response =  await CategoryService.getOnDemandCategoryVideos(id, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setVideos(data.videos);
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }


    const viewCourse = (uid) => {
        navigate(`/courses/${uid}`);

    }

    const viewVideo = (uid) => {
        navigate(`/videos/${uid}`);

    }

    return (
        <React.Fragment>
            <Typography
                variant="h1"
                mb={.25}
                sx={{ fontWeight: 'bold', textTransform:'uppercase' }}
            >
                {categoryName}
            </Typography>
            <Typography variant={'h6'} color={'text.secondary'}>

            </Typography>

            <Divider/>

            <Box mt={5}>

                <Box>
                    {
                        isLoading && (
                            <>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </>
                        )
                    }

                    {!isLoading && (courses?.length < 1 && videos?.length < 1) && (

                        <Card>
                            <CardContent>
                                <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Typography variant={'h4'}>
                                        {t('app.noCoursesAvailableLabel')}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    )}

                    {!isLoading && courses?.length > 0 && (

                        <>
                            <Box mb={3}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        color: 'grey.600',
                                        fontWeight: 500
                                    }}
                                >
                                    {categoryName.toUpperCase()} {t('app.coursesLabel').toUpperCase()}
                                </Typography>
                            </Box>

                        <Grid container spacing={3.75} mb={3}>

                            {courses.map( (course, index) =>
                                (
                                    <>
                                        <Grid item key={index} xs={12} sm={6} lg={4}>
                                            <Card onClick={() => {viewCourse(course.uid)}}>
                                                <CardActionArea>
                                                    {course.videos.length > 0 && (
                                                        <Vimeo
                                                            video={course.videos[0].link}
                                                            autopause
                                                            showByline={false}
                                                            showTitle={false}
                                                            autopip
                                                            pip
                                                            responsive={true}
                                                            controls={false}
                                                        />
                                                    )}
                                                    {course.videos.length < 1 && (
                                                        <CardMedia
                                                            component="img"
                                                            height="140"
                                                            // image={course.image}
                                                            image={'https://mcusercontent.com/2b90d148d2217420a8235d9fd/images/152ab92c-24df-e5f5-ce82-a049af326b45.jpg'}
                                                            alt={course.name}
                                                        />
                                                    )}
                                                    <CardContent sx={{height: 50, marginBottom: 15}}>
                                                        <Typography gutterBottom variant="h5" component="div">
                                                            {appLocale.locale === LOCALE_DE ?
                                                                course.name :
                                                                course?.name_en ?
                                                                    course?.name_en : course?.name
                                                            }

                                                            {course.isFree === 1 && (
                                                                <Chip sx={{marginLeft: 1}} label={t('app.freeLabel')} color={'secondary'} size={'small'}/>
                                                            )}
                                                        </Typography>

                                                        <Typography variant="body2" color="text.secondary">
                                                            {appLocale.locale === LOCALE_DE ? (
                                                                course.description.length > 100
                                                                    ? `${course.description.substring(0, 97)}...`
                                                                    : course.description
                                                            ) : (
                                                                course?.description_en
                                                                    ? (course?.description_en?.length > 100
                                                                        ? `${course?.description_en.substring(0, 97)}...`
                                                                        : course?.description_en)
                                                                    : (course?.description?.length > 100
                                                                        ? `${course?.description.substring(0, 97)}...`
                                                                        : course?.description)
                                                            )}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>

                                                {/*<CardActions sx={{marginTop:5}}>*/}
                                                {/*    <Button sx={{marginLeft: 0}} size={"small"} variant={'contained'} onClick={() => {viewCourse(course.uid)}}>*/}
                                                {/*        {t('app.viewButtonLabel')}*/}

                                                {/*    </Button>*/}
                                                {/*</CardActions>*/}
                                            </Card>
                                        </Grid>
                                    </>
                                )
                            )}
                        </Grid>
                        <Divider />
                        </>
                    )}


                    {!isLoading && videos?.length > 0 && (

                        <>
                            <Box mb={3} mt={3}>
                                <Typography variant="h5"
                                            sx={{
                                                color: 'grey.600',
                                                fontWeight: 500
                                            }}
                                >
                                    {categoryName.toUpperCase()} {t('app.videosLabel').toUpperCase()}
                                </Typography>
                            </Box>

                            <Grid container spacing={3.75} mb={3}>

                                {videos.map( (video, index) =>
                                    (
                                        <>
                                            <Grid item key={index} xs={12} sm={6} lg={4}>
                                                <Card onClick={() => {viewVideo(video.uid)}}>
                                                    <CardActionArea>
                                                        <Vimeo
                                                            video={video.link}
                                                            autopause
                                                            showByline={false}
                                                            showTitle={false}
                                                            autopip
                                                            pip
                                                            responsive={true}
                                                            controls={false}
                                                        />
                                                        <CardContent sx={{height: 50, marginBottom: 15}}>
                                                            <Typography gutterBottom variant="h5" component="div">
                                                                {appLocale.locale === LOCALE_DE ?
                                                                    video.name :
                                                                    video?.name_en ?
                                                                        video?.name_en : video?.name
                                                                }

                                                                {video.isFree === 1 && (
                                                                    <Chip sx={{marginLeft: 1}} label={t('app.freeLabel')} color={'secondary'} size={'small'}/>
                                                                )}
                                                            </Typography>

                                                            <Typography variant="body2" color="text.secondary">
                                                                {appLocale.locale === LOCALE_DE ? (
                                                                    video.description.length > 100
                                                                        ? `${video.description.substring(0, 97)}...`
                                                                        : video.description
                                                                ) : (
                                                                    video?.description_en
                                                                        ? (video?.description_en?.length > 100
                                                                            ? `${video?.description_en.substring(0, 97)}...`
                                                                            : video?.description_en)
                                                                        : (video?.description?.length > 100
                                                                            ? `${video?.description.substring(0, 97)}...`
                                                                            : video?.description)
                                                                )}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>

                                                    {/*<CardActions sx={{marginTop: 5}}>*/}
                                                    {/*    <Button sx={{marginLeft: 0}} size={"small"} variant={'contained'} onClick={() => {viewVideo(video.uid)}}>*/}
                                                    {/*        {t('app.viewButtonLabel')}*/}

                                                    {/*    </Button>*/}
                                                    {/*</CardActions>*/}
                                                </Card>
                                            </Grid>
                                        </>
                                    )
                                )}
                            </Grid>
                        </>
                    )}
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default Category;