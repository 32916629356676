import React, {useEffect, useState} from 'react';
import {
    Box,
    Card, CardActionArea,
    CardContent, CardMedia,
    Divider,
    Grid,
    Typography
} from "@mui/material";
import {Skeleton} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import {StorageService} from "../../services/StorageService";
import {useTranslation} from "react-i18next";
import {ProgramService} from "../../services/ProgramService";
import {LOCALE_DE} from "../../utils/locales";
import {trackPageViewEvent} from "../../utils/tracking";


const Programs = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [programs, setPrograms] = useState([]);


    const user = StorageService.loadUser();
    const appLocale = StorageService.loadAppLocale();

    useEffect(
        async ()  => {
            trackPageViewEvent('classes', '/classes');

            await getPrograms();
        },
        [],
    );


    const getPrograms = async () => {

        setIsLoading(true);
        const response =  await ProgramService.getPublishedPrograms(user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setPrograms(data.programs);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }


    const viewProgram = (uid) => {
        navigate(`/classes/${uid}`);
    }


    return (
        <React.Fragment>
            <Typography
                variant="h1"
                mb={.25}
                sx={{ fontWeight: 'bold', textTransform:'uppercase' }}
            >
                {t('app.classesLabel')}
            </Typography>
            <Typography variant={'h6'} color={'text.secondary'}>

            </Typography>

            <Divider/>

            <Box mt={5}>

                <Box>
                    {
                        isLoading && (
                            <>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </>
                        )
                    }

                    {(!isLoading && programs?.length < 1) && (

                        <Card>
                            <CardContent>
                                    <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                        <Typography variant={'h4'}>
                                            {t('app.noClassesAvailable')}
                                        </Typography>
                                    </Box>
                            </CardContent>
                        </Card>
                    )}

                    {!isLoading && programs?.length > 0 && (
                        <>
                            <Box mb={3}>
                                <Typography variant={'subtitle2'} >
                                    {t('app.selectClassLabel').toUpperCase()}
                                </Typography>
                            </Box>
                        <Grid container spacing={3.75} mb={3}>
                            {programs.map( (program, index) =>
                                (
                                    <>
                                        <Grid item key={index}>
                                            <Card sx={{ maxWidth: 345 }} onClick={() => {viewProgram(program.uid)}}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        component="img"
                                                        height="250"
                                                        image={program?.image ? program.image : "https://drehmomentpole.de/wp-content/uploads/2023/11/polo.webp"}
                                                        alt={program.name}
                                                    />
                                                    <CardContent sx={{height: 50, marginBottom: 15}}>
                                                        <Typography gutterBottom variant="h5" component="div">
                                                            {appLocale.locale === LOCALE_DE ? program.name : program.name_en}
                                                        </Typography>

                                                        {appLocale.locale === LOCALE_DE ?
                                                            <Typography variant="body2" color="text.secondary">
                                                                {
                                                                    program.description.length > 100
                                                                        ? `${program.description.substring(0, 97)}...`
                                                                        : program.description
                                                                }
                                                            </Typography> :
                                                            <Typography variant="body2" color="text.secondary">
                                                                {
                                                                    program.description_en.length > 100
                                                                        ? `${program.description_en.substring(0, 97)}...`
                                                                        : program.description_en
                                                                }
                                                            </Typography>
                                                        }

                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    </>
                                )
                            )}
                        </Grid>
                        </>
                    )}
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default Programs;