
export class APIService {
  static baseUrl = process.env.REACT_APP_API_ENDPOINT;
  static METHOD_GET = 'GET';
  static METHOD_POST = 'POST';
  static METHOD_DELETE = 'DELETE';
  static METHOD_PATCH = 'PATCH';

  static async makeRequest(
    apiEndpoint,
    body,
    headers= {},
    method = APIService.METHOD_POST,
    isFormData = false,
    url = this.baseUrl,
  ) {

    // console.log(`request: ${method} ${url}${apiEndpoint}`, body);
    headers['ngrok-skip-browser-warning'] = true;

    if (method === APIService.METHOD_GET || method === APIService.METHOD_DELETE) {
      const response = await fetch(`${url}${apiEndpoint}`, {
        headers: headers,
        method: method,
      });
      return response;
    }

    const response = await fetch(`${url}${apiEndpoint}`, {
      headers: headers,
      method: method,
      body: isFormData ? body : JSON.stringify(body),
    });
    return response;
  }
}
