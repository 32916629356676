import {APIService} from './APIService';

export class ProgramService extends APIService {

    static async getPublishedPrograms(accessToken) {
        return await this.makeRequest(
            'api/programs/published',
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }

    static async getProgram(uid, accessToken) {
        return await this.makeRequest(
            `api/programs/${uid}`,
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }

    static async getProgramPricing(programUid, accessToken) {
        return await this.makeRequest(
            `api/programs/published/${programUid}/pricing`,
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }
    static async getProgramSchedule(programUid, accessToken) {
        return await this.makeRequest(
            `api/programs/published/${programUid}/schedule`,
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }

}
