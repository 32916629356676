import React, {useCallback, useEffect, useState} from "react";
import {
    Box,
    Card,
    CardActionArea, CardActions, CardContent,
    Chip, Dialog, DialogActions, DialogContent,
    Divider, Drawer, IconButton,
    ListItem,
    ListItemAvatar, ListItemIcon,
    ListItemText, Select, Stack, TextField,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {LoadingButton, Skeleton} from "@mui/lab";
import {StorageService} from "../../services/StorageService";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Vimeo from '@u-wave/react-vimeo';
import {CourseService} from "../../services/CourseService";
import {useTranslation} from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {UserLibraryService} from "../../services/UserLibraryService";
import {EURO_SIGN, IS_MEMBERSHIP_CONTENT, IS_NOT_MEMBERSHIP_CONTENT} from "../../utils/constants";
import {TransactionsService} from "../../services/TransactionsService";
import {EmbeddedCheckout, EmbeddedCheckoutProvider} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {displayAlertDialog} from "../../utils/alertDialogs";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import {
    trackAddToLibraryEvent,
    trackButtonClickEvent, trackModalViewEvent,
    trackPageViewEvent, trackPreviewVideoPlayedEvent, trackPurchaseFailedEvent,
    trackPurchaseInitiatedEvent, trackPurchaseSuccessfulEvent,
    trackRequestFailedEvent, trackVideoEndedEvent, trackVideoErrorEvent, trackVideoPlayedEvent
} from "../../utils/tracking";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import {LOCALE_DE} from "../../utils/locales";
import LanguageIcon from "@mui/icons-material/Language";
import EuroIcon from "@mui/icons-material/Euro";
import LoginIcon from "@mui/icons-material/Login";
import AddIcon from "@mui/icons-material/Add";
import {ClosedCaption} from "@mui/icons-material";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Course = () => {

    const {t} = useTranslation();
    const theme = useTheme();


    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();


    const [course, setCourse] = useState(null);
    const [courseCategory, setCourseCategory] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isAddingCourseToLibrary, setIsAddingCourseToLibrary] = useState(false);

    const [isCourseInUserLibrary, setIsCourseInUserLibrary] = useState(false);
    const [isLibraryItemOnHold, setIsLibraryItemOnHold] = useState(null);
    const [hasMembership, setHasMembership] = useState(false);

    const [isAddToLibraryDialogOpen, setIsAddToLibraryDialogOpen] = useState(false);
    const [isBuyCourseDialogOpen, setIsBuyCourseDialogOpen] = useState(false)
    const [videos, setVideos] = useState(null);
    const [accessCode, setAccessCode] = useState('');


    const [selectedVideo, setSelectedVideo] = useState(null);
    const [isPurchasing, setIsPurchasing] = useState();
    const [stripeCheckoutOptions, setStripeCheckoutOptions] = useState({clientSecret: ""});
    const [isStripeCheckoutDialogOpen, setIsStripeCheckoutDialogOpen] = useState(false)


    const user = StorageService.loadUser();
    const appLocale = StorageService.loadAppLocale();

    const isNotMobile = useMediaQuery('(min-width:900px)');


    useEffect( async () => {
        trackPageViewEvent('course', `/courses/${id}`);

        await getCourse();

        const stripeCheckoutSessionId = searchParams.get("session_id");
        if (stripeCheckoutSessionId) {
            await fetchPurchaseCourseTransactionStatus(stripeCheckoutSessionId);
        }
    }, []);



    const getCourse = async () => {
        setIsLoading(true);

        const response =  await CourseService.getCourse(id, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setCourse(data.course);
                setVideos(data.videos);
                setCourseCategory(data.category);
                setIsCourseInUserLibrary(data.isInUserLibrary);
                setIsLibraryItemOnHold(data.isLibraryItemOnHold)
                setHasMembership(data.hasMembership);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };

    const handleFieldChange = useCallback(
        (callback, e) => {
            const value = e.target.value;
            callback(value);

        },
        [setAccessCode],
    );

    const onCloseBuyCourseDialog = () => {
        trackButtonClickEvent(`cancel buy course`)
        setIsBuyCourseDialogOpen(false);
    }

    const goToLogin = () => {
        const currentPath = encodeURIComponent(window.location.pathname + window.location.search);
        navigate(`/login?returnUrl=${currentPath}`);
    }

    const openBuyCourseDialog = () => {
        trackModalViewEvent(`Buy course modal`)
        setIsBuyCourseDialogOpen(true);
    }

    const addToLibrary = async () => {
        trackButtonClickEvent('add course to library with access code');

        if (!accessCode) {
            alert(t("app.enterAccessCodeLabel"));
            return;
        }
        setIsAddToLibraryDialogOpen(false);
        setIsAddingCourseToLibrary(true);
        const response =  await UserLibraryService.addToLibrary(
            accessCode,
            course.uid,
            'course',
            user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsAddingCourseToLibrary(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                trackAddToLibraryEvent(course?.name, 'course', false)
                await displayAlertDialog(t, 'app.courseAddedToLibraryLabel', 'success');

                setIsCourseInUserLibrary(true);

            } else {
                trackRequestFailedEvent('add_course_to_library_using_access_code')
                alert(data.message);
            }
        } else {
            trackRequestFailedEvent('add_course_to_library_using_access_code')
            alert(message);
        }
    }

    const onCloseStripeCheckoutDialog = () => {
        trackButtonClickEvent(`cancel course stripe checkout: ${course?.name}`)
        setIsStripeCheckoutDialogOpen(false);
        window.location.reload();
    }

    const addToLibraryForFree = async () => {
        trackButtonClickEvent('add free course to library');

        setIsAddingCourseToLibrary(true);
        const response =  await UserLibraryService.addFreeRecordingToLibrary(
            course.uid,
            'course',
            user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsAddingCourseToLibrary(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                trackAddToLibraryEvent(course?.name, 'course', true)
                await displayAlertDialog(t, 'app.courseAddedToLibraryLabel', 'success');

                setIsCourseInUserLibrary(true);

            } else {
                trackRequestFailedEvent('add_free_course_to_library')
                alert(data.message);
            }
        } else {
            trackRequestFailedEvent('add_free_course_to_library')
            alert(message);
        }
    }

    const purchaseCourse = async () => {
        trackButtonClickEvent('purchase course');

        setIsPurchasing(true);

        const response = await TransactionsService.purchaseRecording(course.uid,
            'course', user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsPurchasing(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                trackPurchaseInitiatedEvent(course?.name, 'course')
                setIsStripeCheckoutDialogOpen(true);
                setStripeCheckoutOptions({
                    clientSecret: data.stripeClientSecret
                });

            } else {
                trackRequestFailedEvent('purchase_course')
                alert(data.message);
            }
        } else {
            trackRequestFailedEvent('purchase_course')
            alert(message);
        }
    };

    const fetchPurchaseCourseTransactionStatus = async (stripeCheckoutSessionId) => {
        setIsLoading(true);

        const response = await TransactionsService.fetchTransactionStatus(stripeCheckoutSessionId, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                if (data.status === 'complete') {
                    trackPurchaseSuccessfulEvent(course?.name, 'course')
                    await displayAlertDialog(t, 'app.coursePurchaseSuccessMessageLabel', 'success');
                }
                else {
                    trackPurchaseFailedEvent(course?.name, 'course')
                    await displayAlertDialog(t, 'app.paymentFailedMessageLabel', 'error');
                }
                await getCourse();

            } else {
                trackRequestFailedEvent('fetch_purchase_course_transaction_status')
                alert(data.message);
            }
        } else {
            trackRequestFailedEvent('fetch_purchase_course_transaction_status')
            alert(message);
        }
    };

    return (

        <React.Fragment>

            <Box>

                {isLoading && (
                    <>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>

                    </>
                )}

                {!isLoading && course && (
                    <>

                    <Grid container mb={3} >
                        <Grid item  sm={12} lg={8}>
                            <Button
                                onClick={() => navigate(-1)}
                                size={"small"}
                                startIcon={<ArrowBackIcon />}
                                sx={{marginLeft: -2}}
                            >
                                {t("app.backButtonLabel")}
                            </Button>
                            <ListItem sx={{marginLeft: -3}}>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="h1"
                                            mb={.25}
                                            sx={{ fontWeight: 'bold', textTransform:'uppercase' }}
                                        >
                                            {appLocale.locale === LOCALE_DE ?
                                                course?.name :
                                                course?.name_en ?
                                                    course?.name_en : course?.name
                                            }
                                            &nbsp;

                                            {course.isFree === 1 && (
                                                <Chip sx={{marginLeft: 0}} label={t('app.freeLabel')} color={'secondary'} size={'medium'}/>
                                            )}

                                            {course.isFree === 0 && course?.isMembershipContent === IS_MEMBERSHIP_CONTENT && (
                                                <Chip sx={{marginLeft: 0}} label={t('app.freeForMembersLabel')} color={'secondary'} size={'medium'}/>
                                            )}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography component={"div"}>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    color: 'grey.600',
                                                    fontWeight: 500
                                                }}
                                            >
                                                {courseCategory?.name}
                                            </Typography>

                                            <Stack
                                                direction="row"
                                                spacing={3}
                                                mb={1}
                                                sx={{
                                                    color: 'grey.800',
                                                    gap: 1
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 1,
                                                        textTransform: 'uppercase'
                                                    }}
                                                >
                                                    <LanguageIcon sx={{ fontSize: 20 }} />
                                                    {course.language}
                                                </Typography>

                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 1,
                                                        textTransform: 'uppercase'
                                                    }}
                                                >
                                                    <ClosedCaption sx={{ fontSize: 20 }} />
                                                    {t('app.englishLabel')}, {t('app.germanLabel')}
                                                </Typography>

                                                {course?.price && course?.isFree === 0 && !isCourseInUserLibrary && user && (
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1,
                                                            textTransform: 'uppercase'
                                                        }}
                                                    >
                                                        <EuroIcon sx={{ fontSize: 20 }} />
                                                        {course.price}
                                                    </Typography>
                                                )}
                                            </Stack>


                                            {/*<Typography variant='subtitle2'>*/}
                                            {/*    {t('app.languageLabel')}: {course.language}*/}
                                            {/*</Typography>*/}
                                            <Typography
                                                variant='caption'
                                                sx={{
                                                    color: 'grey.600',
                                                    maxWidth: '600px',
                                                    lineHeight: 1.6
                                                }}
                                            >
                                                {appLocale.locale === LOCALE_DE ? (
                                                    course?.description
                                                ) : (
                                                    course?.description_en
                                                        ? (course?.description_en)
                                                        : (course?.description)
                                                )}
                                            </Typography>

                                            <Stack
                                                direction={{ xs: 'column', sm: 'row' }}
                                                spacing={2}
                                                sx={{ mt: 4 }}
                                            >
                                                    {!user && (
                                                        <Box mt={1}>
                                                            <Button
                                                                sx={{marginRight: 1}}
                                                                size="large" color="primary"
                                                                variant={'contained'}
                                                                    onClick={() => {
                                                                        trackButtonClickEvent('Login to access course');
                                                                        goToLogin();
                                                                    }}>
                                                                {t('app.loginToAccessCourseLabel')}
                                                            </Button>
                                                        </Box>
                                                    )}

                                                    {/*{user && !isCourseInUserLibrary && course?.isFree === 1 && (*/}
                                                    {/*    // add to library for free*/}
                                                    {/*    <Box mt={1}>*/}
                                                    {/*        <LoadingButton loading={isAddingCourseToLibrary}*/}
                                                    {/*                       sx={{marginRight: 1}} size="large"*/}
                                                    {/*                       color="info" variant={'contained'}*/}
                                                    {/*                       onClick={addToLibraryForFree}>*/}
                                                    {/*            {t('app.addCourseToLibraryButtonLabel')} - {t('app.freeLabel')}*/}
                                                    {/*        </LoadingButton>*/}
                                                    {/*    </Box>*/}
                                                    {/*)}*/}

                                                    {user && !isCourseInUserLibrary && course?.isMembershipContent !== IS_MEMBERSHIP_CONTENT && course?.price && course?.isFree === 0 && (
                                                        // purchase
                                                        <Box mt={1}>
                                                            <LoadingButton loading={isPurchasing} sx={{marginRight: 1}}
                                                                           size="large" color="success"
                                                                           variant={'contained'}
                                                                           onClick={openBuyCourseDialog}>
                                                                {t('app.addCourseToLibraryButtonLabel')} - {`${EURO_SIGN}${course.price}`}
                                                            </LoadingButton>
                                                        </Box>
                                                    )}

                                                    {user && !isCourseInUserLibrary && course?.isMembershipContent === IS_MEMBERSHIP_CONTENT && !hasMembership && course?.price && course?.isFree === 0 && (
                                                        // purchase & free access with membership
                                                        <Box mt={1}>
                                                            <LoadingButton loading={isPurchasing} sx={{marginRight: 1, marginTop: 1}}
                                                                           size="large" color="success"
                                                                           variant={'contained'}
                                                                           onClick={openBuyCourseDialog}>
                                                                {t('app.addCourseToLibraryButtonLabel')} - {`${EURO_SIGN}${course.price}`}
                                                            </LoadingButton>

                                                            <Button  sx={{marginRight: 1, marginTop: 1}} size="large" color={'secondary'} variant={'contained'}
                                                                     onClick={() => {
                                                                         trackButtonClickEvent('watch course for free with a membership');
                                                                         navigate('/memberships')
                                                                     }}>
                                                                {t('app.accessForFreeWithMembershipLabel')}
                                                            </Button>
                                                        </Box>
                                                    )}

                                                    {user && !isCourseInUserLibrary && !course?.price && course?.isFree === 0 && (
                                                        // legacy use access code
                                                        <Box mt={1}>
                                                            <LoadingButton loading={isAddingCourseToLibrary} sx={{marginRight: 1}} size="large" color="primary" variant={'contained'}
                                                                           onClick={() => {setIsAddToLibraryDialogOpen(true)}}>
                                                                {t('app.addCourseToLibraryButtonLabel')}
                                                            </LoadingButton>
                                                        </Box>
                                                    )}

                                                    {user && isCourseInUserLibrary && isLibraryItemOnHold && (
                                                        <Box mt={1}>
                                                            <Chip label={t('app.noAccessToItemLabel').toUpperCase()} sx={{marginRight: 1}} size="small" color="warning"/>
                                                        </Box>
                                                    )}
                                            </Stack>
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4}>

                            {course.previewVideoLink && course.previewVideoLink !== 'null' && (
                                <Box>
                                    <ListItem sx={{marginLeft: -2}}>
                                        <ListItemIcon>
                                            <OndemandVideoIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            sx={{marginLeft: -3}}
                                            primary={
                                                <Typography variant={'body1'}
                                                            sx={{fontWeight: 'bold'}}>
                                                    {t('app.coursePreviewLabel')}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Card sx={{ maxWidth: '100%' }}>
                                        <CardActionArea>
                                            <Vimeo
                                                video={course.previewVideoLink}
                                                autopause
                                                showByline={false}
                                                showTitle={false}
                                                autopip
                                                pip
                                                responsive={true}
                                                onPlay={() => {
                                                    trackPreviewVideoPlayedEvent(course?.name, 'course')
                                                }}
                                                onError={() => {
                                                    trackVideoErrorEvent(course?.name, null)
                                                }}
                                            />
                                        </CardActionArea>
                                    </Card>
                                </Box>
                            )}
                        </Grid>
                    </Grid>

                    </>

                )}


            </Box>

            <Divider/>

            <Box mt={2}>
                <Typography variant={'h2'}>
                    {t('app.videosLabel')}
                </Typography>

                <Box mt={1}>

                    {!isLoading && videos?.length < 1 && (

                        <Card>
                            <CardContent>
                                <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Typography variant={'h4'}>
                                        {t('app.courseHasNoVideosLabel')}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    )}

                    {!isLoading && videos?.length > 0 && (
                        (!isNotMobile && (
                            <Grid container spacing={3.75} mb={3}>
                                {videos.map( (video, index) =>
                                    (
                                        <Grid item xs={12} sm={6} lg={4}>
                                            <Card >
                                                <CardActionArea>
                                                    {isCourseInUserLibrary && !isLibraryItemOnHold && (
                                                        // course is in user library
                                                        // show controls
                                                        <Vimeo
                                                            video={video.link}
                                                            autopause
                                                            showByline={false}
                                                            showTitle={false}
                                                            autopip
                                                            pip
                                                            responsive={true}
                                                            controls={true}
                                                            speed={true}

                                                            onPlay={() => {
                                                                trackVideoPlayedEvent(video?.name, course?.name, course.isFree === 1)
                                                            }}
                                                            onEnd={() => {
                                                                trackVideoEndedEvent(video?.name, course?.name)
                                                            }}
                                                            onError={() => {
                                                                trackVideoErrorEvent(video?.name, course?.name)
                                                            }}
                                                        />
                                                    )}

                                                    {isCourseInUserLibrary && isLibraryItemOnHold && (
                                                        // course is in user library but is on hold
                                                        // dont show controls
                                                        <Vimeo
                                                            video={video.link}
                                                            autopause
                                                            showByline={false}
                                                            showTitle={false}
                                                            autopip
                                                            pip
                                                            responsive={true}
                                                            controls={false}
                                                            speed={false}
                                                            onPlay={() => {
                                                                trackVideoPlayedEvent(video?.name, course?.name, course.isFree === 1)
                                                            }}
                                                            onEnd={() => {
                                                                trackVideoEndedEvent(video?.name, course?.name)
                                                            }}
                                                            onError={() => {
                                                                trackVideoErrorEvent(video?.name, course?.name)
                                                            }}
                                                        />
                                                    )}

                                                    {!isCourseInUserLibrary && course?.isMembershipContent === IS_MEMBERSHIP_CONTENT && hasMembership && (
                                                        // course is not in user library but its a membership content and the user has a membership
                                                        // show controls
                                                        <Vimeo
                                                            video={video.link}
                                                            autopause
                                                            showByline={false}
                                                            showTitle={false}
                                                            autopip
                                                            pip
                                                            responsive={true}
                                                            controls={true}
                                                            speed={true}
                                                            onPlay={() => {
                                                                trackVideoPlayedEvent(video?.name, course?.name, video?.isFree === 1)
                                                            }}
                                                            onEnd={() => {
                                                                trackVideoEndedEvent(video?.name, course?.name)
                                                            }}
                                                            onError={() => {
                                                                trackVideoErrorEvent(video?.name, course?.name)
                                                            }}
                                                        />
                                                    )}

                                                    {!isCourseInUserLibrary && course?.isMembershipContent === IS_MEMBERSHIP_CONTENT && !hasMembership && (
                                                        // course is not in user library but its a membership content and the user has no membership
                                                        // show controls if is free and user is logged in
                                                        // else dont
                                                        <Vimeo
                                                            video={video.link}
                                                            autopause
                                                            showByline={false}
                                                            showTitle={false}
                                                            autopip
                                                            pip
                                                            responsive={true}
                                                            controls={(user !== null && video.isFree === 1)}
                                                            speed={(user !== null && video.isFree === 1)}
                                                            onPlay={() => {
                                                                trackVideoPlayedEvent(video?.name, course?.name, video?.isFree === 1)
                                                            }}
                                                            onEnd={() => {
                                                                trackVideoEndedEvent(video?.name, course?.name)
                                                            }}
                                                            onError={() => {
                                                                trackVideoErrorEvent(video?.name, course?.name)
                                                            }}
                                                        />
                                                    )}

                                                    {!isCourseInUserLibrary && course?.isMembershipContent === IS_NOT_MEMBERSHIP_CONTENT  && (
                                                        // course is not in user library
                                                        // show controls if is free and user is logged in
                                                        // else dont
                                                        <Vimeo
                                                            video={video.link}
                                                            autopause
                                                            showByline={false}
                                                            showTitle={false}
                                                            autopip
                                                            pip
                                                            responsive={true}
                                                            controls={(user !== null && course.isFree === 1)}
                                                            speed={(user !== null && course.isFree === 1)}
                                                            onPlay={() => {
                                                                trackVideoPlayedEvent(video?.name, course?.name, video?.isFree === 1)
                                                            }}
                                                            onEnd={() => {
                                                                trackVideoEndedEvent(video?.name, course?.name)
                                                            }}
                                                            onError={() => {
                                                                trackVideoErrorEvent(video?.name, course?.name)
                                                            }}
                                                        />
                                                    )}

                                                    <CardContent sx={{height: 200, marginBottom: 15}}>
                                                        <Typography gutterBottom variant="h5" component="div">
                                                            {appLocale.locale === LOCALE_DE ?
                                                                video?.name :
                                                                video?.name_en ?
                                                                    video?.name_en : video?.name
                                                            }
                                                        </Typography>
                                                        <Typography variant='subtitle2'>
                                                            {t('app.languageLabel')}: {video.language}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">

                                                            {appLocale.locale === LOCALE_DE ?
                                                                video?.description :
                                                                video?.description_en ?
                                                                    video?.description_en : video?.description
                                                            }
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        ))

                    )}

                    {!isLoading && videos?.length > 0 && (
                        (isNotMobile && (
                            <List sx={{ width: '100%', bgcolor: 'background.paper', marginLeft: -2 }}>
                                {videos.map((video, index) => (
                                    <Card key={index} sx={{marginBottom: 2}}>
                                        <ListItem
                                            alignItems="flex-start"
                                            sx={{ py: 3 }}
                                        >
                                            <Box sx={{
                                                width: '320px',
                                                height: '180px',
                                                mr: 3,
                                                flexShrink: 0,
                                                overflow: 'hidden',
                                                position: 'relative'
                                            }}>
                                                <Card >
                                                    <CardActionArea>
                                                {isCourseInUserLibrary && !isLibraryItemOnHold &&(
                                                    // course is in user library
                                                    // show controls
                                                    <Vimeo
                                                        video={video.link}
                                                        autopause
                                                        showByline={false}
                                                        showTitle={false}
                                                        autopip
                                                        pip
                                                        responsive={true}
                                                        controls={true}
                                                        speed={true}
                                                        onPlay={() => trackVideoPlayedEvent(video?.name, course?.name, course.isFree === 1)}
                                                        onEnd={() => trackVideoEndedEvent(video?.name, course?.name)}
                                                        onError={() => trackVideoErrorEvent(video?.name, course?.name)}
                                                    />
                                                )}

                                                {isCourseInUserLibrary && isLibraryItemOnHold &&(
                                                    // course is in user library but is on hold
                                                    // dont show controls
                                                    <Vimeo
                                                        video={video.link}
                                                        autopause
                                                        showByline={false}
                                                        showTitle={false}
                                                        autopip
                                                        pip
                                                        responsive={true}
                                                        controls={false}
                                                        speed={false}
                                                        onPlay={() => trackVideoPlayedEvent(video?.name, course?.name, course.isFree === 1)}
                                                        onEnd={() => trackVideoEndedEvent(video?.name, course?.name)}
                                                        onError={() => trackVideoErrorEvent(video?.name, course?.name)}
                                                    />
                                                )}

                                                        {!isCourseInUserLibrary && course?.isMembershipContent === IS_MEMBERSHIP_CONTENT && hasMembership && (
                                                            // course is not in user library but its a membership content and the user has a membership
                                                            // show controls
                                                            <Vimeo
                                                                video={video.link}
                                                                autopause
                                                                showByline={false}
                                                                showTitle={false}
                                                                autopip
                                                                pip
                                                                responsive={true}
                                                                controls={true}
                                                                speed={true}
                                                                onPlay={() => {
                                                                    trackVideoPlayedEvent(video?.name, course?.name, video?.isFree === 1)
                                                                }}
                                                                onEnd={() => {
                                                                    trackVideoEndedEvent(video?.name, course?.name)
                                                                }}
                                                                onError={() => {
                                                                    trackVideoErrorEvent(video?.name, course?.name)
                                                                }}
                                                            />
                                                        )}

                                                        {!isCourseInUserLibrary && course?.isMembershipContent === IS_MEMBERSHIP_CONTENT && !hasMembership && (
                                                            // course is not in user library but its a membership content and the user has no membership
                                                            // show controls if is free and user is logged in
                                                            // else dont
                                                            <Vimeo
                                                                video={video.link}
                                                                autopause
                                                                showByline={false}
                                                                showTitle={false}
                                                                autopip
                                                                pip
                                                                responsive={true}
                                                                controls={(user !== null && video.isFree === 1)}
                                                                speed={(user !== null && video.isFree === 1)}
                                                                onPlay={() => {
                                                                    trackVideoPlayedEvent(video?.name, course?.name, video?.isFree === 1)
                                                                }}
                                                                onEnd={() => {
                                                                    trackVideoEndedEvent(video?.name, course?.name)
                                                                }}
                                                                onError={() => {
                                                                    trackVideoErrorEvent(video?.name, course?.name)
                                                                }}
                                                            />
                                                        )}

                                                {!isCourseInUserLibrary && course?.isMembershipContent === IS_NOT_MEMBERSHIP_CONTENT && (
                                                    <Vimeo
                                                        video={video.link}
                                                        autopause
                                                        showByline={false}
                                                        showTitle={false}
                                                        autopip
                                                        pip
                                                        responsive={true}
                                                        controls={(user !== null && course.isFree === 1)}
                                                        speed={(user !== null && course.isFree === 1)}
                                                        onPlay={() => trackVideoPlayedEvent(video?.name, course?.name, course.isFree === 1)}
                                                        onEnd={() => trackVideoEndedEvent(video?.name, course?.name)}
                                                        onError={() => trackVideoErrorEvent(video?.name, course?.name)}
                                                    />
                                                )}
                                                    </CardActionArea>
                                                </Card>
                                            </Box>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h2" component="div" mt={-1}>
                                                        {appLocale.locale === LOCALE_DE ?
                                                            video?.name :
                                                            video?.name_en ?
                                                                video?.name_en : video?.name
                                                        }
                                                    </Typography>
                                                }
                                                secondary={
                                                    <>
                                                        <Typography variant="subtitle2" component="span">
                                                            {t('app.languageLabel')}: {video.language}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" component="p">
                                                            {appLocale.locale === LOCALE_DE ?
                                                                video?.description :
                                                                video?.description_en ?
                                                                    video?.description_en : video?.description
                                                            }
                                                        </Typography>
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                        {/*{index < videos.length - 1 && <Divider variant="inset" component="li" />}*/}
                                    </Card>
                                ))}
                            </List>
                        ))

                    )}
                </Box>
            </Box>

            <Dialog
                open={isAddToLibraryDialogOpen}
                onClose={() => setIsAddToLibraryDialogOpen(false)}
            >
                <DialogTitle>
                    {t('app.enterAccessCodeLabel')}
                </DialogTitle>
                <DialogContent>
                   <Box mb={2} width={500}>
                    <TextField
                        label={t('app.accessCodeLabel')}
                        placeholder={t('app.enterAccessCodeLabel')}
                        fullWidth
                        value={accessCode}
                        onChange={(e) =>
                        {handleFieldChange(setAccessCode, e)}}
                        sx={{ marginBottom: 1, marginTop: 2 }}
                    />
                </Box>
                </DialogContent>
                <DialogActions>
                    <Button color={'error'} variant={'contained'} onClick={() => setIsAddToLibraryDialogOpen(false)}>
                        {t('app.closeButtonLabel')}
                    </Button>
                    <Button color={'primary'} variant={'contained'} onClick={addToLibrary}>
                        {t('app.addCourseToLibraryButtonLabel')}
                    </Button>

                </DialogActions>
            </Dialog>

            <Dialog open={isBuyCourseDialogOpen} onClose={onCloseBuyCourseDialog} maxWidth={'xl'} fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">{t('app.purchaseCourseLabel')}</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            {videos && videos.length > 0 && (
                                <Vimeo
                                    video={videos[0].link}
                                    autopause
                                    showByline={false}
                                    showTitle={false}
                                    autopip
                                    pip
                                    responsive={true}
                                    controls={false}
                                    speed={false}
                                />
                            )}

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h1" gutterBottom>
                                {appLocale.locale === LOCALE_DE ?
                                    course?.name :
                                    course?.name_en ?
                                        course?.name_en : course?.name
                                }
                            </Typography>
                            <Typography variant="h3" color="primary">
                                €{course?.price}
                            </Typography>
                            <Typography variant="body1" paragraph>
                                {appLocale.locale === LOCALE_DE ?
                                    course?.description :
                                    course?.description_en ?
                                        course?.description_en : course?.description
                                }
                            </Typography>



                            <Box mt={25}>
                                <Typography variant={"body1"} fontSize={12}>
                                    {t('app.byPurchasingThisItemLabel')}
                                    <Link
                                        ml={1}
                                        href={"https://drehmomentpole.de/agbs/"}
                                        color={"inherit"}
                                        target={'_blank'}
                                    >{t('app.termsAndConditionsLabel')}</Link>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseBuyCourseDialog} color="primary">
                        {t('app.cancelButtonLabel')}
                    </Button>

                    {user && (
                        <LoadingButton loading={isPurchasing} onClick={purchaseCourse} variant="contained" color="primary">
                            {t('app.payLabel')} €{course?.price}
                        </LoadingButton>
                    )}

                    {!user && (
                        <Button onClick={() => {
                            trackButtonClickEvent(`login to buy course`)
                            goToLogin()
                        }} variant="contained" color="primary">
                            {t('app.loginToBuyLabel')}
                        </Button>
                    )}

                </DialogActions>
            </Dialog>

            <Dialog fullScreen={true} open={isStripeCheckoutDialogOpen} onClose={onCloseStripeCheckoutDialog} maxWidth={'xl'} fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h1">{t('app.purchaseCourseLabel')}</Typography>
                        <Button onClick={onCloseStripeCheckoutDialog} color="primary">
                            {t('app.cancelButtonLabel')}
                        </Button>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {stripeCheckoutOptions.clientSecret !== "" && (
                        <Box>
                            <EmbeddedCheckoutProvider
                                stripe={stripePromise}
                                options={stripeCheckoutOptions}
                            >
                                <EmbeddedCheckout />
                            </EmbeddedCheckoutProvider>
                        </Box>

                    )}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default Course;