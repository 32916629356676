import React from 'react';
import Avatar from "@mui/material/Avatar";
import {ListItemIcon, ListItemText, ThemeProvider, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import LogoutIcon from '@mui/icons-material/Logout';
import {useNavigate} from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {StorageService} from "../../../services/StorageService";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import useJumboHeaderTheme from "../../../../@jumbo/hooks/useJumboHeaderTheme";
import {useTranslation} from "react-i18next";
import {getAbbreviation} from "../../../utils/abbreviateUserName";
import {trackButtonClickEvent} from "../../../utils/tracking";


const AuthUserDropdown = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {theme} = useJumboTheme();
    const {headerTheme} = useJumboHeaderTheme();
    const {setAuthToken} = useJumboAuth();
    const user = StorageService.loadUser();

    const onLogout = () => {
        trackButtonClickEvent('logout');
        setAuthToken(null);
        StorageService.logout();
        // navigate("/login", {replace: true});
        navigate("/", {replace: true});
    };

    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    // <Avatar
                    //     sizes={"small"}
                    //     sx={{boxShadow: 25, cursor: 'pointer'}}
                    // >{getAbbreviation(user?.name)}</Avatar>
                    <ThemeProvider theme={headerTheme}>
                        <JumboIconButton elevation={25}>
                            <PersonOutlineIcon sx={{fontSize: '1.25rem'}}/>
                        </JumboIconButton>
                    </ThemeProvider>
                }
            >
                <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: 200,
                    p: theme => theme.spacing(2.5),
                }}>
                    {/*<Avatar src={authUser.profile_pic} alt={authUser.name} sx={{width: 60, height: 60, mb: 2}}/>*/}

                    <Avatar>{getAbbreviation(user?.name)}</Avatar>

                    <Typography variant={"h5"}>{user?.name}</Typography>
                    <Typography variant={"body1"} color="text.secondary">{user?.email}</Typography>
                </Div>
                <Divider/>
                <nav>
                    <List disablePadding sx={{pb: 1}}>
                        <ListItemButton onClick={() => navigate("/profile")}>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <PersonOutlineIcon/>
                            </ListItemIcon>
                            <ListItemText sx={{my: 0}}>
                                {t('sidebar.menuItem.profile')}
                            </ListItemText>
                        </ListItemButton>
                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <LogoutIcon/>
                            </ListItemIcon>
                            <ListItemText sx={{my: 0}}>
                                {t('sidebar.menuItem.logout')}
                            </ListItemText>
                        </ListItemButton>
                    </List>
                </nav>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
