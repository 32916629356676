import React, {useCallback, useEffect, useState} from "react";
import {
    Box,
    Card,
    CardActionArea, CardActions, CardContent, CardMedia,
    Chip, Dialog, DialogActions, DialogContent, DialogTitle,
    Divider, Drawer, IconButton,
    ListItem,
    ListItemAvatar, ListItemIcon,
    ListItemText, Select, Tabs, TextField,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {LoadingButton, Skeleton} from "@mui/lab";
import {StorageService} from "../../services/StorageService";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {ProgramService} from "../../services/ProgramService";
import {getSupportedLocale, LOCALE_DE} from "../../utils/locales";
import CircleIcon from '@mui/icons-material/Circle';
import dayjs from "dayjs";
import Tab from "@mui/material/Tab";
import { CreditCard, CalendarMonth} from "@mui/icons-material";
import {loadStripe} from "@stripe/stripe-js";
import {TransactionsService} from "../../services/TransactionsService";
import {EmbeddedCheckoutProvider, EmbeddedCheckout} from "@stripe/react-stripe-js";
import {displayAlertDialog} from "../../utils/alertDialogs";
import {UserClassPassService} from "../../services/UserClassPassService";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {BookingsService} from "../../services/BookingsService";
import Vimeo from "@u-wave/react-vimeo";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import {
    trackBookingExistsEvent,
    trackBookingInsufficientClassPassesEvent, trackBookingProgramSubscriptionOnHoldEvent, trackBookingSuccessfulEvent,
    trackButtonClickEvent, trackClassIntroVideoPlayedEvent,
    trackModalViewEvent,
    trackPageViewEvent, trackPurchaseInitiatedEvent, trackPurchaseSuccessfulEvent,
    trackRequestFailedEvent, trackVideoErrorEvent
} from "../../utils/tracking";
import Link from "@mui/material/Link";
import {log} from "../../utils/logger";
import {
    PRICING_TYPE_INSTALMENTAL,
    PRICING_TYPE_ONE_TIME,
    PROGRAM_TYPE_DROP_IN,
    PROGRAM_TYPE_FIXED_COURSE
} from "../../utils/constants";

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LanguageIcon from '@mui/icons-material/Language';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { CheckCircle, Info, PauseCircle } from '@mui/icons-material';





const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const localizer = momentLocalizer(moment);

const Program = () => {

    const {t} = useTranslation();

    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const theme = useTheme();

    const navigate = useNavigate();
    const location = useLocation();

    //selected schedule from schedule pages
    const [locationSelectedSchedule, setLocationSelectedSchedule] = useState(location.state);

    const [program, setProgram] = useState(null);
    const [programPricing, setProgramPricing] = useState([]);
    const [programSchedule, setProgramSchedule] = useState([]);
    const [calendarSchedules, setCalendarSchedules] = useState([]);
    const [selectedSchedule, setSelectedSchedule] = useState();
    const [isScheduleDialogOpen, setIsScheduleDialogOpen] = useState(false);
    const [programSubscriptionStatus, setProgramSubscriptionStatus] = useState('not_available')
    const [isBuyClassPassDialogOpen, setIsBuyClassPassDialogOpen] = useState(false)
    const [isStripeCheckoutDialogOpen, setIsStripeCheckoutDialogOpen] = useState(false)
    const [isBookingClass, setIsBookingClass] = useState(false);
    const [availableClassPasses, setAvailableClassPasses] = useState('');
    const [hasMembership, setHasMembership] = useState(false);


    const [selectedProgramPrice, setSelectedProgramPrice] = useState();

    const [isLoading, setIsLoading] = useState(false);

    const user = StorageService.loadUser();
    const appLocale = StorageService.loadAppLocale();

    const [tabValue, setTabValue] = useState(0);

    const [quantity, setQuantity] = useState(1);
    const [selectedProgramPriceTotalPrice, setSelectedProgramPriceTotalPrice] = useState(0);
    const [stripeCheckoutOptions, setStripeCheckoutOptions] = useState({clientSecret: ""});
    const [isPurchasing, setIsPurchasing] = useState();

    const isNotMobile = useMediaQuery('(min-width:1200px)');
    const scrollCalendarTo12PM = new Date(new Date().setUTCHours(12));


    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const goToLogin = () => {
        const currentPath = encodeURIComponent(window.location.pathname + window.location.search);
        navigate(`/login?returnUrl=${currentPath}`);
    }

    useEffect(
        async ()  => {
            trackPageViewEvent('class', `/classes/${id}`);

            await getProgram();

            const stripeCheckoutSessionId = searchParams.get("session_id");
            if (stripeCheckoutSessionId) {
                await fetchPurchaseClassPassTransactionStatus(stripeCheckoutSessionId);
            }
        },
        [],
    );

    useEffect(
        async ()  => {
            await getAvailableClassPasses();
            await getProgramPricing();
            await getProgramSchedule();

            if(locationSelectedSchedule) {
                handleSelectSchedule(locationSelectedSchedule);
                window.history.replaceState({}, '')
            }
        },
        [program],
    );

    useEffect(() => {
        if(quantity < 1 || isNaN(quantity)){
            return;
        }
        setSelectedProgramPriceTotalPrice((selectedProgramPrice?.totalAmount * quantity).toFixed(2));
    }, [quantity, selectedProgramPrice?.totalAmount]);

    const handleQuantityChange = (event) => {
        // const newQuantity = Math.max(1, parseInt(event.target.value) || 1);
        const newQuantity = parseInt(event.target.value);

        setQuantity(newQuantity);
    };


    const displayScheduleDialog = (shouldDisplay) => {
        if(shouldDisplay) {
            trackModalViewEvent(`Book class modal: ${program?.name}`)
        }

        setIsScheduleDialogOpen(shouldDisplay);
    }

    const onCloseBuyClassPassDialog = () => {
        trackButtonClickEvent(`cancel buy class pass: ${program?.name}`)
        setIsBuyClassPassDialogOpen(false);
    }

    const onCloseStripeCheckoutDialog = () => {
        trackButtonClickEvent(`cancel class pass stripe checkout: ${program?.name}`)
        setIsStripeCheckoutDialogOpen(false);
        window.location.reload();
    }

    const openBuyClassPassDialog = (programPrice) => {
        setSelectedProgramPrice(programPrice);
        setQuantity(1);
        setSelectedProgramPriceTotalPrice(programPrice.totalAmount * quantity);

        trackModalViewEvent(`Buy class pass modal: ${program?.name}`)
        setIsBuyClassPassDialogOpen(true);
    }

    const getProgram = async () => {
        setIsLoading(true);

        const response =  await ProgramService.getProgram(id, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setProgram(data.program);
                setProgramSubscriptionStatus(data?.programSubscriptionStatus);
                setHasMembership(data.hasMembership);
                log('data.program', data.program)

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };

    const getAvailableClassPasses = async () => {
        if(!user) {
            return;
        }

        const response =  await UserClassPassService.getUserAvailableClassPassForProgram(id, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setAvailableClassPasses(data.availableClassPasses);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };

    const getProgramPricing = async () => {
        // setIsLoading(true);

        const response =  await ProgramService.getProgramPricing(id, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        // setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setProgramPricing(data.programPricing);
                log('data.programPricing', data.programPricing);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };

    const purchaseClassPass = async () => {
        trackButtonClickEvent(`purchase class pass`)
        setIsPurchasing(true);

        const response = await TransactionsService.purchaseClassPass(selectedProgramPrice.uid, quantity, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsPurchasing(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                trackPurchaseInitiatedEvent(program?.name, 'class_pass')
                onCloseBuyClassPassDialog();
                setIsStripeCheckoutDialogOpen(true);
                setStripeCheckoutOptions({
                    clientSecret: data.stripeClientSecret
                });

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };

    const bookClass = async () => {
        trackButtonClickEvent(`book class`)
        displayScheduleDialog(false)
        setIsBookingClass(true);

        const response =  await BookingsService.bookClass(selectedSchedule.uid, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsBookingClass(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                if (data.insufficientClassPasses) {
                    trackBookingInsufficientClassPassesEvent(program?.name);
                    await displayAlertDialog(t, 'app.insufficientClassPassesLabel', 'error');
                }
                else if (data.isProgramSubscriptionOnHold) {
                    trackBookingProgramSubscriptionOnHoldEvent(program?.name);
                    await displayAlertDialog(t, 'app.classBookingFailedDueToSubscriptionOnHold', 'error');
                }
                else {
                    if (data.isExistingBooking) {
                        trackBookingExistsEvent(program?.name);
                        await displayAlertDialog(t, 'app.classAlreadyBookedLabel', 'info');

                    }
                    else {
                        trackBookingSuccessfulEvent(program?.name)
                        await displayAlertDialog(t, 'app.bookingSuccessfulLabel', 'success');
                        await getAvailableClassPasses();
                    }
                }

            } else {
                trackRequestFailedEvent('booking')
                alert(data.message);
            }
        } else {
            trackRequestFailedEvent('booking')
            alert(message);
        }
    }

    const fetchPurchaseClassPassTransactionStatus = async (stripeCheckoutSessionId) => {
        setIsLoading(true);

        const response = await TransactionsService.fetchTransactionStatus(stripeCheckoutSessionId, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                if (data.status === 'complete') {
                    trackPurchaseSuccessfulEvent(program?.name, 'class_pass');
                    await displayAlertDialog(t, 'app.classPassPurchaseSuccessMessageLabel', 'success');
                }
                else {
                    trackRequestFailedEvent(program?.name, 'class_pass')
                    await displayAlertDialog(t, 'app.paymentFailedMessageLabel', 'error');
                }
                await getAvailableClassPasses();

            } else {
                trackRequestFailedEvent('fetch_purchase_class_pass_transaction_status')
                alert(data.message);
            }
        } else {
            trackRequestFailedEvent('fetch_purchase_class_pass_transaction_status')
            alert(message);
        }
    };

    const getProgramSchedule = async () => {
        // setIsLoading(true);

        const response =  await ProgramService.getProgramSchedule(id, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        // setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                const calSchedules = [];
                for (const schedule of data.programSchedule) {
                    // console.log('programs', programs)
                    const scheduleProgram = program;
                    calSchedules.push({
                        ...schedule,
                        start: new Date(schedule.startTime),
                        end: new Date(schedule.endTime),
                        title: scheduleProgram?.name,
                    });
                }
                setProgramSchedule(data.programSchedule);
                setCalendarSchedules(calSchedules);


            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };


    const handleSelectSchedule = (schedule) => {
        setSelectedSchedule(schedule);
        displayScheduleDialog(true)
    };


    const eventStyleGetter = (event, start, end, isSelected) => {
        return {
            style: {
                backgroundColor: theme.palette.secondary.main,
                borderRadius: '4px',
                opacity: 0.8,
                color: theme.palette.secondary.contrastText,
                border: 'none',
                display: 'block'
            }
        };
    };

    const getProgramSubscriptionStatusStyles = (status) => {
        switch (status) {
            case 'enrolled':
                return { color: 'success.main' };
            case 'not_enrolled':
                return { color: 'secondary.main' };
            case 'on_hold':
                return { color: 'warning.main' };
            default:
                return {};
        }
    };

    const getProgramSubscriptionStatusIcon = (status) => {
        switch (status) {
            case 'enrolled':
                return <CheckCircle sx={{ fontSize: 18 }} />;
            case 'not_enrolled':
                return <Info sx={{ fontSize: 18 }} />;
            case 'on_hold':
                return <PauseCircle sx={{ fontSize: 18 }} />;
            default:
                return null;
        }
    };

    const getProgramSubscriptionStatusMessage = (status) => {
        switch (status) {
            case 'enrolled':
                return "app.programSubscriptionStatusEnrolled";
            case 'not_enrolled':
                return "app.programSubscriptionStatusNotEnrolled";
            case 'on_hold':
                return "app.programSubscriptionStatusOnHold";
            default:
                return "";
        }
    };

    const getMembershipSubscriptionStatusStyles = (hasMembership) => {
        return hasMembership ? { color: 'success.main' } : { color: 'secondary.main' };
    };

    const getMembershipSubscriptionStatusIcon = (hasMembership) => {
        return hasMembership ? <CheckCircle sx={{ fontSize: 18 }} />: <Info sx={{ fontSize: 18 }} />;
    };

    const getMembershipSubscriptionStatusMessage = (hasMembership) => {
        return hasMembership ? "app.membershipSubscriptionStatusActive": "app.membershipSubscriptionStatusNotActive";
    };

    return (

        <React.Fragment>

            <Box>

                {isLoading && (
                    <>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>

                    </>
                )}

                {!isLoading && program && (
                    <>
                        <Grid container spacing={3} sx={{ mb: 4 }}>
                            {/* Back Button */}
                            <Grid item xs={12}>
                                <Box sx={{ mb: 2 }}>
                                    <Button
                                        onClick={() => navigate(-1)}
                                        size="small"
                                        startIcon={<ArrowBackIcon />}
                                        sx={{ ml: -1 }}
                                    >
                                        {t("app.backButtonLabel")}
                                    </Button>
                                </Box>
                            </Grid>

                            {/* Content Section */}
                            <Grid item xs={12} lg={8}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: { xs: 'column', sm: 'row' },
                                        gap: 3,
                                    }}
                                >
                                    {/* Image */}
                                    {program?.image && (
                                        <Box
                                            sx={{
                                                width: { xs: '100%', sm: 160 },
                                                height: { xs: 200, sm: 120 },
                                                borderRadius: 2,
                                                overflow: 'hidden',
                                                flexShrink: 0,
                                                boxShadow: '0 4px 12px rgba(0,0,0,0.08)'
                                            }}
                                        >
                                            <img
                                                width="100%"
                                                height="100%"
                                                alt={program.name}
                                                src={program.image}
                                                style={{
                                                    objectFit: 'cover',
                                                    display: 'block'
                                                }}
                                            />
                                        </Box>
                                    )}

                                    {/* Text Content */}
                                    <Box sx={{ flex: 1 }}>
                                        <Typography variant="h1" sx={{ mb: 1, fontWeight: 'bold', textTransform:'uppercase' }}>
                                            {appLocale.locale === LOCALE_DE ? program?.name : program?.name_en} &nbsp;

                                            {program?.type === PROGRAM_TYPE_DROP_IN &&  (
                                                <Chip sx={{marginLeft: 0}} label={t('app.freeForMembersLabel')} color={'secondary'} size={'medium'}/>
                                            )}
                                        </Typography>

                                        {program?.type === PROGRAM_TYPE_FIXED_COURSE && programSchedule && (
                                            <>
                                                {programSubscriptionStatus && programSubscriptionStatus !== 'not_available' && (
                                                    <Typography
                                                        variant="subtitle2"
                                                        sx={{
                                                            mb: 1,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1,
                                                            ...getProgramSubscriptionStatusStyles(programSubscriptionStatus),
                                                        }}
                                                    >
                                                        {getProgramSubscriptionStatusIcon(programSubscriptionStatus)}
                                                        {t(getProgramSubscriptionStatusMessage(programSubscriptionStatus))}
                                                    </Typography>
                                                )}
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        mb: 1,
                                                        color: 'text.primary',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 1,
                                                        textTransform: 'uppercase',
                                                    }}
                                                >
                                                    <CalendarTodayIcon sx={{ fontSize: 18 }} />
                                                    {dayjs(programSchedule[programSchedule.length - 1]?.startTime).format('MMMM D, YYYY')} - {dayjs(programSchedule[0]?.startTime).format('MMMM D, YYYY')}
                                                </Typography>
                                            </>
                                        )}


                                        {program?.type === PROGRAM_TYPE_DROP_IN  && user && (
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    mb: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                    ...getMembershipSubscriptionStatusStyles(hasMembership),
                                                }}
                                            >
                                                {getMembershipSubscriptionStatusIcon(hasMembership)}
                                                {t(getMembershipSubscriptionStatusMessage(hasMembership))}
                                            </Typography>
                                        )}

                                        {user && (
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    mb: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                <CreditCardIcon sx={{ fontSize: 18 }} />
                                                {t('app.numberOfAvailableClassCredits')}: {availableClassPasses}
                                            </Typography>
                                        )}

                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                mb: 2,
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            <LanguageIcon sx={{ fontSize: 18 }} />
                                            {t('app.languageLabel')}: {getSupportedLocale(program.locale)?.localizedLanguage}
                                        </Typography>

                                        <Typography
                                            variant="caption"
                                            color="text.primary"
                                            sx={{
                                                lineHeight: 1.6
                                            }}
                                        >
                                            {appLocale.locale === LOCALE_DE ? program.description : program.description_en}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            {/* Video Section */}
                            <Grid item xs={12} lg={4}>
                                {program.introVideo && (
                                    <Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                                mb: 2
                                            }}
                                        >
                                            <OndemandVideoIcon color="primary" />
                                            <Typography
                                                variant="subtitle1"
                                                sx={{ fontWeight: 600 }}
                                            >
                                                {t('app.classIntroLabel')}
                                            </Typography>
                                        </Box>

                                        <Card
                                            sx={{
                                                borderRadius: 2,
                                                overflow: 'hidden',
                                                boxShadow: '0 4px 12px rgba(0,0,0,0.08)'
                                            }}
                                        >
                                            <CardActionArea>
                                                <Vimeo
                                                    video={program.introVideo}
                                                    autopause
                                                    showByline={false}
                                                    showTitle={false}
                                                    autopip
                                                    pip
                                                    responsive={true}
                                                    onPlay={() => {
                                                        trackClassIntroVideoPlayedEvent(program?.name)
                                                    }}
                                                    onError={() => {
                                                        trackVideoErrorEvent(program?.name, null)
                                                    }}
                                                />
                                            </CardActionArea>
                                        </Card>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    <Box mt={4}>
                        {/* Display class pass label if its a drop in class or the fixed course is not enrolled to or not available*/}
                        {/* Else Hide the class pass label if its a fixed course and it is enrolled to or has other statuses*/}
                        <Box
                            sx={{
                                display: (program?.type === PROGRAM_TYPE_FIXED_COURSE &&
                                    (programSubscriptionStatus === 'not_enrolled' ||
                                        programSubscriptionStatus === 'not_available')) ||
                                program?.type === PROGRAM_TYPE_DROP_IN
                                    ? 'flex' : 'none',
                                alignItems: 'center',
                                gap: 2,
                                mb: 3,
                                mt: 4
                            }}
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    letterSpacing: '0.1em',
                                    color: 'text.primary'
                                }}
                            >
                                {t('app.classPassesLabel')}
                            </Typography>
                            <Divider sx={{ flex: 1 }} />
                        </Box>

                        <Grid container spacing={3.75} mb={3} mt={1}>

                            {program?.type === PROGRAM_TYPE_DROP_IN &&  (
                                <Grid item>
                                    <Card
                                        sx={{
                                            width: 345,
                                            borderRadius: 3,
                                            background: `${theme.palette.secondary.main}`,
                                            transition: 'transform 0.2s, box-shadow 0.2s',
                                            '&:hover': {
                                                transform: 'translateY(-4px)',
                                                boxShadow: '0 12px 24px rgba(0,0,0,0.1)',
                                            },
                                            border: `2px solid ${theme.palette.secondary.main}`,
                                            boxShadow: theme.shadows[10],
                                        }}
                                    >
                                        <CardActionArea>
                                            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                                                <CardMedia
                                                    component="img"
                                                    image={program?.image || "https://drehmomentpole.de/wp-content/uploads/2023/11/polo.webp"}
                                                    alt={'free-with-membership'}
                                                    sx={{
                                                        height: 200,
                                                        objectFit: 'cover',
                                                        transition: 'transform 0.3s',
                                                        '&:hover': {
                                                            transform: 'scale(1.05)',
                                                        }
                                                    }}
                                                />
                                            </Box>

                                            {!hasMembership && (
                                                <Chip
                                                    label={t('app.mostPopularLabel')}
                                                    color="secondary"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: -11,
                                                        left: '50%',
                                                        transform: 'translateX(-50%)',
                                                    }}
                                                />
                                            )}

                                            {hasMembership && (
                                                <Chip
                                                    label={t('app.activeLabel').toUpperCase()}
                                                    color="secondary"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: -11,
                                                        left: '50%',
                                                        transform: 'translateX(-50%)',
                                                    }}
                                                />
                                            )}

                                            <CardContent sx={{ p: 3,
                                                height: 250,
                                            }}>
                                                <Box sx={{ mb: 3 }}>
                                                    <Typography
                                                        variant="h4"
                                                        component="div"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            color: 'primary.main',
                                                            mb: 1
                                                        }}
                                                    >
                                                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                                                            <span>€0</span>
                                                            <Typography variant="subtitle1" color="white">
                                                                {t('app.withMembershipLabel')}
                                                            </Typography>
                                                        </Box>

                                                    </Typography>
                                                </Box>

                                                <Typography
                                                    variant="h6"
                                                    component="div"
                                                    sx={{
                                                        fontWeight: 600,
                                                        mb: 2,
                                                        textTransform: 'uppercase',
                                                        color: 'primary.main',
                                                    }}
                                                >
                                                    {t('app.freeVirtualDropInClassLabel')}
                                                </Typography>

                                                <Typography
                                                    variant="body1"
                                                    color="white"
                                                    sx={{
                                                        minHeight: 60,
                                                        opacity: 0.8
                                                    }}
                                                >
                                                    {t('app.freeVirtualDropInClassDescriptionLabel')}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>

                                        <CardActions sx={{ px: 3, pb: 3 }}>
                                            <Button
                                                onClick={() => {
                                                    trackButtonClickEvent(`join membership ${program?.name} class pass`);
                                                    navigate('/memberships')
                                                }}
                                                variant="contained"
                                                fullWidth
                                                size="large"
                                                color={'primary'}
                                                sx={{
                                                    borderRadius: 2,
                                                    textTransform: 'uppercase',
                                                    py: 1.5,
                                                    fontWeight: 600,
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                                                    }
                                                }}
                                                disabled={hasMembership}
                                            >
                                                {hasMembership && (
                                                    <>
                                                        {t('app.joinMembershipLabel')}
                                                    </>
                                                )}

                                                {!hasMembership && (
                                                    <>
                                                        {t('app.joinMembershipLabel')}
                                                    </>
                                                )}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )}

                            {(!hasMembership && program?.type === PROGRAM_TYPE_DROP_IN) && programPricing.map((programPrice, index) => (
                                <Grid item key={index}>
                                    <Card
                                        sx={{
                                            width: 345,
                                            borderRadius: 3,
                                            background: 'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)',
                                            transition: 'transform 0.2s, box-shadow 0.2s',
                                            '&:hover': {
                                                transform: 'translateY(-4px)',
                                                boxShadow: '0 12px 24px rgba(0,0,0,0.1)',
                                            }
                                        }}
                                    >
                                        <CardActionArea>
                                            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                                                <CardMedia
                                                    component="img"
                                                    image={program?.image || "https://drehmomentpole.de/wp-content/uploads/2023/11/polo.webp"}
                                                    alt={programPrice.name}
                                                    sx={{
                                                        height: 200,
                                                        objectFit: 'cover',
                                                        transition: 'transform 0.3s',
                                                        '&:hover': {
                                                            transform: 'scale(1.05)',
                                                        }
                                                    }}
                                                />
                                            </Box>

                                            <CardContent sx={{ p: 3,
                                                height: 250,
                                            }}>
                                                <Box sx={{ mb: 3 }}>
                                                    <Typography
                                                        variant="h4"
                                                        component="div"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            color: 'primary.main',
                                                            mb: 1
                                                        }}
                                                    >
                                                        {programPrice.type === PRICING_TYPE_ONE_TIME ? (
                                                            <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                                                                <span>€{programPrice.totalAmount}</span>
                                                                <Typography variant="subtitle1" color="text.secondary">
                                                                    one-time
                                                                </Typography>
                                                            </Box>
                                                        ) : (
                                                            <Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                                                                    <span>€{programPrice.amount}</span>
                                                                    <Typography variant="subtitle1" color="text.secondary">
                                                                        /{t('app.monthLabel')}
                                                                    </Typography>
                                                                </Box>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="text.secondary"
                                                                    sx={{ mt: 0.5 }}
                                                                >
                                                                    {programPrice.paymentIntervals} {t('app.monthSubLabel')}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Typography>
                                                </Box>

                                                <Typography
                                                    variant="h6"
                                                    component="div"
                                                    sx={{
                                                        fontWeight: 600,
                                                        mb: 2,
                                                        textTransform: 'uppercase',
                                                        color: 'primary.main',
                                                    }}
                                                >
                                                    {appLocale.locale === LOCALE_DE ? programPrice.name : programPrice.name_en}
                                                </Typography>

                                                <Typography
                                                    variant="body1"
                                                    color="text.secondary"
                                                    sx={{
                                                        minHeight: 60,
                                                        opacity: 0.8
                                                    }}
                                                >
                                                    {appLocale.locale === LOCALE_DE ? (
                                                        programPrice.description.length > 100
                                                            ? `${programPrice.description.substring(0, 97)}...`
                                                            : programPrice.description
                                                    ) : (
                                                        programPrice?.description_en
                                                            ? (programPrice?.description_en?.length > 100
                                                                ? `${programPrice?.description_en.substring(0, 97)}...`
                                                                : programPrice?.description_en)
                                                            : (programPrice?.description?.length > 100
                                                                ? `${programPrice?.description.substring(0, 97)}...`
                                                                : programPrice?.description)
                                                    )}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>

                                        <CardActions sx={{ px: 3, pb: 3 }}>
                                            <Button
                                                onClick={() => openBuyClassPassDialog(programPrice)}
                                                variant="contained"
                                                fullWidth
                                                size="large"
                                                sx={{
                                                    borderRadius: 2,
                                                    textTransform: 'uppercase',
                                                    py: 1.5,
                                                    fontWeight: 600,
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                                                    }
                                                }}
                                            >
                                                {t('app.buyLabel')}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}

                            {(program?.type === PROGRAM_TYPE_FIXED_COURSE && (programSubscriptionStatus === 'not_enrolled' || programSubscriptionStatus === 'not_available')) && programPricing.map((programPrice, index) => (
                                <Grid item key={index}>
                                    <Card
                                        sx={{
                                            width: 345,
                                            borderRadius: 3,
                                            background: 'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)',
                                            transition: 'transform 0.2s, box-shadow 0.2s',
                                            '&:hover': {
                                                transform: 'translateY(-4px)',
                                                boxShadow: '0 12px 24px rgba(0,0,0,0.1)',
                                            }
                                        }}
                                    >
                                        <CardActionArea>
                                            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                                                <CardMedia
                                                    component="img"
                                                    image={program?.image || "https://drehmomentpole.de/wp-content/uploads/2023/11/polo.webp"}
                                                    alt={programPrice.name}
                                                    sx={{
                                                        height: 200,
                                                        objectFit: 'cover',
                                                        transition: 'transform 0.3s',
                                                        '&:hover': {
                                                            transform: 'scale(1.05)',
                                                        }
                                                    }}
                                                />
                                            </Box>

                                            <CardContent sx={{ p: 3,
                                                height: 250,
                                            }}>
                                                <Box sx={{ mb: 3 }}>
                                                    <Typography
                                                        variant="h4"
                                                        component="div"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            color: 'primary.main',
                                                            mb: 1
                                                        }}
                                                    >
                                                        {programPrice.type === PRICING_TYPE_ONE_TIME ? (
                                                            <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                                                                <span>€{programPrice.totalAmount}</span>
                                                                <Typography variant="subtitle1" color="text.secondary">
                                                                    one-time
                                                                </Typography>
                                                            </Box>
                                                        ) : (
                                                            <Box>
                                                                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                                                                    <span>€{programPrice.amount}</span>
                                                                    <Typography variant="subtitle1" color="text.secondary">
                                                                        /{t('app.monthLabel')}
                                                                    </Typography>
                                                                </Box>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="text.secondary"
                                                                    sx={{ mt: 0.5 }}
                                                                >
                                                                    {programPrice.paymentIntervals} {t('app.monthSubLabel')}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Typography>
                                                </Box>

                                                <Typography
                                                    variant="h6"
                                                    component="div"
                                                    sx={{
                                                        fontWeight: 600,
                                                        mb: 2,
                                                        textTransform: 'uppercase',
                                                        color: 'primary.main',
                                                    }}
                                                >
                                                    {appLocale.locale === LOCALE_DE ? programPrice.name : programPrice.name_en}
                                                </Typography>

                                                <Typography
                                                    variant="body1"
                                                    color="text.secondary"
                                                    sx={{
                                                        minHeight: 60,
                                                        opacity: 0.8
                                                    }}
                                                >
                                                    {appLocale.locale === LOCALE_DE ? (
                                                        programPrice.description.length > 100
                                                            ? `${programPrice.description.substring(0, 97)}...`
                                                            : programPrice.description
                                                    ) : (
                                                        programPrice?.description_en
                                                            ? (programPrice?.description_en?.length > 100
                                                                ? `${programPrice?.description_en.substring(0, 97)}...`
                                                                : programPrice?.description_en)
                                                            : (programPrice?.description?.length > 100
                                                                ? `${programPrice?.description.substring(0, 97)}...`
                                                                : programPrice?.description)
                                                    )}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>

                                        <CardActions sx={{ px: 3, pb: 3 }}>
                                            <Button
                                                onClick={() => openBuyClassPassDialog(programPrice)}
                                                variant="contained"
                                                fullWidth
                                                size="large"
                                                sx={{
                                                    borderRadius: 2,
                                                    textTransform: 'uppercase',
                                                    py: 1.5,
                                                    fontWeight: 600,
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                                                    }
                                                }}
                                            >
                                                {t('app.buyLabel')}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>


                    </Box>


                    <Box mt={4}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                mb: 3,
                                mt: 4
                            }}
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    letterSpacing: '0.1em',
                                    color: 'text.primary'
                                }}
                            >
                                {t('app.scheduleLabel')}
                            </Typography>
                            <Divider sx={{ flex: 1 }} />
                        </Box>

                        <Box mb={3} mt={1}>
                            <Card>
                                <CardContent>
                                    {calendarSchedules?.length > 0 && (
                                        <Calendar
                                            localizer={localizer}
                                            events={calendarSchedules}
                                            selectable
                                            defaultView={isNotMobile ? 'week' : 'day'}
                                            culture={appLocale.locale === LOCALE_DE ? 'de' : 'en'}
                                            scrollToTime={scrollCalendarTo12PM}
                                            onSelectEvent={event => handleSelectSchedule(event)}
                                            eventPropGetter={eventStyleGetter}
                                            style={{
                                                height: 600
                                            }}
                                            defaultDate={
                                                // navigate to the day of the first schedule in the schedules, if the schedule is empty navigate to the current day
                                                calendarSchedules?.length > 0 ?
                                                    calendarSchedules[calendarSchedules?.length - 1]?.start :
                                                    new Date()
                                            }
                                        />
                                    )}

                                    {
                                        // This would just display an empty calendar if there are no events
                                        calendarSchedules?.length < 1 && (
                                            <Calendar
                                                localizer={localizer}
                                                events={calendarSchedules}
                                                selectable
                                                defaultView={isNotMobile ? 'week' : 'day'}
                                                culture={appLocale.locale === LOCALE_DE ? 'de' : 'en'}
                                                scrollToTime={scrollCalendarTo12PM}
                                                onSelectEvent={event => handleSelectSchedule(event)}
                                                style={{height: 600}}
                                                defaultDate={
                                                    // navigate to the day of the first schedule in the schedules, if the schedule is empty navigate to the current day
                                                    calendarSchedules?.length > 0 ?
                                                        calendarSchedules[calendarSchedules?.length - 1]?.start :
                                                        new Date()
                                                }
                                            />
                                        )}


                                </CardContent>
                            </Card>
                        </Box>



                    </Box>


                    </>
                )}


            </Box>

            <Divider/>

            <Box mt={2}>

                <Box>

                    {!isLoading && !program && (

                        <Card>
                            <CardContent>
                                <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Typography variant={'h4'}>
                                        {t('app.classDoesntExistLabel')}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    )}

                </Box>
            </Box>

            <Dialog open={isBuyClassPassDialogOpen} onClose={onCloseBuyClassPassDialog} maxWidth={'xl'} fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">{t('app.purchaseClassPassLabel')}</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <img
                                src={program?.image}
                                alt={selectedProgramPrice?.name}
                                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h1" gutterBottom>
                                {appLocale.locale === LOCALE_DE ? selectedProgramPrice?.name : selectedProgramPrice?.name_en}
                            </Typography>
                            {program?.type === PROGRAM_TYPE_FIXED_COURSE && programSchedule && (
                                <Typography variant={'h5'} mb={3}>
                                    {dayjs(programSchedule[programSchedule.length - 1]?.startTime).format('MMMM D, YYYY')} - {dayjs(programSchedule[0]?.startTime).format('MMMM D, YYYY')}
                                </Typography>
                            )}
                            <Typography variant="h3" gutterBottom>
                                {selectedProgramPrice?.type === PRICING_TYPE_ONE_TIME && (
                                    <>€{selectedProgramPrice?.totalAmount}</>
                                )}
                                {selectedProgramPrice?.type === PRICING_TYPE_INSTALMENTAL && (
                                    <>
                                        €{selectedProgramPrice?.amount}/{t('app.monthLabel')}
                                        <Typography variant={'subtitle2'}>
                                            {selectedProgramPrice?.paymentIntervals} {t('app.monthSubLabel')}
                                        </Typography>
                                    </>
                                )}
                            </Typography>
                            <Typography variant="body1" paragraph>
                                {appLocale.locale === LOCALE_DE ? selectedProgramPrice?.description : selectedProgramPrice?.description_en}
                            </Typography>

                            {program?.type === PROGRAM_TYPE_DROP_IN && selectedProgramPrice?.type === PRICING_TYPE_ONE_TIME && (
                                <TextField
                                    label="Quantity"
                                    type="number"
                                    value={quantity}
                                    onChange={handleQuantityChange}
                                    inputProps={{ min: 1 }}
                                    fullWidth
                                    margin="normal"
                                />
                            )}

                            <Box
                                position={'relative'} top={'25%'}
                            >
                                <Typography variant="h3" color="primary" mt={2}>
                                    {t('app.totalPriceLabel')}: €{selectedProgramPriceTotalPrice}
                                </Typography>
                            </Box>


                            <Box
                                position={'relative'} top={'25%'}
                            >
                                <Box>
                                    <Typography variant={"body1"} fontSize={12}>
                                        *{t('app.byPurchasingThisItemLabel')}
                                        <Link
                                            ml={1}
                                            href={"https://drehmomentpole.de/agbs/"}
                                            color={"inherit"}
                                            target={'_blank'}
                                        >{t('app.termsAndConditionsLabel')}</Link>
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant={"body1"} fontSize={12}>
                                        <Box>
                                            <Typography variant={"body1"} fontSize={12}>
                                                **{
                                                appLocale.locale === LOCALE_DE ?
                                                    program?.cancellationPolicy || '' :
                                                    program?.cancellationPolicy_en || ''}
                                            </Typography>
                                        </Box>
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseBuyClassPassDialog} color="primary">
                        {t('app.cancelButtonLabel')}
                    </Button>

                    {user && selectedProgramPrice?.type === PRICING_TYPE_ONE_TIME &&(
                        <LoadingButton loading={isPurchasing} onClick={purchaseClassPass} variant="contained" color="primary">
                            {t('app.payLabel')} €{selectedProgramPriceTotalPrice}
                        </LoadingButton>
                    )}

                    {user && selectedProgramPrice?.type === PRICING_TYPE_INSTALMENTAL &&(
                        <LoadingButton loading={isPurchasing} onClick={purchaseClassPass} variant="contained" color="primary">
                            {t('app.subscribeForLabel')} €{selectedProgramPrice?.amount}/{t('app.monthLabel')}
                        </LoadingButton>
                    )}

                    {!user && (
                        <Button onClick={() => {
                            trackButtonClickEvent(`login to buy class pass`)
                            goToLogin()
                        }} variant="contained" color="primary">
                            {t('app.loginToBuyLabel')}
                        </Button>
                    )}

                </DialogActions>
            </Dialog>

            <Dialog fullScreen={true} open={isStripeCheckoutDialogOpen} onClose={onCloseStripeCheckoutDialog} maxWidth={'xl'} fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h1">{t('app.purchaseClassPassLabel')}</Typography>
                        <Button onClick={onCloseStripeCheckoutDialog} color="primary">
                            {t('app.cancelButtonLabel')}
                        </Button>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {stripeCheckoutOptions.clientSecret !== "" && (
                        <Box>
                            <EmbeddedCheckoutProvider
                                stripe={stripePromise}
                                options={stripeCheckoutOptions}
                            >
                                <EmbeddedCheckout />
                            </EmbeddedCheckoutProvider>
                        </Box>

                    )}
                </DialogContent>
            </Dialog>


            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                open={isScheduleDialogOpen}
            >
                <DialogContent>
                    <Typography variant={'h1'}>
                        <CircleIcon  sx={{fontSize: 16, paddingRight: '2px', paddingTop: '3px'}} color={'info'} />
                        {selectedSchedule?.title}
                    </Typography>
                    <Typography variant={'h5'}>
                        <CalendarMonthIcon sx={{fontSize: 16 , paddingRight: '2px', paddingTop: '3px'}} color={'action'}/>
                        {dayjs(selectedSchedule?.start).format('dddd, MMMM D')} {dayjs(selectedSchedule?.start).format('HH:mm')} - {dayjs(selectedSchedule?.end).format('HH:mm')}
                    </Typography>

                    <Box mt={5}>
                        <Typography variant={"body1"} fontSize={12}>
                            *{t('app.byBookingThisClassLabel')}
                            <Link
                                ml={1}
                                href={"https://drehmomentpole.de/agbs/"}
                                color={"inherit"}
                                target={'_blank'}
                            >{t('app.termsAndConditionsLabel')}</Link>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant={"body1"} fontSize={12}>
                            **{
                                appLocale.locale === LOCALE_DE ?
                                    program?.cancellationPolicy || '' :
                                    program?.cancellationPolicy_en || ''
                        }
                        </Typography>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        trackButtonClickEvent(`cancel book class: ${program.name}`)
                        displayScheduleDialog(false)
                        setSelectedSchedule(null);

                    }}>
                        {t('app.cancelLabel')}
                    </Button>

                    {user && (
                        <LoadingButton loading={isBookingClass} onClick={bookClass} variant="contained" color="primary">
                            {t('app.bookClassLabel')}
                        </LoadingButton>
                    )}

                    {!user && (
                        <Button onClick={() => {
                            trackButtonClickEvent(`login to book class`)
                            goToLogin();
                        }} variant="contained" color="primary">
                            {t('app.loginToBookClassLabel')}
                        </Button>
                    )}

                </DialogActions>
            </Dialog>



        </React.Fragment>
    );
}

export default Program;