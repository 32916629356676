import React, {useCallback, useEffect, useState} from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {useTranslation} from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import {StorageService} from "../../services/StorageService";
import {UserInfoService} from "../../services/UserInfoService";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {ASSET_IMAGES} from "../../utils/constants/paths";
import Link from "@mui/material/Link";
import {SUPPORTED_LOCALES} from "../../utils/locales";
import useJumboTheme from "../../../@jumbo/hooks/useJumboTheme";
import {trackPageViewEvent} from "../../utils/tracking";


const Welcome = () => {

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const returnUrl = searchParams.get('returnUrl') || null;

    const {setAuthToken} = useJumboAuth();



    const [isStepOneVisible, setIsStepOneVisible] = useState(true);
    const [isStepTwoVisible, setIsStepTwoVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const [phoneNumber, setPhoneNumber] = useState('');
    const [addressLineOne, setAddressLineOne] = useState('');
    const [addressLineTwo, setAddressLineTwo] = useState('');
    const [city, setCity] = useState('');
    const [region, setRegion] = useState('');
    const [postalCode, setPostalCode] = useState('');

    const [country, setCountry] = useState('');
    const [whereDidYouHearAboutUs, setWhereDidYouHearAboutUs] = useState('');

    const [isFitForSports, setIsFitForSports] = useState(false);
    const [acceptInjuryResponsibility, setAcceptInjuryResponsibility] = useState(false);
    const [agreesToEmailComms, setAgreesToEmailComms] = useState(false);


    const user = StorageService.loadUser();

    const { locale } = useParams();

    const defaultLanguage = SUPPORTED_LOCALES[0]

    const [languages, setLanguages] = useState(SUPPORTED_LOCALES);
    const [selectedLanguage, setSelectedLanguage] = useState(StorageService.loadAppLocale()?.locale || defaultLanguage.locale);
    const [selectedLanguageObject, setSelectedLanguageObject] = useState(defaultLanguage);
    const {setMuiLocale} = useJumboTheme();
    const [activeLocale, setActiveLocale] = React.useState(languages[1]);


    useEffect(() => {
        trackPageViewEvent('welcome', '/welcome');

        changeLanguage(locale || selectedLanguage);

    }, []);

    const changeLanguage = (value) => {
        setSelectedLanguage(value);

        const localeIndex = languages.findIndex(language => language.locale === value);
        if (localeIndex !== -1) {
            i18n.changeLanguage(languages[localeIndex].locale).then(() => {
                setActiveLocale(languages[localeIndex]);
                setMuiLocale(languages[localeIndex].muiLocale);

                StorageService.saveAppLocale(languages[localeIndex]);

                setSelectedLanguageObject(languages[localeIndex]);
            });
        }
    }



    useEffect( () => {


    }, []);

    const handleNext = () => {
      setIsStepOneVisible(false);
      setIsStepTwoVisible(true);
    }

    const handleBack = () => {
        setIsStepOneVisible(true);
        setIsStepTwoVisible(false);
    }

    const handleFinish = async () => {
        setIsLoading(true);

        const fitForSports = isFitForSports ? 'Yes' : null;
        const acceptsInjuryResponsibility = acceptInjuryResponsibility ? 'Yes' : null;
        const agreeToEmailComms = agreesToEmailComms ? 1 : 0;

        const response = await UserInfoService.createUserInfo(
            phoneNumber, addressLineOne,addressLineTwo,
            city, region, postalCode,
            country,fitForSports, whereDidYouHearAboutUs, acceptsInjuryResponsibility,
            agreeToEmailComms, user.token);

        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);
        // @ts-ignore
        if (response.ok) {
            if (data) {

                const postWelcomeUrl = returnUrl || '/';

                navigate(decodeURIComponent(postWelcomeUrl), {replace: true});

                //navigate('/', {replace: true})

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }

    }

    const handleFieldChange = useCallback(
        (callback, e) => {
            const value = e.target.value;
            callback(value);

        },
        [setPhoneNumber, setAddressLineOne, setAddressLineTwo,
            setCity, setRegion, setPostalCode, setCountry, setIsFitForSports],
    );



    return (
        <React.Fragment>

            <Div sx={{
                flex: 1,
                flexWrap: 'wrap',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: theme => theme.spacing(4),
            }}>
                <Box sx={{
                    width: 720,
                    maxWidth: '100%',
                    margin: 'auto',
                    marginBottom: '20px',
                    p: 4}}>
                    <Box align={'center'}>
                        <img src={`${ASSET_IMAGES}/drehmoment_logo.svg`} alt="Drehmoment" />
                    </Box>
                    <Card>
                        <CardContent>
                            <>
                                <Typography
                                    variant="h1"
                                    mb={.25}
                                    sx={{ fontWeight: 'bold', textTransform:'uppercase' }}
                                >
                                    {t('app.weNeedMoreInfoLabel')}
                                </Typography>

                            </>
                    <Box mt={5}>
                        {isStepOneVisible && (
                            <Box>
                                <Box>
                                    <TextField
                                        label={t('app.telephoneNumberLabel')}
                                        placeholder={'+49 1512 345 679'}
                                        type={'tel'}
                                        value={phoneNumber}
                                        fullWidth
                                        onChange={(e) =>
                                            {handleFieldChange(setPhoneNumber, e)}}
                                        sx={{ marginBottom: 2 }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        label={t('app.addressLineOneLabel')}
                                        placeholder={'65 Hansen Way'}
                                        value={addressLineOne}
                                        fullWidth
                                        onChange={(e) =>
                                        {handleFieldChange(setAddressLineOne, e)}}
                                        sx={{ marginBottom: 2  }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        label={t('app.addressLineTwoLabel')}
                                        placeholder={'Apartment 4'}
                                        value={addressLineTwo}
                                        fullWidth
                                        onChange={(e) =>
                                        {handleFieldChange(setAddressLineTwo, e)}}
                                        sx={{ marginBottom: 2 }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        label={t('app.cityLabel')}
                                        placeholder={'Palo Alto'}
                                        value={city}
                                        onChange={(e) =>
                                        {handleFieldChange(setCity, e)}}
                                        fullWidth
                                        sx={{ marginBottom: 2 }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        label={t('app.regionLabel')}
                                        placeholder={'California'}
                                        value={region}
                                        onChange={(e) =>
                                        {handleFieldChange(setRegion, e)}}
                                        fullWidth
                                        sx={{ marginBottom: 2 }}
                                    />
                                </Box>

                                <Box>
                                    <TextField
                                        label={t('app.postalCodeLabel')}
                                        placeholder={'94304'}
                                        value={postalCode}
                                        onChange={(e) =>
                                        {handleFieldChange(setPostalCode, e)}}
                                        fullWidth
                                        sx={{ marginBottom: 2 }}
                                    />
                                </Box>

                                <Box>
                                    <TextField
                                        label={t('app.countryLabel')}
                                        placeholder={'United States'}
                                        value={country}
                                        onChange={(e) =>
                                        {handleFieldChange(setCountry, e)}}
                                        fullWidth
                                        sx={{ marginBottom: 2 }}
                                    />
                                </Box>

                                <Box>
                                    <TextField
                                        label={t('app.whereDidYouHearAboutUsLabel')}
                                        value={whereDidYouHearAboutUs}
                                        onChange={(e) =>
                                        {handleFieldChange(setWhereDidYouHearAboutUs, e)}}
                                        fullWidth
                                        sx={{ marginBottom: 2 }}
                                    />
                                </Box>


                                <Div>
                                    <Button variant={'outlined'} onClick={handleNext}>
                                        {t('app.nextButtonLabel')}
                                    </Button>
                                </Div>
                            </Box>
                        )}

                        {isStepTwoVisible && (
                            <Box>
                                <Box mb={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isFitForSports}
                                                onChange={() => setIsFitForSports(!isFitForSports)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label={t('app.fitForSportsMessage')}
                                    />
                                </Box>

                                <Box mb={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={acceptInjuryResponsibility}
                                                onChange={() => setAcceptInjuryResponsibility(!acceptInjuryResponsibility)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label={t('app.acceptInjuryResponsibilityMessage')}
                                    />
                                </Box>

                                <Box mb={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={agreesToEmailComms}
                                                onChange={() => setAgreesToEmailComms(!agreesToEmailComms)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label={t('app.agreeToEmailCommsMessage')}
                                    />
                                    <Box sx={{marginLeft:3.8}}>
                                        <Link
                                            href={"https://drehmomentpole.de/agbs/"}
                                            color={"inherit"}
                                            target={'_blank'}
                                        >
                                            {t('app.seeOurTermsMessage')}
                                        </Link>
                                    </Box>

                                </Box>



                                <Div>
                                    <Button variant={'outlined'} onClick={handleBack}
                                            sx={{marginRight:1}}>
                                        {t('app.backButtonLabel')}
                                    </Button>
                                    <LoadingButton disabled={!isFitForSports || !acceptInjuryResponsibility} loading={isLoading} variant={'contained'} onClick={handleFinish}>
                                        {t('app.submitButtonLabel')}
                                    </LoadingButton>
                                </Div>
                            </Box>
                        )}

                    </Box>
                        </CardContent>
                    </Card>
                </Box>
                {/*<Box>*/}
                {/*    <LoadingButton loading={false}  variant="text" onClick={handleSkip}>*/}
                {/*        {t('app.skipButtonLabel')}*/}
                {/*    </LoadingButton>*/}
                {/*</Box>*/}
            </Div>
        </React.Fragment>
    );
}

export default Welcome;