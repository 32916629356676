import {logEvent} from "firebase/analytics";
import {analytics} from "../../index";


export const trackEvent = (eventName, data) => {
    if (!window.location.hostname.includes('app.drehmomentpole.de')) {
        return
    }

    if (data) {
        logEvent(analytics, eventName, data);
    } else {
        logEvent(analytics, eventName);
    }
}

export const trackLoginEvent = () => {
    trackEvent('login');
}

export const trackLoginFailedEvent = () => {
    trackEvent('incorrect_login_credentials');
}

export const trackSignUpEvent = () => {
    trackEvent('sign_up');
}

export const trackActivateAccountEvent = () => {
    trackEvent('activate_account');
}

export const trackPageViewEvent = (name, url) => {
    trackEvent('page_view', {
        page_name: name,
        page_url: url
    });
}

export const trackModalViewEvent = (name) => {
    trackEvent('modal_view', {
        modal_name: name,
    });
}

export const trackButtonClickEvent = (name) => {
    trackEvent('button_click', {
        button_name: name,
    });
}

export const trackPurchaseInitiatedEvent = (itemName, itemType) => {
    trackEvent('purchase_initiated', {
        item_name: itemName,
        item_type: itemType
    });
}

export const trackPurchaseSuccessfulEvent = (itemName, itemType) => {
    trackEvent('purchase_successful', {
        item_name: itemName,
        item_type: itemType
    });
}

export const trackPurchaseFailedEvent = (itemName, itemType) => {
    trackEvent('purchase_failed', {
        item_name: itemName,
        item_type: itemType
    });
}

export const trackBookingInsufficientClassPassesEvent = (className) => {
    trackEvent('booking_insufficient_class_passes', {
        class_name: className
    });
}

export const trackBookingProgramSubscriptionOnHoldEvent = (className) => {
    trackEvent('booking_program_subscription_on_hold', {
        class_name: className
    });
}

export const trackBookingSuccessfulEvent = (className) => {
    trackEvent('booking_successful', {
        class_name: className
    });
}

export const trackBookingExistsEvent = (className) => {
    trackEvent('booking_exists', {
        class_name: className
    });
}

export const trackVideoPlayedEvent = (videoName, courseName, isFreeVideo) => {
    if (isFreeVideo) {
        trackEvent('free_video_played', {
            video_name: videoName,
            course_name: courseName
        });
        return;
    }
    trackEvent('video_played', {
        video_name: videoName,
        course_name: courseName
    });
}

export const trackVideoEndedEvent = (videoName, courseName) => {
    trackEvent('video_ended', {
        video_name: videoName,
        course_name: courseName
    });
}

export const trackVideoErrorEvent = (videoName, courseName) => {
    trackEvent('video_error', {
        video_name: videoName,
        course_name: courseName
    });
}

export const trackClassIntroVideoPlayedEvent = (className) => {
    trackEvent('class_intro_video_played', {
        class_name: className,
    });
}

export const trackPreviewVideoPlayedEvent = (videoName, videoType) => {
    trackEvent('preview_video_played', {
        video_name: videoName,
        video_typw: videoType
    });
}

export const trackAddToLibraryEvent = (itemName, itemType, isFreeItem) => {
    trackEvent('add_to_library', {
        item_name: itemName,
        item_type: itemType,
        is_free_item: isFreeItem
    });
}

export const trackRequestFailedEvent = (requestName) => {
    trackEvent('request_failed', {
        request_name: requestName
    });
}