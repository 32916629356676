import {APIService} from './APIService';

export class UserLibraryService extends APIService {
  static async addToLibrary(accessCode,resourceUid, resourceType, accessToken) {

    return await this.makeRequest(
      'api/user-library/add',
        {
            accessCode,
            resourceUid,
            resourceType
        },
      {
          'Content-Type': 'application/json',
          'x-access-token': accessToken
      },
        APIService.METHOD_POST,
    );
  }

    static async addFreeRecordingToLibrary(resourceUid, resourceType, accessToken) {

        return await this.makeRequest(
            'api/user-library/add-free-recording',
            {
                resourceUid,
                resourceType
            },
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_POST,
        );
    }


    static async isInUserLibrary(resourceUid, accessToken) {
        return await this.makeRequest(
            `api/user-library/${resourceUid}/check`,
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }

    static async getUserLibrary(uid, accessToken) {
        return await this.makeRequest(
            `api/user/library`,
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }
}
