import React, {useEffect, useState, Suspense, useCallback, useRef} from 'react';
import Stack from "@mui/material/Stack";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
import {
    Box,
    Button,
    ButtonGroup, ClickAwayListener, Grow,
    IconButton,
    ListItemIcon,
    ListItemText, Paper,
    Popper,
    Select,
    Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "../../../../shared/Logo";
import {SIDEBAR_STYLES, SIDEBAR_VARIANTS} from "@jumbo/utils/constants";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import {StorageService} from "../../../../services/StorageService";
import useJumboTheme from "../../../../../@jumbo/hooks/useJumboTheme";
import useJumboSidebarTheme from "../../../../../@jumbo/hooks/useJumboSidebarTheme";
import useJumboFooterTheme from "../../../../../@jumbo/hooks/useJumboFooterTheme";
import {mainTheme as mainThemeDark} from "../../../../themes/main/dark";
import {headerTheme as headerThemeDark} from "../../../../themes/header/dark";
import {sidebarTheme as sidebarThemeDark} from "../../../../themes/sidebar/dark";
import {footerTheme as footerThemeDark} from "../../../../themes/footer/dark";
import {mainTheme as mainThemeDefault} from "../../../../themes/main/default";
import {headerTheme as headerThemeDefault} from "../../../../themes/header/default";
import {sidebarTheme as sidebarThemeDefault} from "../../../../themes/sidebar/default";
import {footerTheme as footerThemeDefault} from "../../../../themes/footer/default";
import {useNavigate} from "react-router-dom";
import {getSupportedLocale, LOCALE_EN, SUPPORTED_LOCALES} from "../../../../utils/locales";
import {useTranslation} from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import {trackButtonClickEvent} from "../../../../utils/tracking";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import TranslateIcon from '@mui/icons-material/Translate';
import {Menu} from "@mui/icons-material";
import MenuList from "@mui/material/MenuList";

const Header = () => {
    const {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();
    const {theme, setTheme, setMuiLocale} = useJumboTheme();
    const {headerTheme, setHeaderTheme} = useJumboHeaderTheme();
    const {setSidebarTheme} = useJumboSidebarTheme();
    const {setFooterTheme} = useJumboFooterTheme();
    const navigate = useNavigate();

    const user = StorageService.loadUser();
    const [selectedLanguageObject, setSelectedLanguageObject] = useState(null);
    const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedLanguageLocale, setSelectedLanguageLocale] = useState(
        StorageService.loadAppLocale()?.locale
        || LOCALE_EN);

    const [selectedLanguageIso2, setSelectedLanguageIso2] = useState(
        StorageService.loadAppLocale()?.iso2
        || 'EN');

    const {t, i18n} = useTranslation();
    const themeColor = StorageService.loadAppThemeColor() || 'light';


    const handleLanguageDropdownToggle = () => {
        trackButtonClickEvent('language select dropdown');

        setIsLanguageDropdownOpen((prevOpen) => !prevOpen);
    };

    const handleLanguageDropdownClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setIsLanguageDropdownOpen(false);
    };

    useEffect(async () => {

        if(themeColor === 'dark') {
            await setTheme({mode: "dark", ...mainThemeDark});
            setHeaderTheme({...theme, ...headerThemeDark});
            setSidebarTheme({...theme, ...sidebarThemeDark});
            setFooterTheme({...theme, ...footerThemeDark});
        }
        else {
            await setTheme({mode: "light", ...mainThemeDefault});
            setHeaderTheme({...theme, ...headerThemeDefault});
            setSidebarTheme({...theme, ...sidebarThemeDefault});
            setFooterTheme({...theme, ...footerThemeDefault});
        }

        const locale = user?.locale || selectedLanguageLocale;
        const supportedLocale = getSupportedLocale(locale)

        await i18n.changeLanguage(supportedLocale.locale).then(async () => {
            setMuiLocale(supportedLocale.muiLocale);
        });

    }, []);

    const goToLogin = () => {
        trackButtonClickEvent('login_from_header');
        navigate('/login');
    }

    const goToSignup = () => {
        trackButtonClickEvent('signup_from_header');
        navigate('/signup');
    }

    const handleThemeChange = React.useCallback(async (theme) => {
        StorageService.saveAppThemeColor(theme);
        if(theme === 'dark') {
            await setTheme({mode: "dark", ...mainThemeDark});
            setHeaderTheme({...theme, ...headerThemeDark});
            setSidebarTheme({...theme, ...sidebarThemeDark});
            setFooterTheme({...theme, ...footerThemeDark});
            return;
        }
        await setTheme({mode: "light", ...mainThemeDefault});
        setHeaderTheme({...theme, ...headerThemeDefault});
        setSidebarTheme({...theme, ...sidebarThemeDefault});
        setFooterTheme({...theme, ...footerThemeDefault});
    }, []);

    const changeLanguage = (language) => {
        trackButtonClickEvent(`language change: ${language.iso2}`);

        setSelectedLanguageIso2(language.iso2);
        setSelectedLanguageLocale(language.locale);

        i18n.changeLanguage(language.locale).then(async () => {
            setMuiLocale(language.muiLocale);

            StorageService.saveAppLocale(language);

            setSelectedLanguageObject(language);
            setIsLanguageDropdownOpen(false);
            window.location.reload();
        });
    }

    return (
        <React.Fragment>
            {
                (
                    sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                    || sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY
                    || (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT && !sidebarOptions.open)
                ) &&
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{
                            ml: sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ? -2 : 0,
                            mr: 3,
                        }}
                        onClick={() => setSidebarOptions({open: !sidebarOptions.open, anchor: 'left', hide: false})}
                    >
                        {
                            sidebarOptions?.open ? <MenuOpenIcon/> : <MenuIcon/>
                        }
                    </IconButton>
            }
            {
                sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <Logo sx={{mr: 3}} mode={headerTheme.type ?? "light"}/>
            }

            <Stack direction="row" alignItems="center" spacing={1.25} sx={{ml: "auto"}}>
                {user && (
                    <Suspense fallback={<Box/>}>
                        <AuthUserDropdown/>
                    </Suspense>
                )}

                {!user && (
                    <Box>
                            <Button
                                onClick={handleLanguageDropdownToggle}
                                startIcon={<TranslateIcon />}
                                ref={anchorRef}
                                size="small"
                                aria-controls={isLanguageDropdownOpen ? 'split-button-menu' : undefined}
                                aria-expanded={isLanguageDropdownOpen ? 'true' : undefined}
                                // aria-label="select language"
                                aria-label="Language selector split button"
                                aria-haspopup="menu"
                                color={'secondary'}
                            >
                                {selectedLanguageIso2}
                            </Button>
                        <Popper
                            sx={{ zIndex: 1 }}
                            open={isLanguageDropdownOpen}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleLanguageDropdownClose}>
                                            <MenuList id="split-button-menu" autoFocusItem>
                                                {SUPPORTED_LOCALES.map((language, index) => (
                                                    <MenuItem
                                                        key={language.locale}
                                                        selected={language.locale === selectedLanguageLocale}
                                                        onClick={() => changeLanguage(language)}
                                                    >
                                                        {language.localizedLanguage?.toUpperCase()}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>


                        <Button sx={{marginLeft: 1,backgroundColor: "#000"}}
                                onClick={goToLogin}
                                size={'small'} variant={'contained'} color={'secondary'}>{t('app.logInButtonLabel')}</Button>
                        <Button
                            sx={{marginLeft: 1, backgroundColor: "#FFF", color: "#000"}}
                            onClick={goToSignup}
                            size={'small'} variant={'contained'} color={'secondary'}>{t('app.signUpButtonLabel')}</Button>
                    </Box>
                )}

            </Stack>
        </React.Fragment>
    );
};

export default Header;
