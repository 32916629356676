import {APIService} from './APIService';

export class UserClassPassService extends APIService {

    static async getUserAvailableClassPassForProgram(programUid, accessToken) {
        return await this.makeRequest(
            `api/class-passes/${programUid}/user`,
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }
}
