import  secureLocalStorage  from  "react-secure-storage";
import {SUPPORTED_LOCALES} from "../utils/locales";

export class StorageService {

  static USER_KEY = 'aa';
  static CONTENT_CATEGORY_KEY = 'cc';
  static SUPPORTED_LOCALES_KEY = 'vf';
  static SUPPORTED_CONTENT_LANGUAGES_KEY = 'uc';
  static BUSINESS_KEY = 'bb';
  static PROJECTS_KEY = 'dd';
  static IS_ACCOUNT_OWNER_KEY = 'DjY';
  static TEAM_KEY = 'ff';
  static SELECTED_BUSINESS_KEY = 'sbb';
  static SELECTED_PROJECT_KEY = 'sdd';
  static PLAN_KEY = 'ppbb';
  static SUBSCRIPTION_KEY = 'xcii';
  static PLAN_FEATURES_KEY = 'vuhh';
  static APP_LOCALE_KEY = 'aloc';
  static APP_THEME_KEY = 'athm';
  static BRAND_VOICES_KEY = 'nvbd';



  static saveUser(user) {
    secureLocalStorage.setItem(StorageService.USER_KEY, user);
  }

  static loadUser() {
    return secureLocalStorage.getItem(StorageService.USER_KEY);
  }

  static async getCurrentUser() {
    return await Promise.resolve(secureLocalStorage.getItem(StorageService.USER_KEY));
  }

  static deleteUser() {
    secureLocalStorage.removeItem(StorageService.USER_KEY);
  }

  static saveSupportedLocales(supportedLocales) {
    secureLocalStorage.setItem(StorageService.SUPPORTED_LOCALES_KEY, supportedLocales);
  }

  static loadSupportedLocales() {
    return secureLocalStorage.getItem(StorageService.SUPPORTED_LOCALES_KEY);
  }

  static deleteSupportedLocales() {
    secureLocalStorage.removeItem(StorageService.SUPPORTED_LOCALES_KEY);
  }

  static saveSupportedContentLanguages(supportedContentLanguages) {
    secureLocalStorage.setItem(StorageService.SUPPORTED_CONTENT_LANGUAGES_KEY, supportedContentLanguages);
  }

  static loadSupportedContentLanguages() {
    return secureLocalStorage.getItem(StorageService.SUPPORTED_CONTENT_LANGUAGES_KEY);
  }

  static deleteSupportedContentLanguages() {
    secureLocalStorage.removeItem(StorageService.SUPPORTED_CONTENT_LANGUAGES_KEY);
  }

  static saveContentCategories(contentCategories) {
    secureLocalStorage.setItem(StorageService.CONTENT_CATEGORY_KEY, contentCategories);
  }

  static loadContentCategories() {
    return secureLocalStorage.getItem(StorageService.CONTENT_CATEGORY_KEY);
  }

  static deleteContentCategories() {
    secureLocalStorage.removeItem(StorageService.CONTENT_CATEGORY_KEY);
  }

  static saveBusinesses(businesses) {
    secureLocalStorage.setItem(StorageService.BUSINESS_KEY, businesses);
  }

  static loadBusinesses() {
    return secureLocalStorage.getItem(StorageService.BUSINESS_KEY);
  }

  static deleteBusinesses() {
    secureLocalStorage.removeItem(StorageService.BUSINESS_KEY);
  }

  static saveSelectedBusiness(business) {
    secureLocalStorage.setItem(StorageService.SELECTED_BUSINESS_KEY, business);
  }

  static loadSelectedBusiness() {
    return secureLocalStorage.getItem(StorageService.SELECTED_BUSINESS_KEY);
  }

  static deleteSelectedBusiness() {
    secureLocalStorage.removeItem(StorageService.SELECTED_BUSINESS_KEY);
  }

  static saveProjects(projects) {
    secureLocalStorage.setItem(StorageService.PROJECTS_KEY, projects);
  }

  static loadProjects() {
    return secureLocalStorage.getItem(StorageService.PROJECTS_KEY);
  }

  static deleteProjects() {
    secureLocalStorage.removeItem(StorageService.PROJECTS_KEY);
  }


  static saveSelectedProject(project) {
    secureLocalStorage.setItem(StorageService.SELECTED_PROJECT_KEY, project);
  }

  static loadSelectedProject() {
    return secureLocalStorage.getItem(StorageService.SELECTED_PROJECT_KEY);
  }

  static deleteSelectedProject() {
    secureLocalStorage.removeItem(StorageService.SELECTED_PROJECT_KEY);
  }

  static saveTeam(team) {
    secureLocalStorage.setItem(StorageService.TEAM_KEY, team);
  }

  static loadTeam() {
    return secureLocalStorage.getItem(StorageService.TEAM_KEY);
  }

  static deleteTeam() {
    secureLocalStorage.removeItem(StorageService.TEAM_KEY);
  }

  static savePlan(plan) {
    secureLocalStorage.setItem(StorageService.PLAN_KEY, plan);
  }

  static loadPlan() {
    return secureLocalStorage.getItem(StorageService.PLAN_KEY);
  }

  static deletePlan() {
    secureLocalStorage.removeItem(StorageService.PLAN_KEY);
  }


  static saveSubscription(subscription) {
    secureLocalStorage.setItem(StorageService.SUBSCRIPTION_KEY, subscription);
  }

  static loadSubscription() {
    return secureLocalStorage.getItem(StorageService.SUBSCRIPTION_KEY);
  }

  static deleteSubscription() {
    secureLocalStorage.removeItem(StorageService.SUBSCRIPTION_KEY);
  }


  static savePlanFeatures(planFeatures) {
    secureLocalStorage.setItem(StorageService.PLAN_FEATURES_KEY, planFeatures);
  }

  static loadPlanFeatures() {
    return secureLocalStorage.getItem(StorageService.PLAN_FEATURES_KEY);
  }

  static deletePlanFeatures() {
    secureLocalStorage.removeItem(StorageService.PLAN_FEATURES_KEY);
  }

  static saveAppLocale(locale) {
    secureLocalStorage.setItem(StorageService.APP_LOCALE_KEY, locale);
  }

  static loadAppLocale() {
    return secureLocalStorage.getItem(StorageService.APP_LOCALE_KEY) ?? SUPPORTED_LOCALES[1];
  }

  static deleteAppLocale() {
    secureLocalStorage.removeItem(StorageService.APP_LOCALE_KEY);
  }

  static saveAppThemeColor(themeColor) {
    secureLocalStorage.setItem(StorageService.APP_THEME_KEY, themeColor);
  }

  static loadAppThemeColor() {
    return secureLocalStorage.getItem(StorageService.APP_THEME_KEY);
  }

  static deleteAppThemeColor() {
    secureLocalStorage.removeItem(StorageService.APP_THEME_KEY);
  }

  static saveIsAccountOwner(isAccountOwner) {
    secureLocalStorage.setItem(StorageService.IS_ACCOUNT_OWNER_KEY, isAccountOwner);
  }

  static loadIsAccountOwner() {
    return secureLocalStorage.getItem(StorageService.IS_ACCOUNT_OWNER_KEY);
  }

  static deleteIsAccountOwner() {
    secureLocalStorage.removeItem(StorageService.IS_ACCOUNT_OWNER_KEY);
  }

  static saveBrandVoices(brandVoices) {
    secureLocalStorage.setItem(StorageService.BRAND_VOICES_KEY, brandVoices);
  }

  static loadBrandVoices() {
    return secureLocalStorage.getItem(StorageService.BRAND_VOICES_KEY);
  }

  static deleteBrandVoices() {
    secureLocalStorage.removeItem(StorageService.BRAND_VOICES_KEY);
  }

  static logout() {
    StorageService.deleteUser();
    StorageService.deleteTeam();
    StorageService.deleteBusinesses();
    StorageService.deleteSelectedBusiness();
    StorageService.deletePlan();
    StorageService.deleteSubscription();
    StorageService.deletePlanFeatures();
    StorageService.deleteProjects();
    StorageService.deleteSelectedProject();
    StorageService.deleteIsAccountOwner();
    StorageService.deleteBrandVoices();
  }

  static clear() {
    secureLocalStorage.clear();
  }
}