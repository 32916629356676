import React, {useCallback, useState, useEffect} from "react";
import Div from "@jumbo/shared/Div";
import {Box, Card, CardContent, Select, Typography} from "@mui/material";
import {SUPPORTED_LOCALES} from "../../../utils/locales";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {useNavigate, useParams} from "react-router-dom";
import useJumboTheme from "../../../../@jumbo/hooks/useJumboTheme";
import {StorageService} from "../../../services/StorageService";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import Swal from "sweetalert2";
import {AuthService} from "../../../services/AuthService";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {trackPageViewEvent} from "../../../utils/tracking";

const ResetPassword = () => {

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const { resetUid, locale } = useParams();

    const defaultLanguage = SUPPORTED_LOCALES[1]

    const validationSchema = yup.object({
        password: yup
            .string(t('app.passwordFieldFormValidationLabel'))
            .min(6, t('app.passwordFieldFormValidationMessage'))
            .required(t('app.passwordFieldFormValidationError')),
        confirmPassword: yup
            .string(t('app.passwordFieldFormValidationLabel'))
            .min(6, t('app.passwordFieldFormValidationMessage'))
            .required(t('app.passwordFieldFormValidationError')),
    });


    const [languages, setLanguages] = useState(SUPPORTED_LOCALES);
    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage.locale);
    const [selectedLanguageObject, setSelectedLanguageObject] = useState(defaultLanguage);
    const {setMuiLocale} = useJumboTheme();
    const [activeLocale, setActiveLocale] = React.useState(languages[0]);


    useEffect(() => {
        trackPageViewEvent('reset_password', '/reset-password');

        if (!resetUid) {
            alert('invalid reset link')
        }

        changeLanguage(locale || selectedLanguage);

    }, []);


    const handleLanguageChange = useCallback(
        (e) => {
            const value = e.target.value;
            changeLanguage(value);
        },
        [setSelectedLanguage],
    );

    const changeLanguage = (value) => {
        setSelectedLanguage(value);

        const localeIndex = languages.findIndex(language => language.locale === value);
        if (localeIndex !== -1) {
            i18n.changeLanguage(languages[localeIndex].locale).then(() => {
                setActiveLocale(languages[localeIndex]);
                setMuiLocale(languages[localeIndex].muiLocale);

                StorageService.saveAppLocale(languages[localeIndex]);

                setSelectedLanguageObject(languages[localeIndex]);
            });
        }
    }

    const onResetPassword = async (password, confirmPassword) => {

        if (password !== confirmPassword) {
            alert(t('app.newPasswordMisMatchErrorMessage'));
            return;
        }

        const response = await AuthService.resetPassword(resetUid, password);

        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        // @ts-ignore
        if (response.ok) {
            if (data) {

                Swal.fire({
                    text: t('app.passwordResetSuccessMessage'),
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: t('app.signInButtonLabel'),
                    confirmButtonColor: 'error',
                }).then(async (result) => {
                    if (result.value) {
                        navigate('/login', {replace: true});
                    } else if (
                        result.dismiss === Swal.DismissReason.cancel
                    ) {
                        navigate('/login', {replace: true});
                    }
                });


            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };

    return (
        <>
            <Div sx={{
                flex: 1,
                flexWrap: 'wrap',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: theme => theme.spacing(4),
            }}>
                <Box sx={{
                    width: 720,
                    maxWidth: '100%',
                    margin: 'auto',
                    marginBottom: '20px',
                    p: 4}}>
                    <Box align={'center'}>
                        <img src={`${ASSET_IMAGES}/drehmoment_logo.svg`} alt="Drehmoment" />

                    </Box>
                    <Card>
                        <CardContent sx={{minHeight: 700}}>
                            {/*<Typography variant="h3">*/}
                            {/*    {t('app.welcomeMessage')} {t('app.name')}*/}

                            {/*</Typography>*/}
                            <Typography variant={'h3'} gutterBottom>
                                {t('app.enterNewPasswordMessage')}
                            </Typography>

                            <Box mt={5}>

                                <Box>
                                    <Formik
                                        validateOnChange={true}
                                        initialValues={{
                                            password: '',
                                            confirmPassword: '',
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={(data, {setSubmitting}) => {
                                            setSubmitting(true);
                                            onResetPassword(data.password, data.confirmPassword);
                                            setSubmitting(false);
                                        }}
                                    >
                                        {({isSubmitting}) => (
                                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>

                                                <Div sx={{mt: 1, mb: 2}}>
                                                    <JumboTextField
                                                        fullWidth
                                                        name="password"
                                                        label={t('app.passwordLabel')}
                                                        type="password"
                                                    />
                                                </Div>
                                                <Div sx={{mt: 1, mb: 2}}>
                                                    <JumboTextField
                                                        fullWidth
                                                        name="confirmPassword"
                                                        label={t('app.retypePasswordLabel')}
                                                        type="password"
                                                    />
                                                </Div>
                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    variant="contained"
                                                    size="large"
                                                    sx={{mb: 3}}
                                                    loading={isSubmitting}
                                                >
                                                    {t('app.setNewPasswordButtonLabel')}
                                                </LoadingButton>
                                            </Form>
                                        )}
                                    </Formik>
                                </Box>

                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Div>


        </>

    );

}

export default ResetPassword;