import {APIService} from './APIService';

export class CategoryService extends APIService {


    static async getPublishedCategoryCourses(uid, accessToken) {
        return await this.makeRequest(
            `api/categories/${uid}/courses/published`,
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }

    static async getOnDemandCategoryCourses(uid, accessToken) {
        return await this.makeRequest(
            `api/categories/${uid}/courses/on-demand`,
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }

    static async getPublishedCategoryVideos(uid, accessToken) {
        return await this.makeRequest(
            `api/categories/${uid}/videos/published`,
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }

    static async getOnDemandCategoryVideos(uid, accessToken) {
        return await this.makeRequest(
            `api/categories/${uid}/videos/on-demand`,
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }
}
