import React, {useEffect, useState} from 'react';
import {
    Box,
    Card, CardActionArea,
    CardContent, CardMedia, Dialog, DialogContent, DialogTitle,
    Divider,
    Grid,
    Typography
} from "@mui/material";
import {Skeleton} from "@mui/lab";
import {useNavigate, useSearchParams} from "react-router-dom";
import {StorageService} from "../../services/StorageService";
import {useTranslation} from "react-i18next";
import {LOCALE_DE, LOCALE_EN} from "../../utils/locales";
import {
    trackButtonClickEvent,
    trackPageViewEvent,
    trackPurchaseInitiatedEvent,
    trackPurchaseSuccessfulEvent, trackRequestFailedEvent
} from "../../utils/tracking";

import {
    Button,
    Container,
    Chip,
    Stack,
    useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {SubscriptionService} from "../../services/SubscriptionService";
import {TransactionsService} from "../../services/TransactionsService";
import {loadStripe} from "@stripe/stripe-js";
import {EmbeddedCheckout, EmbeddedCheckoutProvider} from "@stripe/react-stripe-js";
import {displayAlertDialog} from "../../utils/alertDialogs";
import dayjs from "dayjs";


const StyledCard = styled(Card)(({ theme, popular }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'translateY(-4px)',
    },
    ...(popular && {
        border: `2px solid ${theme.palette.secondary.main}`,
        boxShadow: theme.shadows[10],
    }),
}));

const FeatureItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& svg': {
        marginRight: theme.spacing(1),
        color: theme.palette.success.main,
    },
}));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Memberships = () => {

    const {t} = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();


    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [plans, setPlans] = useState(null);
    const [userPlan, setUserPlan] = useState(null);
    const [userSubscription, setUserSubscription] = useState(null);
    const [isStripeCheckoutDialogOpen, setIsStripeCheckoutDialogOpen] = useState(false)
    const [stripeCheckoutOptions, setStripeCheckoutOptions] = useState({clientSecret: ""});
    const [isJoining, setIsJoining] = useState(false);


    const user = StorageService.loadUser();
    const appLocale = StorageService.loadAppLocale();

    useEffect(
        async ()  => {
            trackPageViewEvent('memberships', '/memberships');

            await getPlans();

            const stripeCheckoutSessionId = searchParams.get("session_id");
            if (stripeCheckoutSessionId) {
                await fetchJoinMembershipTransactionStatus(stripeCheckoutSessionId);
            }
        },
        [],
    );

    const onCloseStripeCheckoutDialog = () => {
        trackButtonClickEvent(`cancel join membership stripe checkout`)
        setIsStripeCheckoutDialogOpen(false);
        window.location.reload();
    }


    const getPlans = async () => {

        setIsLoading(true);
        const response =  await SubscriptionService.getMembershipPlans(user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setPlans(data.plans);
                setUserPlan(data.userPlan);
                setUserSubscription(data.userSubscription);


            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const goToLogin = () => {
        navigate('/login');
    }

    const fetchJoinMembershipTransactionStatus = async (stripeCheckoutSessionId) => {
        setIsLoading(true);

        const response = await TransactionsService.fetchTransactionStatus(stripeCheckoutSessionId, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                if (data.status === 'complete') {
                    trackPurchaseSuccessfulEvent('Join Membership', 'membership');
                    await displayAlertDialog(t, 'app.joinMembershipPurchaseSuccessMessageLabel', 'success');
                }
                else {
                    trackRequestFailedEvent('Join Membership', 'membership')
                    await displayAlertDialog(t, 'app.paymentFailedMessageLabel', 'error');
                }

            } else {
                trackRequestFailedEvent('fetch_join_membership_transaction_status')
                alert(data.message);
            }
        } else {
            trackRequestFailedEvent('fetch_join_membership_transaction_status')
            alert(message);
        }
    };



    const joinMembership = async (plan) => {
        trackButtonClickEvent(`join membership ${plan?.name_en}`)
        setIsJoining(true);

        const response = await TransactionsService.joinMembership(plan?.uid, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsJoining(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                trackPurchaseInitiatedEvent(plan?.name_en, 'membership')
                setIsStripeCheckoutDialogOpen(true);
                setStripeCheckoutOptions({
                    clientSecret: data.stripeClientSecret
                });

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };


    return (
        <React.Fragment>
            <Typography
                variant="h1"
                mb={.25}
                sx={{ fontWeight: 'bold', textTransform:'uppercase' }}
            >
                {t('app.membershipsLabel')}
            </Typography>
            <Typography variant={'h6'} color={'text.secondary'}>

            </Typography>

            <Divider/>

            <Box mt={5}>

                <Box>
                    {
                        isLoading && (
                            <>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </>
                        )
                    }

                    {(!isLoading && plans?.length < 1) && (

                        <Card>
                            <CardContent>
                                    <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                        <Typography variant={'h4'}>
                                            {t('app.noMembershipsAvailable')}
                                        </Typography>
                                    </Box>
                            </CardContent>
                        </Card>
                    )}

                    {!isLoading && plans?.length > 0 && !userPlan && (
                        <>
                            <Box mb={3}>
                                <Typography variant={'subtitle2'} >
                                    {t('app.selectMembershipLabel').toUpperCase()}
                                </Typography>
                            </Box>

                            <Grid container spacing={4} justifyContent="center">
                                {plans.map((plan, index) => (
                                    <Grid item xs={12} md={4} key={plan.name}>
                                        <StyledCard popular={index === 1}>
                                            {index === 1 && (
                                                <Chip
                                                    label={t('app.mostPopularLabel')}
                                                    color="secondary"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: -11,
                                                        left: '50%',
                                                        transform: 'translateX(-50%)',
                                                    }}
                                                />
                                            )}
                                            <CardContent sx={{ p: 3, flexGrow: 1 }}>
                                                <Stack spacing={3}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                        <Typography variant="h5" component="h3" fontWeight="bold" sx={{
                                                            textTransform: 'uppercase'
                                                        }}>
                                                            {appLocale?.locale === LOCALE_DE ? plan?.name : plan?.name_en}
                                                        </Typography>
                                                        {userPlan?.uid === plan?.uid && (
                                                            <Chip
                                                                label="Current Membership"
                                                                color="secondary"
                                                                size="small"
                                                            />
                                                        )}
                                                    </Box>

                                                    <Box>
                                                        <Typography variant="h3" component="div" fontWeight="bold">
                                                            €{Math.floor(plan.billingPrice)}
                                                            <Typography variant="caption" sx={{marginLeft: 1}}>

                                                                {appLocale.locale === LOCALE_DE ? plan?.billingCycle : plan?.billingCycle_en}

                                                            </Typography>
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                                            €{plan.pricePerMonth} {appLocale.locale === LOCALE_DE ? 'pro monat' : 'per month'}

                                                        </Typography>
                                                        <Typography variant="subtitle1" color="text.secondary">
                                                            {appLocale.locale === LOCALE_DE ? plan?.billingCycleDescription : plan?.billingCycleDescription_en}

                                                        </Typography>

                                                        {plan.amountSavedDescription && (
                                                            <Chip
                                                                label={appLocale.locale === LOCALE_DE ? plan?.amountSavedDescription : plan?.amountSavedDescription_en}
                                                                color="success"
                                                                size="small"
                                                                sx={{ mt: 1}}
                                                            />
                                                        )}
                                                    </Box>

                                                    <Box>
                                                        {appLocale.locale === LOCALE_DE && (
                                                            plan.features.split(',').map((feature, index) => (
                                                                    <FeatureItem key={index}>
                                                                        <CheckCircleOutlineIcon />
                                                                        <Typography variant="body2">
                                                                            {feature}
                                                                        </Typography>
                                                                    </FeatureItem>
                                                            ))
                                                        )}

                                                        {appLocale.locale === LOCALE_EN && (
                                                            plan.features_en.split(',').map((feature, index) => (
                                                                <FeatureItem key={index}>
                                                                    <CheckCircleOutlineIcon />
                                                                    <Typography variant="body2">
                                                                        {feature}
                                                                    </Typography>
                                                                </FeatureItem>
                                                            ))
                                                        )}

                                                    </Box>

                                                    {user && (
                                                        <Button
                                                            variant={index === 1  ? "contained" : "outlined"}
                                                            color={index === 1  ? "secondary" : "primary"}
                                                            size="large"
                                                            fullWidth
                                                            disabled={isJoining}
                                                            onClick={async () => {await joinMembership(plan)}}
                                                        >
                                                            {t('app.joinMembershipLabel')}
                                                        </Button>
                                                    )}

                                                    {!user && (
                                                        <Button
                                                            variant={index === 1 ? "contained" : "outlined"}
                                                            color={index === 1? "secondary" : "primary"}
                                                            size="large"
                                                            fullWidth
                                                            onClick={goToLogin}
                                                        >
                                                            {t('app.loginToJoinMembershipLabel')}
                                                        </Button>
                                                    )}

                                                </Stack>
                                            </CardContent>
                                        </StyledCard>
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}

                    {!isLoading && user && userPlan && (
                        <>
                            <Box mb={3}>
                                <Typography variant={'subtitle2'} >
                                    {t('app.yourMembershipLabel').toUpperCase()}
                                </Typography>
                            </Box>

                            <Grid container spacing={4}>
                                    <Grid item xs={12} md={4} key={userPlan.name}>
                                        <StyledCard>
                                            <CardContent sx={{ p: 3, flexGrow: 1 }}>
                                                <Stack spacing={3}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                        <Typography variant="h5" component="h3" fontWeight="bold">
                                                            {appLocale?.locale === LOCALE_DE ? userPlan?.name : userPlan?.name_en}
                                                        </Typography>
                                                        <Chip
                                                            label={userSubscription?.status === 'paid'? 'active': userSubscription?.status }
                                                            color={userSubscription?.status === 'paid' || userSubscription?.status === 'active'? 'success': 'secondary' }
                                                            size="small"
                                                        />
                                                    </Box>

                                                    <Box>
                                                        <Typography variant="h3" component="div" fontWeight="bold">
                                                            €{userPlan.billingPrice}
                                                            <Typography variant="caption" sx={{marginLeft: 1}}>

                                                                {appLocale.locale === LOCALE_DE ? userPlan?.billingCycle : userPlan?.billingCycle_en}

                                                            </Typography>
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                                            {userSubscription?.status === 'canceled' && (
                                                                <>
                                                                    {t('app.endsOnLabel')}:
                                                                    <Typography color={'secondary'} variant="body2" sx={{display: 'inline', ml: 1}} >
                                                                        {dayjs(userSubscription?.nextBillingDate).format('MMMM D, YYYY')}
                                                                    </Typography>
                                                                </>

                                                            )}

                                                            {userSubscription?.status !== 'canceled' && (
                                                                <>
                                                                    {t('app.renewsOnLabel')}:
                                                                    <Typography color={'secondary'} variant="body2" sx={{display: 'inline', ml: 1}} >
                                                                        {dayjs(userSubscription?.nextBillingDate).format('MMMM D, YYYY')}
                                                                    </Typography>
                                                                </>
                                                            )}

                                                        </Typography>

                                                    </Box>

                                                    <Box>
                                                        {appLocale.locale === LOCALE_DE && (
                                                            userPlan.features.split(',').map((feature, index) => (
                                                                <FeatureItem key={index}>
                                                                    <CheckCircleOutlineIcon />
                                                                    <Typography variant="body2">
                                                                        {feature}
                                                                    </Typography>
                                                                </FeatureItem>
                                                            ))
                                                        )}

                                                        {appLocale.locale === LOCALE_EN && (
                                                            userPlan.features_en.split(',').map((feature, index) => (
                                                                <FeatureItem key={index}>
                                                                    <CheckCircleOutlineIcon />
                                                                    <Typography variant="body2">
                                                                        {feature}
                                                                    </Typography>
                                                                </FeatureItem>
                                                            ))
                                                        )}

                                                    </Box>

                                                    <Button
                                                        variant={'contained'}
                                                        color={'secondary'}
                                                        size="large"
                                                        fullWidth
                                                        onClick={() => {}}
                                                        href={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL + `?prefilled_email=${user?.email}` }
                                                        target={'_blank'}
                                                    >
                                                        {t('app.manageMembershipLabel')}
                                                    </Button>

                                                </Stack>
                                            </CardContent>
                                        </StyledCard>
                                    </Grid>
                            </Grid>
                        </>
                    )}
                </Box>
            </Box>

            <Dialog fullScreen={true} open={isStripeCheckoutDialogOpen} onClose={onCloseStripeCheckoutDialog} maxWidth={'xl'} fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h1">{t('app.joinMembershipLabel')}</Typography>
                        <Button onClick={onCloseStripeCheckoutDialog} color="primary">
                            {t('app.cancelButtonLabel')}
                        </Button>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {stripeCheckoutOptions.clientSecret !== "" && (
                        <Box>
                            <EmbeddedCheckoutProvider
                                stripe={stripePromise}
                                options={stripeCheckoutOptions}
                            >
                                <EmbeddedCheckout />
                            </EmbeddedCheckoutProvider>
                        </Box>

                    )}
                </DialogContent>
            </Dialog>

        </React.Fragment>
    );
}

export default Memberships;