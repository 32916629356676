import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AirIcon from '@mui/icons-material/Air';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import FlareIcon from '@mui/icons-material/Flare';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CycloneIcon from '@mui/icons-material/Cyclone';
import AnimationIcon from '@mui/icons-material/Animation';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import CoffeeIcon from '@mui/icons-material/Coffee';
import TodayIcon from '@mui/icons-material/Today';
import ClassIcon from '@mui/icons-material/Class';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import BadgeIcon from '@mui/icons-material/Badge';

const menus = [
    {
        label: 'sidebar.menuItem.mainMenu',
        type: "section",
        children: [
            {
                uri: "/",
                label: 'sidebar.menuItem.home',
                type: "nav-item",
                icon: <HomeIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/schedules",
                label: 'sidebar.menuItem.schedules',
                type: "nav-item",
                icon: <CalendarMonthIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/classes",
                label: 'sidebar.menuItem.classes',
                type: "nav-item",
                icon: <ClassIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/memberships",
                label: 'sidebar.menuItem.memberships',
                type: "nav-item",
                icon: <BadgeIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/bookings",
                label: 'sidebar.menuItem.myBookings',
                type: "nav-item",
                icon: <TodayIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/library",
                label: 'sidebar.menuItem.myLibrary',
                type: "nav-item",
                icon: <VideoLibraryIcon sx={{fontSize: 20}}/>
            },
        ]
    },
];

export const recordingsMenu = {
    label: 'sidebar.menuItem.onDemandCatalog',
        type: "section",
    children: [
        {
            uri: "/categories/48abf3cf-8bba-4f8a-87db-1fa2ae6bdfee",
            label: 'sidebar.menuItem.bodyfulBreathing',
            type: "nav-item",
            icon: <AccessibilityNewIcon sx={{fontSize: 20}}/>
        },
        {
            uri: "/categories/643745a2-254e-4d89-8dc2-61e1cdc01345",
            label: 'sidebar.menuItem.coffeeAndTreats',
            type: "nav-item",
            icon: <CoffeeIcon sx={{fontSize: 20}}/>
        },
        {
            uri: "/categories/c318483b-0653-4136-9515-6ea280b20c86",
            label: 'sidebar.menuItem.elevatedEssence',
            type: "nav-item",
            icon: <AnimationIcon sx={{fontSize: 20}}/>
        },
        {
            uri: "/categories/1685562f-9b20-465a-ac1f-7eba39050556",
            label: 'sidebar.menuItem.fluidFoundations',
            type: "nav-item",
            icon: <CycloneIcon sx={{fontSize: 20}}/>
        },
        {
            uri: "/categories/34057ad4-b16d-424c-b9f3-c2e1e5e60be3",
            label: 'sidebar.menuItem.headOverHeels',
            type: "nav-item",
            icon: <ElectricBoltIcon sx={{fontSize: 20}}/>
        },
        {
            uri: "/categories/4bbd0b72-48e5-4b3b-a040-d2cc113add8e",
            label: 'sidebar.menuItem.holisticHeels',
            type: "nav-item",
            icon: <AutoAwesomeIcon sx={{fontSize: 20}}/>
        },
        {
            uri: "/categories/e6be7d9e-f864-409e-849e-36b62b23b7f6",
            label: 'sidebar.menuItem.hooked',
            type: "nav-item",
            icon: <FlareIcon sx={{fontSize: 20}}/>
        },
        {
            uri: "/categories/a66eb18e-f535-42ec-afd2-5abadb430eb9",
            label: 'sidebar.menuItem.infinityFlow',
            type: "nav-item",
            icon: <AllInclusiveIcon sx={{fontSize: 20}}/>
        },
        {
            uri: "/categories/e52f8ec6-f942-4a6d-8c32-2c175711529e",
            label: 'sidebar.menuItem.mobilityFlow',
            type: "nav-item",
            icon: <AirIcon sx={{fontSize: 20}}/>
        },

        {
            uri: "/categories/549dcfbf-30f0-46cd-afdf-d75fe1b20f42",
            label: 'sidebar.menuItem.seductiveSilk',
            type: "nav-item",
            icon: <Diversity2Icon sx={{fontSize: 20}}/>
        },
        {
            uri: "/categories/a14ca8d5-3f20-40cb-ab9c-a523fd2baf73",
            label: 'sidebar.menuItem.sensualHeeling',
            type: "nav-item",
            icon: <FavoriteBorderIcon sx={{fontSize: 20}}/>
        },
]
}



export default menus;
