const CATEGORY_MAP = new Map();


CATEGORY_MAP.set('48abf3cf-8bba-4f8a-87db-1fa2ae6bdfee', 'Bodyful Breathing');
CATEGORY_MAP.set('643745a2-254e-4d89-8dc2-61e1cdc01345', 'Coffee & Treats');
CATEGORY_MAP.set('c318483b-0653-4136-9515-6ea280b20c86', 'Elevated');
CATEGORY_MAP.set('1685562f-9b20-465a-ac1f-7eba39050556', 'Fluid Foundations');
CATEGORY_MAP.set('34057ad4-b16d-424c-b9f3-c2e1e5e60be3', 'Head Over Heels');
CATEGORY_MAP.set('4bbd0b72-48e5-4b3b-a040-d2cc113add8e', "Ho'listic Heels");
CATEGORY_MAP.set('e6be7d9e-f864-409e-849e-36b62b23b7f6', 'Hooked!');
CATEGORY_MAP.set('a66eb18e-f535-42ec-afd2-5abadb430eb9', 'Infinity');
CATEGORY_MAP.set('e52f8ec6-f942-4a6d-8c32-2c175711529e', 'Mobility Flow');
CATEGORY_MAP.set('549dcfbf-30f0-46cd-afdf-d75fe1b20f42', 'Seductive Silk');
CATEGORY_MAP.set('a14ca8d5-3f20-40cb-ab9c-a523fd2baf73', 'Sensual Heeling');
export default CATEGORY_MAP;