import {deDE, enUS} from "@mui/material/locale";

export const LOCALE_DE = 'de-DE';
export const LOCALE_EN = 'en-US';


export const SUPPORTED_LOCALES = [
    { locale: LOCALE_DE, language: "German",localizedLanguage: "Deutsch", muiLocale: deDE, iso2: "DE" },
    { locale: LOCALE_EN, language: "English",localizedLanguage: "English", muiLocale: enUS, iso2: "EN" },
];


export const getSupportedLocale = (locale) => {
    return SUPPORTED_LOCALES.find((supportedLocale) => supportedLocale.locale === locale) ||
        SUPPORTED_LOCALES[1]; // default to English
}