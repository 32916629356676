import React, {useEffect, useState} from 'react';
import {
    Box,
    Card, CardActionArea,
    CardActions,
    CardContent, CardMedia, Chip, Dialog, DialogActions, DialogContent, DialogTitle,
    Divider,
    Grid, TextField,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {LoadingButton, Skeleton} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import {StorageService} from "../../services/StorageService";
import {useTranslation} from "react-i18next";
import {LOCALE_DE} from "../../utils/locales";
import {BookingsService} from "../../services/BookingsService";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CancelIcon from '@mui/icons-material/Cancel';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import {displayAlertDialog, displayPromptDialog} from "../../utils/alertDialogs";
import {
    STATUS_ACTIVE,
    STATUS_ENDED,
    STATUS_IN_PROGRESS,
    STATUS_STARTING_SOON,
    STATUS_UPCOMING,
    STATUS_CANCELED
} from "../../utils/constants";
import {trackButtonClickEvent, trackModalViewEvent, trackPageViewEvent} from "../../utils/tracking";

const Bookings = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [bookings, setBookings] = useState([]);
    const [selectedBooking, setSelectedBooking] = useState();
    const [isCancelingBooking, setIsCancelingBooking] = useState(false);
    const [isBookingDialogOpen, setIsBookingDialogOpen] =  useState(false);


    const user = StorageService.loadUser();
    const appLocale = StorageService.loadAppLocale();

    useEffect(
        async ()  => {
            trackPageViewEvent('bookings', '/bookings');

            if (user) {
                await getBookings();
            }
        },
        [],
    );

    const onCloseBookingDialogOpen = () => {
        setIsBookingDialogOpen(false);
    }


    const getBookings = async () => {

        if (!user) {
            return;
        }

        setIsLoading(true);
        const response =  await BookingsService.getBookings(user.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setBookings(data.bookings);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }


    const viewBooking = (booking) => {
        trackModalViewEvent(`booking modal`)
        setSelectedBooking(booking);
        setIsBookingDialogOpen(true);
    }

    const goToBookingRecording = () => {
        trackButtonClickEvent('go to class recording')
    }

    const promptToCancelBooking = async () => {
        setIsBookingDialogOpen(false);
        await displayPromptDialog(t,
            'app.cancelThisBookingLabel',
            'app.cancelBookingLabel',
            cancelBooking,
            () => {
                setIsBookingDialogOpen(true);
            })
    }

    const cancelBooking = async () => {

        trackButtonClickEvent('cancel booking');

        setIsCancelingBooking(true);
        const response =  await BookingsService.cancelBooking(selectedBooking?.uid, user.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsCancelingBooking(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setIsBookingDialogOpen(false);

                await displayAlertDialog(t,'app.bookingCanceledLabel', 'success', getBookings)

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }

    }

    const unableToJoinClass = () => {
        trackButtonClickEvent('unable to join class subscription inactive');
        alert(t('app.unableToJoinClassDueToSubscriptionOnHold'));
    }

    const bookAClass = () => {
        navigate(`/classes`);
    }

    const BookingCard = ({ booking, viewBooking, trackButtonClickEvent, unableToJoinClass, goToBookingRecording, t, appLocale }) => (
        <Grid item>
            <Card sx={{ maxWidth: 345 }} onClick={() => viewBooking(booking)}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="250"
                        image={booking?.program?.image || "https://drehmomentpole.de/wp-content/uploads/2023/11/polo.webp"}
                        alt={booking.name}
                    />
                    <CardContent sx={{height: 150}}>
                        <Typography gutterBottom variant="h5" component="div">
                            {appLocale.locale === LOCALE_DE ? booking?.program?.name : booking?.program?.name_en}
                        </Typography>

                        <Box>
                            <Typography variant="body2" color="text.secondary">
                                {dayjs(booking?.programSchedule?.startTime).format('MMMM D')} {dayjs(booking?.programSchedule?.startTime).format('HH:mm')} - {dayjs(booking?.programSchedule?.endTime).format('HH:mm')}
                            </Typography>
                        </Box>
                    </CardContent>
                    <CardActions onClick={e => e.stopPropagation()}>
                        <Button
                            onClick={() => viewBooking(booking)}
                            variant="contained"
                            size="small"
                            color="primary"
                        >
                            {t('app.viewButtonLabel')}
                        </Button>

                        {(booking?.status === STATUS_STARTING_SOON || booking?.status === STATUS_IN_PROGRESS) &&
                            !booking?.isProgramSubscriptionOnHold && (
                                <Button
                                    onClick={() => trackButtonClickEvent('booking class link')}
                                    target="_blank"
                                    href={booking?.programSchedule?.classRoomLink}
                                    sx={{ ml: 1 }}
                                    size="small"
                                    color="info"
                                    variant="contained"
                                    startIcon={<VideoCameraBackIcon />}
                                >
                                    {t('app.joinClassLabel')}
                                </Button>
                            )}

                        {(booking?.status === STATUS_STARTING_SOON || booking?.status === STATUS_IN_PROGRESS) &&
                            booking?.isProgramSubscriptionOnHold && (
                                <Button
                                    onClick={unableToJoinClass}
                                    sx={{ ml: 1 }}
                                    size="small"
                                    color="warning"
                                    variant="contained"
                                    startIcon={<VideoCameraBackIcon />}
                                >
                                    {t('app.joinClassLabel')}
                                </Button>
                            )}

                        {booking?.scheduleRecordingLink && booking?.status === STATUS_ENDED && (
                            <Button
                                sx={{ ml: 1 }}
                                onClick={goToBookingRecording}
                                href={booking?.scheduleRecordingLink}
                                variant="contained"
                                size="small"
                                color="secondary"
                            >
                                {t('app.accessRecordingLabel')}
                            </Button>
                        )}
                    </CardActions>
                </CardActionArea>
            </Card>
        </Grid>
    );


    return (
        <React.Fragment>
            <Typography
                variant="h1"
                mb={.25}
                sx={{ fontWeight: 'bold', textTransform:'uppercase' }}
            >
                {t('app.myBookingsLabel')}

                <Button
                    onClick={bookAClass}
                    size={'small'}
                    variant={"contained"}
                    sx={{
                        marginLeft: 1,
                        marginBottom: 1
                    }}
                >
                    {t('app.bookAClassLabel')}
                </Button>
            </Typography>
            <Typography variant={'h6'} color={'text.secondary'}>

            </Typography>

            <Divider/>

            <Box mt={5}>

                <Box>

                    {!user &&  (
                        <Card>
                            <CardContent>
                                <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Typography variant={'h4'}>
                                        {t('app.noLoginToManageBookings')}
                                    </Typography>
                                </Box>

                                <Box mt={3} sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Button
                                        onClick={() => {
                                            trackButtonClickEvent('login_from_bookings');
                                            navigate('/login')
                                        }}
                                        size={'small'}
                                        variant={"contained"}
                                    >
                                        {t('app.logInButtonLabel')}
                                    </Button>

                                </Box>
                            </CardContent>
                        </Card>
                    )}

                    {
                        isLoading && (
                            <>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </>
                        )
                    }

                    {(!isLoading && user && bookings?.length < 1) && (

                        <Card>
                            <CardContent>
                                <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Typography variant={'h4'}>
                                        {t('app.noClassesBookedLabel')}
                                    </Typography>
                                </Box>

                                <Box mt={3} sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Button
                                        onClick={bookAClass}
                                        size={'small'}
                                        variant={"contained"}
                                    >
                                        {t('app.bookAClassLabel')}
                                    </Button>

                                </Box>
                            </CardContent>
                        </Card>
                    )}

                    {!isLoading && bookings?.length > 0 && (
                        <Box>
                            {/* In Progress */}
                            {bookings.filter(b => b.status === STATUS_IN_PROGRESS).length > 0 && (
                                <Box mb={6}>
                                    <Box mb={3}>
                                        <Typography variant="h5" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Chip label={t('app.inProgressLabel')} size="small" color="success" />
                                        </Typography>
                                    </Box>

                                    <Grid container spacing={3.75}>
                                        {bookings
                                            .filter(booking => booking.status === STATUS_IN_PROGRESS)
                                            .map((booking) => (
                                                <BookingCard
                                                    key={booking.uid}
                                                    booking={booking}
                                                    viewBooking={viewBooking}
                                                    trackButtonClickEvent={trackButtonClickEvent}
                                                    unableToJoinClass={unableToJoinClass}
                                                    goToBookingRecording={goToBookingRecording}
                                                    t={t}
                                                    appLocale={appLocale}
                                                />
                                            ))}
                                    </Grid>
                                </Box>
                            )}

                            {/* Starting Soon */}
                            {bookings.filter(b => b.status === STATUS_STARTING_SOON).length > 0 && (
                                <Box mb={6}>
                                    <Box mb={3}>
                                        <Typography variant="h5" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Chip label={t('app.startingSoonLabel')} size="small" color="warning" />
                                        </Typography>
                                    </Box>

                                    <Grid container spacing={3.75}>
                                        {bookings
                                            .filter(booking => booking.status === STATUS_STARTING_SOON)
                                            .map((booking) => (
                                                <BookingCard
                                                    key={booking.uid}
                                                    booking={booking}
                                                    viewBooking={viewBooking}
                                                    trackButtonClickEvent={trackButtonClickEvent}
                                                    unableToJoinClass={unableToJoinClass}
                                                    goToBookingRecording={goToBookingRecording}
                                                    t={t}
                                                    appLocale={appLocale}
                                                />
                                            ))}
                                    </Grid>
                                </Box>
                            )}

                            {/* Upcoming */}
                            {bookings.filter(b => b.status === STATUS_UPCOMING).length > 0 && (
                                <Box mb={6}>
                                    <Box mb={3}>
                                        <Typography variant="h5" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Chip label={t('app.upComingLabel')} size="small" color="info" />
                                        </Typography>
                                    </Box>

                                    <Grid container spacing={3.75}>
                                        {bookings
                                            .filter(booking => booking.status === STATUS_UPCOMING)
                                            .sort((a, b) => {
                                                return new Date(a?.programSchedule?.startTime) - new Date(b?.programSchedule?.startTime);
                                            })
                                            .map((booking) => (
                                                <BookingCard
                                                    key={booking.uid}
                                                    booking={booking}
                                                    viewBooking={viewBooking}
                                                    trackButtonClickEvent={trackButtonClickEvent}
                                                    unableToJoinClass={unableToJoinClass}
                                                    goToBookingRecording={goToBookingRecording}
                                                    t={t}
                                                    appLocale={appLocale}
                                                />
                                            ))}
                                    </Grid>
                                </Box>
                            )}
                            {/* Canceled */}
                            {bookings.filter(b => b.status === STATUS_CANCELED).length > 0 && (
                                <Box mb={6}>
                                    <Box mb={3}>
                                        <Typography variant="h5" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Chip label={t('app.canceledLabel')} size="small" color="error" />
                                            {/*<Typography variant="h5" component="span">*/}
                                            {/*    ({bookings.filter(b => b.status === STATUS_CANCELED).length})*/}
                                            {/*</Typography>*/}
                                        </Typography>
                                    </Box>

                                    <Grid container spacing={3.75}>
                                        {bookings
                                            .filter(booking => booking.status === STATUS_CANCELED)
                                            .map((booking) => (
                                                <BookingCard
                                                    key={booking.uid}
                                                    booking={booking}
                                                    viewBooking={viewBooking}
                                                    trackButtonClickEvent={trackButtonClickEvent}
                                                    unableToJoinClass={unableToJoinClass}
                                                    goToBookingRecording={goToBookingRecording}
                                                    t={t}
                                                    appLocale={appLocale}
                                                />
                                            ))}
                                    </Grid>
                                </Box>
                            )}

                            {/* Ended */}
                            {bookings.filter(b => b.status === STATUS_ENDED).length > 0 && (
                                <Box mb={6}>
                                    <Box mb={3}>
                                        <Typography variant="h5" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Chip label={t('app.endedLabel')} size="small" color="default" />
                                            {/*<Typography variant="h5" component="span">*/}
                                            {/*    ({bookings.filter(b => b.status === STATUS_ENDED).length})*/}
                                            {/*</Typography>*/}
                                        </Typography>
                                    </Box>

                                    <Grid container spacing={3.75}>
                                        {bookings
                                            .filter(booking => booking.status === STATUS_ENDED)
                                            .map((booking) => (
                                                <BookingCard
                                                    key={booking.uid}
                                                    booking={booking}
                                                    viewBooking={viewBooking}
                                                    trackButtonClickEvent={trackButtonClickEvent}
                                                    unableToJoinClass={unableToJoinClass}
                                                    goToBookingRecording={goToBookingRecording}
                                                    t={t}
                                                    appLocale={appLocale}
                                                />
                                            ))}
                                    </Grid>
                                </Box>
                            )}
                        </Box>
                    )}

                    {/*{!isLoading && bookings?.length > 0 && (*/}
                    {/*    <>*/}
                    {/*    <Grid container spacing={3.75} mb={3}>*/}
                    {/*        {bookings.map( (booking, index) =>*/}
                    {/*            (*/}
                    {/*                <>*/}
                    {/*                    <Grid item key={index}>*/}
                    {/*                        <Card sx={{ maxWidth: 345 }} onClick={() => {viewBooking(booking)}}>*/}
                    {/*                            <CardActionArea>*/}
                    {/*                                <CardMedia*/}
                    {/*                                    component="img"*/}
                    {/*                                    height="250"*/}
                    {/*                                    image={booking?.program?.image ? booking?.program?.image : "https://drehmomentpole.de/wp-content/uploads/2023/11/polo.webp"}*/}
                    {/*                                    alt={booking.name}*/}
                    {/*                                />*/}
                    {/*                                <CardContent  sx={{height: 150}}>*/}
                    {/*                                    <Typography gutterBottom variant="h5" component="div">*/}
                    {/*                                        {appLocale.locale === LOCALE_DE ? booking?.program?.name : booking?.program?.name_en}*/}
                    {/*                                    </Typography>*/}

                    {/*                                    <Box>*/}
                    {/*                                        <Typography variant="body2" color="text.secondary">*/}
                    {/*                                            {dayjs(booking?.programSchedule?.startTime).format('MMMM D')} {dayjs(booking?.programSchedule?.startTime).format('HH:mm')} - {dayjs(booking?.programSchedule?.endTime).format('HH:mm')}*/}
                    {/*                                        </Typography>*/}

                    {/*                                        {booking?.status !== 'Active' && (*/}
                    {/*                                            <Chip sx={{marginTop: 1}} label={t(booking?.statusTranslationKey)} size={'small'} color={booking?.statusDisplayColor} />*/}
                    {/*                                        )}*/}
                    {/*                                    </Box>*/}

                    {/*                                </CardContent>*/}
                    {/*                                <CardActions>*/}
                    {/*                                    <Button onClick={() => {viewBooking(booking)}} variant={'contained'} size="small" color="primary">*/}
                    {/*                                        {t('app.viewButtonLabel')}*/}
                    {/*                                    </Button>*/}

                    {/*                                    {(*/}
                    {/*                                        booking?.status === STATUS_STARTING_SOON ||*/}
                    {/*                                        booking?.status === STATUS_IN_PROGRESS*/}
                    {/*                                    ) && !booking?.isProgramSubscriptionOnHold && (*/}
                    {/*                                        <Button onClick={() => {*/}
                    {/*                                            trackButtonClickEvent('booking class link');*/}

                    {/*                                        }} target={'_blank'} href={booking?.programSchedule?.classRoomLink} sx={{marginLeft: 1}} size={'small'} color={'info'} variant={'contained'} startIcon={<VideoCameraBackIcon />}>*/}
                    {/*                                            {t('app.joinClassLabel')}*/}
                    {/*                                        </Button>*/}
                    {/*                                    )}*/}

                    {/*                                    {(*/}
                    {/*                                        booking?.status === STATUS_STARTING_SOON ||*/}
                    {/*                                        booking?.status === STATUS_IN_PROGRESS*/}
                    {/*                                    ) && booking?.isProgramSubscriptionOnHold &&  (*/}
                    {/*                                        <Button onClick={unableToJoinClass} sx={{marginLeft: 1}} size={'small'} color={'warning'} variant={'contained'} startIcon={<VideoCameraBackIcon />}>*/}
                    {/*                                            {t('app.joinClassLabel')}*/}
                    {/*                                        </Button>*/}
                    {/*                                    )}*/}

                    {/*                                    {booking?.scheduleRecordingLink && booking?.status === STATUS_ENDED && (*/}
                    {/*                                        <Button sx={{marginLeft: 1}} onClick={goToBookingRecording} href={booking?.scheduleRecordingLink} variant={'contained'} size="small" color="secondary">*/}
                    {/*                                            {t('app.accessRecordingLabel')}*/}
                    {/*                                        </Button>*/}
                    {/*                                    )}*/}
                    {/*                                </CardActions>*/}
                    {/*                            </CardActionArea>*/}
                    {/*                        </Card>*/}
                    {/*                    </Grid>*/}
                    {/*                </>*/}
                    {/*            )*/}
                    {/*        )}*/}
                    {/*    </Grid>*/}
                    {/*    </>*/}
                    {/*)}*/}
                </Box>
            </Box>

            <Dialog open={isBookingDialogOpen} onClose={onCloseBookingDialogOpen} maxWidth={'xl'} fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">{appLocale.locale === LOCALE_DE ? selectedBooking?.program?.name : selectedBooking?.program?.name_en}</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <img
                                src={selectedBooking?.program?.image}
                                alt={selectedBooking?.program?.name}
                                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5" gutterBottom>
                                {appLocale.locale === LOCALE_DE ? selectedBooking?.program?.name : selectedBooking?.program?.name_en}

                                {selectedBooking?.status !== 'Active' && (
                                    <Chip sx={{marginLeft: 1}} label={t(selectedBooking?.statusTranslationKey)} size={'small'} color={selectedBooking?.statusDisplayColor} />
                                )}
                            </Typography>
                            <Typography variant={'h6'}>
                                <CalendarMonthIcon sx={{fontSize: 16 , paddingRight: '2px', paddingTop: '3px'}} color={'action'}/>
                                {dayjs(selectedBooking?.programSchedule?.startTime).format('dddd, MMMM D')} {dayjs(selectedBooking?.programSchedule?.startTime).format('HH:mm')} - {dayjs(selectedBooking?.programSchedule?.endTime).format('HH:mm')}
                            </Typography>

                            <Box mt={2}>

                                {(
                                    selectedBooking?.status === STATUS_STARTING_SOON ||
                                    selectedBooking?.status === STATUS_IN_PROGRESS
                                ) && !selectedBooking?.isProgramSubscriptionOnHold &&  (
                                    <Button onClick={() => {
                                        trackButtonClickEvent('booking class room link');

                                    }} target={'_blank'} href={selectedBooking?.programSchedule?.classRoomLink} sx={{marginRight: 1, marginBottom: 1}} size={'small'} color={'info'} variant={'contained'} startIcon={<VideoCameraBackIcon />}>
                                        {t('app.joinClassLabel')}
                                    </Button>
                                )}

                                {(
                                    selectedBooking?.status === STATUS_STARTING_SOON ||
                                    selectedBooking?.status === STATUS_IN_PROGRESS
                                ) && selectedBooking?.isProgramSubscriptionOnHold &&  (
                                    <Button onClick={unableToJoinClass} sx={{marginRight: 1, marginBottom: 1}} size={'small'} color={'warning'} variant={'contained'} startIcon={<VideoCameraBackIcon />}>
                                        {t('app.joinClassLabel')}
                                    </Button>
                                )}

                                {selectedBooking?.scheduleRecordingLink && selectedBooking?.status === STATUS_ENDED && (
                                    <Button sx={{marginRight: 1, marginBottom: 1}} onClick={goToBookingRecording} href={selectedBooking?.scheduleRecordingLink} variant={'contained'} size="small" color="secondary">
                                        {t('app.accessRecordingLabel')}
                                    </Button>
                                )}

                                {(
                                    selectedBooking?.isCancellable
                                 ) && (

                                    <LoadingButton loading={isCancelingBooking} onClick={promptToCancelBooking} sx={{marginRight: 1, marginBottom: 1}} size={'small'} color={'error'} variant={'contained'} startIcon={<CancelIcon />}>
                                        {t('app.cancelBookingLabel')}
                                    </LoadingButton>
                                )}



                            </Box>

                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseBookingDialogOpen} color="primary">
                        {t('app.closeButtonLabel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default Bookings;