import {APIService} from "./APIService";

export class BookingsService extends APIService {
    static async bookClass(programScheduleUid, accessToken) {

        return await this.makeRequest(
            'api/bookings/create',
            {
                programScheduleUid
            },
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_POST,
        );
    }

    static async cancelBooking(bookingUid, accessToken) {

        return await this.makeRequest(
            'api/bookings/cancel',
            {
                bookingUid
            },
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_POST,
        );
    }

    static async getBookings(accessToken) {

        return await this.makeRequest(
            'api/bookings/user',
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET,
        );
    }
}