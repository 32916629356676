import React, {useCallback, useEffect, useState} from "react";
import {
    Box,
    Card,
    CardActionArea, CardActions, CardContent,
    Chip, Dialog, DialogActions, DialogContent,
    Divider, Drawer, IconButton,
    ListItem,
    ListItemAvatar, ListItemIcon,
    ListItemText, Select, Stack, TextField,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {LoadingButton, Skeleton} from "@mui/lab";
import {StorageService} from "../../services/StorageService";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Vimeo from '@u-wave/react-vimeo';
import {useTranslation} from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";

import {UserLibraryService} from "../../services/UserLibraryService";
import {VideoService} from "../../services/VideoService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {loadStripe} from "@stripe/stripe-js";
import {TransactionsService} from "../../services/TransactionsService";
import {displayAlertDialog} from "../../utils/alertDialogs";
import {EURO_SIGN, IS_MEMBERSHIP_CONTENT, IS_NOT_MEMBERSHIP_CONTENT} from "../../utils/constants";
import {EmbeddedCheckout, EmbeddedCheckoutProvider} from "@stripe/react-stripe-js";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import {
    trackAddToLibraryEvent,
    trackButtonClickEvent,
    trackClassIntroVideoPlayedEvent, trackModalViewEvent,
    trackPageViewEvent,
    trackPreviewVideoPlayedEvent,
    trackPurchaseFailedEvent,
    trackPurchaseInitiatedEvent,
    trackPurchaseSuccessfulEvent,
    trackRequestFailedEvent,
    trackVideoEndedEvent,
    trackVideoErrorEvent,
    trackVideoPlayedEvent
} from "../../utils/tracking";
import Link from "@mui/material/Link";
import {LOCALE_DE} from "../../utils/locales";
import LanguageIcon from "@mui/icons-material/Language";
import {ClosedCaption} from "@mui/icons-material";
import EuroIcon from "@mui/icons-material/Euro";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Video = () => {

    const {t} = useTranslation();

    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();


    const [video, setVideo] = useState(null);
    const [videoCategory, setVideoCategory] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isAddingVideoToLibrary, setIsAddingVideoToLibrary] = useState(false);
    const [isBuyVideoDialogOpen, setIsBuyVideoDialogOpen] = useState(false)

    const [isVideoInUserLibrary, setIsVideoInUserLibrary] = useState(false);
    const [isLibraryItemOnHold, setIsLibraryItemOnHold] = useState(null);
    const [hasMembership, setHasMembership] = useState(false);

    const [isAddToLibraryDialogOpen, setIsAddToLibraryDialogOpen] = useState(false);

    const [accessCode, setAccessCode] = useState('');

    const [isPurchasing, setIsPurchasing] = useState();
    const [stripeCheckoutOptions, setStripeCheckoutOptions] = useState({clientSecret: ""});
    const [isStripeCheckoutDialogOpen, setIsStripeCheckoutDialogOpen] = useState(false)

    const user = StorageService.loadUser();
    const appLocale = StorageService.loadAppLocale();


    useEffect( async () => {
        trackPageViewEvent('video', `/videos/${id}`);

        await getVideo();

        const stripeCheckoutSessionId = searchParams.get("session_id");
        if (stripeCheckoutSessionId) {
            await fetchPurchaseVideoTransactionStatus(stripeCheckoutSessionId);
        }
    }, []);

    const onCloseBuyVideoDialog = () => {
        trackButtonClickEvent(`cancel buy video`)
        setIsBuyVideoDialogOpen(false);
    }

    const goToLogin = () => {
        const currentPath = encodeURIComponent(window.location.pathname + window.location.search);
        navigate(`/login?returnUrl=${currentPath}`);
    }

    const openBuyVideoDialog = () => {
        trackModalViewEvent(`Buy video modal`)
        setIsBuyVideoDialogOpen(true);
    }

    const getVideo = async () => {
        setIsLoading(true);

        const response =  await VideoService.getVideo(id, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setVideo(data.video);
                setVideoCategory(data.category);
                setIsVideoInUserLibrary(data.isInUserLibrary);
                setIsLibraryItemOnHold(data.isLibraryItemOnHold)
                setHasMembership(data.hasMembership);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };

    const handleFieldChange = useCallback(
        (callback, e) => {
            const value = e.target.value;
            callback(value);

        },
        [setAccessCode],
    );

    const addToLibrary = async () => {
        trackButtonClickEvent('add video to library with access code');

        if (!accessCode) {
            alert(t("app.enterAccessCodeLabel"));
            return;
        }
        setIsAddToLibraryDialogOpen(false);
        setIsAddingVideoToLibrary(true);
        const response =  await UserLibraryService.addToLibrary(
            accessCode,
            video.uid,
            'video',
            user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsAddingVideoToLibrary(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                trackAddToLibraryEvent(video?.name, 'video', false)
                await displayAlertDialog(t, 'app.videoAddedToLibraryLabel', 'success');

                setIsVideoInUserLibrary(true);

            } else {
                trackRequestFailedEvent('add_video_to_library_using_access_code')
                alert(data.message);
            }
        } else {
            trackRequestFailedEvent('add_video_to_library_using_access_code')
            alert(message);
        }
    }

    const onCloseStripeCheckoutDialog = () => {
        trackButtonClickEvent(`cancel video stripe checkout: ${video?.name}`)
        setIsStripeCheckoutDialogOpen(false);
        window.location.reload();
    }

    const addToLibraryForFree = async () => {
        trackButtonClickEvent('add free video to library');

        setIsAddingVideoToLibrary(true);
        const response =  await UserLibraryService.addFreeRecordingToLibrary(
            video.uid,
            'video',
            user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsAddingVideoToLibrary(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                trackAddToLibraryEvent(video?.name, 'video', true)
                await displayAlertDialog(t, 'app.videoAddedToLibraryLabel', 'success');

                setIsVideoInUserLibrary(true);

            } else {
                trackRequestFailedEvent('add_free_video_to_library')
                alert(data.message);
            }
        } else {
            trackRequestFailedEvent('add_free_video_to_library')
            alert(message);
        }
    }

    const purchaseVideo = async () => {
        trackButtonClickEvent('purchase video');
        setIsPurchasing(true);

        const response = await TransactionsService.purchaseRecording(video.uid,
            'video', user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsPurchasing(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                trackPurchaseInitiatedEvent(video?.name, 'video')

                setIsStripeCheckoutDialogOpen(true);
                setStripeCheckoutOptions({
                    clientSecret: data.stripeClientSecret
                });

            } else {
                trackRequestFailedEvent('purchase_video')
                alert(data.message);
            }
        } else {
            trackRequestFailedEvent('purchase_video')
            alert(message);
        }
    };

    const fetchPurchaseVideoTransactionStatus = async (stripeCheckoutSessionId) => {
        setIsLoading(true);

        const response = await TransactionsService.fetchTransactionStatus(stripeCheckoutSessionId, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                if (data.status === 'complete') {
                    trackPurchaseSuccessfulEvent(video?.name, 'video')
                    await displayAlertDialog(t, 'app.videoPurchaseSuccessMessageLabel', 'success');
                }
                else {
                    trackPurchaseFailedEvent(video?.name, 'video')
                    await displayAlertDialog(t, 'app.paymentFailedMessageLabel', 'error');
                }
                await getVideo();

            } else {
                trackRequestFailedEvent('fetch_purchase_video_transaction_status')
                alert(data.message);
            }
        } else {
            trackRequestFailedEvent('fetch_purchase_video_transaction_status')
            alert(message);
        }
    };


    return (

        <React.Fragment>

            <Box>

                {isLoading && (
                    <>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>

                    </>
                )}

                {!isLoading && video && (


                    <>


                        <Grid container mb={3} >
                            <Grid item  sm={12} lg={8}>
                                <Button
                                    onClick={() => navigate(-1)}
                                    size={"small"}
                                    startIcon={<ArrowBackIcon />}
                                    sx={{marginLeft: -2}}
                                >
                                    {t("app.backButtonLabel")}
                                </Button>
                                <ListItem sx={{marginLeft: -3}}>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h1"
                                                        mb={.25}
                                                        sx={{ fontWeight: 'bold', textTransform:'uppercase' }}
                                            >
                                                {appLocale.locale === LOCALE_DE ?
                                                    video.name :
                                                    video?.name_en ?
                                                        video?.name_en : video?.name
                                                }
                                                &nbsp;

                                                {video.isFree === 1 && (
                                                    <Chip sx={{marginLeft: 0}} label={t('app.freeLabel')} color={'secondary'} size={'medium'}/>
                                                )}

                                                {video.isFree === 0 && video?.isMembershipContent === IS_MEMBERSHIP_CONTENT && (
                                                    <Chip sx={{marginLeft: 0}} label={t('app.freeForMembersLabel')} color={'secondary'} size={'medium'}/>
                                                )}
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography component={"div"}>
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        color: 'grey.600',
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    {videoCategory?.name}
                                                </Typography>
                                                <Stack
                                                    direction="row"
                                                    spacing={3}
                                                    mb={1}
                                                    sx={{
                                                        color: 'grey.800',
                                                        gap: 1
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1,
                                                            textTransform: 'uppercase'
                                                        }}
                                                    >
                                                        <LanguageIcon sx={{ fontSize: 20 }} />
                                                        {video?.language}
                                                    </Typography>

                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1,
                                                            textTransform: 'uppercase'
                                                        }}
                                                    >
                                                        <ClosedCaption sx={{ fontSize: 20 }} />
                                                        {t('app.englishLabel')}, {t('app.germanLabel')}
                                                    </Typography>

                                                    {video?.price && video?.isFree === 0 && !isVideoInUserLibrary && user && (
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: 1,
                                                                textTransform: 'uppercase'
                                                            }}
                                                        >
                                                            <EuroIcon sx={{ fontSize: 20 }} />
                                                            {video?.price}
                                                        </Typography>
                                                    )}
                                                </Stack>

                                                {/*<Typography variant='subtitle2'>*/}
                                                {/*    {t('app.languageLabel')}: {video.language}*/}
                                                {/*</Typography>*/}
                                                <Typography
                                                    variant='caption'
                                                    sx={{
                                                        color: 'grey.600',
                                                        maxWidth: '600px',
                                                        lineHeight: 1.6
                                                    }}
                                                >

                                                    {appLocale.locale === LOCALE_DE ?
                                                        video.description :
                                                        video?.description_en ?
                                                            video?.description_en : video?.description
                                                    }
                                                </Typography>

                                                <Stack
                                                    direction={{ xs: 'column', sm: 'row' }}
                                                    spacing={2}
                                                    sx={{ mt: 4 }}
                                                >

                                                    {!user && (
                                                        <Box mt={1}>
                                                            <Button sx={{marginRight: 1}} size="large" color="primary" variant={'contained'}
                                                                    onClick={() => {
                                                                        trackButtonClickEvent('Login to access video');
                                                                        goToLogin();
                                                                    }}>
                                                                {t('app.loginToAccessVideoLabel')}
                                                            </Button>
                                                        </Box>
                                                    )}

                                                    {/*{user && !isVideoInUserLibrary && video?.isFree === 1 && (*/}
                                                    {/*    // add to library for free*/}
                                                    {/*    <Box mt={1}>*/}
                                                    {/*        <LoadingButton loading={isAddingVideoToLibrary} sx={{marginRight: 1}} size="large" color="info" variant={'contained'}*/}
                                                    {/*                       onClick={addToLibraryForFree}>*/}
                                                    {/*            {t('app.addVideoToLibraryButtonLabel')} - {t('app.freeLabel')}*/}
                                                    {/*        </LoadingButton>*/}
                                                    {/*    </Box>*/}
                                                    {/*)}*/}

                                                    {user && !isVideoInUserLibrary && video?.isMembershipContent !== IS_MEMBERSHIP_CONTENT && video?.price && video?.isFree === 0 && (
                                                        // purchase
                                                        <Box mt={1}>
                                                            <LoadingButton loading={isPurchasing} sx={{marginRight: 1}} size="large" color={'success'} variant={'contained'}
                                                                           onClick={openBuyVideoDialog}>
                                                                {t('app.addVideoToLibraryButtonLabel')} - {`${EURO_SIGN}${video.price}`}
                                                            </LoadingButton>
                                                        </Box>
                                                    )}

                                                    {user && !isVideoInUserLibrary && video?.isMembershipContent === IS_MEMBERSHIP_CONTENT && !hasMembership && video?.price && video?.isFree === 0 && (
                                                        // purchase & free access with membership
                                                        <Box mt={1}>
                                                            <LoadingButton loading={isPurchasing} sx={{marginRight: 1, marginTop: 1}} size="large" color={'success'} variant={'contained'}
                                                                           onClick={openBuyVideoDialog}>
                                                                {t('app.addVideoToLibraryButtonLabel')} - {`${EURO_SIGN}${video.price}`}
                                                            </LoadingButton>

                                                            <Button  sx={{marginRight: 1, marginTop: 1}} size="large" color={'secondary'} variant={'contained'}
                                                                          onClick={() => {
                                                                               trackButtonClickEvent('watch video for free with a membership');
                                                                               navigate('/memberships')
                                                                           }}>
                                                                {t('app.accessForFreeWithMembershipLabel')}
                                                            </Button>
                                                        </Box>
                                                    )}

                                                    {user && !isVideoInUserLibrary && !video?.price && video?.isFree === 0 && (
                                                        // legacy use access code
                                                        <Box mt={1}>
                                                            <LoadingButton loading={isAddingVideoToLibrary} sx={{marginRight: 1}} size="large" color="primary" variant={'contained'}
                                                                           onClick={() => {setIsAddToLibraryDialogOpen(true)}}>
                                                                {t('app.addVideoToLibraryButtonLabel')}
                                                            </LoadingButton>
                                                        </Box>
                                                    )}

                                                    {user && isVideoInUserLibrary && isLibraryItemOnHold && (
                                                        <Box mt={1}>
                                                            <Chip label={t('app.noAccessToItemLabel').toUpperCase()} sx={{marginRight: 1}} size="large" color="warning"/>
                                                        </Box>
                                                    )}
                                                </Stack>

                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </Grid>

                            <Grid item  xs={12} sm={12} md={12} lg={4}>

                                {video.previewVideoLink && (
                                    <Box>
                                        <ListItem sx={{marginLeft: -2}}>
                                            <ListItemIcon>
                                                <OndemandVideoIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                sx={{marginLeft: -3}}
                                                primary={
                                                    <Typography variant={'body1'}
                                                                sx={{fontWeight: 'bold'}}>
                                                        {t('app.videoPreviewLabel')}
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <Card sx={{ maxWidth: '100%' }}>
                                            <CardActionArea>
                                                <Vimeo
                                                    video={video.previewVideoLink}
                                                    autopause
                                                    showByline={false}
                                                    showTitle={false}
                                                    autopip
                                                    pip
                                                    responsive={true}
                                                    controls={true}
                                                    speed={true}
                                                    onPlay={() => {
                                                        trackPreviewVideoPlayedEvent(video?.name, 'video')
                                                    }}
                                                    onError={() => {
                                                        trackVideoErrorEvent(video?.name, null)
                                                    }}
                                                />
                                            </CardActionArea>
                                        </Card>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}


            </Box>

            <Divider/>

            <Box mt={5}>

                <Box mt={3}>

                    {!isLoading && !video && (

                        <Card>
                            <CardContent>
                                <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Typography variant={'h4'}>
                                        {t('app.videoDoesntExistLabel')}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    )}

                    {!isLoading && video && (
                        <Grid container spacing={3.75} mb={3}>
                            <Grid item xs={12} sm={12} lg={8}>
                                <Card >
                                    <CardActionArea>
                                        {(isVideoInUserLibrary && !isLibraryItemOnHold) && (
                                            // video is in user library
                                            // show controls
                                            <Vimeo
                                                video={video.link}
                                                autopause
                                                showByline={false}
                                                showTitle={false}
                                                autopip
                                                pip
                                                responsive={true}
                                                controls={true}
                                                speed={true}
                                                onPlay={() => {
                                                    trackVideoPlayedEvent(video?.name, null, video?.isFree === 1)
                                                }}
                                                onEnd={() => {
                                                    trackVideoEndedEvent(video?.name, null)
                                                }}
                                                onError={() => {
                                                    trackVideoErrorEvent(video?.name, null)
                                                }}
                                            />
                                        )}

                                        {(isVideoInUserLibrary && isLibraryItemOnHold) && (
                                            // video is in user library but is on hold
                                            // dont show controls
                                            <Vimeo
                                                video={video.link}
                                                autopause
                                                showByline={false}
                                                showTitle={false}
                                                autopip
                                                pip
                                                responsive={true}
                                                controls={false}
                                                speed={false}
                                                onPlay={() => {
                                                    trackVideoPlayedEvent(video?.name, null, video?.isFree === 1)
                                                }}
                                                onEnd={() => {
                                                    trackVideoEndedEvent(video?.name, null)
                                                }}
                                                onError={() => {
                                                    trackVideoErrorEvent(video?.name, null)
                                                }}
                                            />
                                        )}

                                        {!isVideoInUserLibrary && video?.isMembershipContent === IS_MEMBERSHIP_CONTENT && hasMembership && (
                                            // video is not in user library but its a membership content and the user has a membership
                                            // show controls
                                            <Vimeo
                                                video={video.link}
                                                autopause
                                                showByline={false}
                                                showTitle={false}
                                                autopip
                                                pip
                                                responsive={true}
                                                controls={true}
                                                speed={true}
                                                onPlay={() => {
                                                    trackVideoPlayedEvent(video?.name, null, video?.isFree === 1)
                                                }}
                                                onEnd={() => {
                                                    trackVideoEndedEvent(video?.name, null)
                                                }}
                                                onError={() => {
                                                    trackVideoErrorEvent(video?.name, null)
                                                }}
                                            />
                                        )}

                                        {!isVideoInUserLibrary && video?.isMembershipContent === IS_MEMBERSHIP_CONTENT && !hasMembership && (
                                            // video is not in user library but its a membership content and the user has no membership
                                            // show controls if is free and user is logged in
                                            // else dont
                                            <Vimeo
                                                video={video.link}
                                                autopause
                                                showByline={false}
                                                showTitle={false}
                                                autopip
                                                pip
                                                responsive={true}
                                                controls={(user !== null && video.isFree === 1)}
                                                speed={(user !== null && video.isFree === 1)}
                                                onPlay={() => {
                                                    trackVideoPlayedEvent(video?.name, null, video?.isFree === 1)
                                                }}
                                                onEnd={() => {
                                                    trackVideoEndedEvent(video?.name, null)
                                                }}
                                                onError={() => {
                                                    trackVideoErrorEvent(video?.name, null)
                                                }}
                                            />
                                        )}



                                        {!isVideoInUserLibrary && video?.isMembershipContent === IS_NOT_MEMBERSHIP_CONTENT &&  (
                                            // video is not in user library and is not a membership content
                                            // show controls if is free and user is logged in
                                            // else dont
                                            <Vimeo
                                                video={video.link}
                                                autopause
                                                showByline={false}
                                                showTitle={false}
                                                autopip
                                                pip
                                                responsive={true}
                                                controls={(user !== null && video.isFree === 1)}
                                                speed={(user !== null && video.isFree === 1)}
                                                onPlay={() => {
                                                    trackVideoPlayedEvent(video?.name, null, video?.isFree === 1)
                                                }}
                                                onEnd={() => {
                                                    trackVideoEndedEvent(video?.name, null)
                                                }}
                                                onError={() => {
                                                    trackVideoErrorEvent(video?.name, null)
                                                }}
                                            />
                                        )}
                                    </CardActionArea>
                                </Card>
                            </Grid>

                        </Grid>
                    )}
                </Box>
            </Box>

            <Dialog
                open={isAddToLibraryDialogOpen}
                onClose={() => setIsAddToLibraryDialogOpen(false)}
            >
                <DialogTitle>
                    {t('app.enterAccessCodeLabel')}
                </DialogTitle>
                <DialogContent>
                   <Box mb={2} width={500}>
                    <TextField
                        label={t('app.accessCodeLabel')}
                        placeholder={t('app.enterAccessCodeLabel')}
                        fullWidth
                        value={accessCode}
                        onChange={(e) =>
                        {handleFieldChange(setAccessCode, e)}}
                        sx={{ marginBottom: 1, marginTop: 2 }}
                    />
                </Box>
                </DialogContent>
                <DialogActions>
                    <Button color={'error'} variant={'contained'} onClick={() => setIsAddToLibraryDialogOpen(false)}>
                        {t('app.closeButtonLabel')}
                    </Button>
                    <Button color={'primary'} variant={'contained'} onClick={addToLibrary}>
                        {t('app.addVideoToLibraryButtonLabel')}
                    </Button>

                </DialogActions>
            </Dialog>


            <Dialog open={isBuyVideoDialogOpen} onClose={onCloseBuyVideoDialog} maxWidth={'xl'} fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">{t('app.purchaseVideoLabel')}</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            {video && (
                                <Vimeo
                                    video={video.link}
                                    autopause
                                    showByline={false}
                                    showTitle={false}
                                    autopip
                                    pip
                                    responsive={true}
                                    controls={false}
                                    speed={false}
                                />
                            )}

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h1" gutterBottom>
                                {appLocale.locale === LOCALE_DE ?
                                    video?.name :
                                    video?.name_en ?
                                        video?.name_en : video?.name
                                }
                            </Typography>
                            <Typography variant="h3" color="primary">
                                €{video?.price}
                            </Typography>
                            <Typography variant="body1" paragraph>
                                {appLocale.locale === LOCALE_DE ?
                                    video?.description :
                                    video?.description_en ?
                                        video?.description_en : video?.description
                                }
                            </Typography>



                            <Box mt={25}>
                                <Typography variant={"body1"} fontSize={12}>
                                    {t('app.byPurchasingThisItemLabel')}
                                    <Link
                                        ml={1}
                                        href={"https://drehmomentpole.de/agbs/"}
                                        color={"inherit"}
                                        target={'_blank'}
                                    >{t('app.termsAndConditionsLabel')}</Link>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseBuyVideoDialog} color="primary">
                        {t('app.cancelButtonLabel')}
                    </Button>

                    {user && (
                        <LoadingButton loading={isPurchasing} onClick={purchaseVideo} variant="contained" color="primary">
                            {t('app.payLabel')} €{video?.price}
                        </LoadingButton>
                    )}

                    {!user && (
                        <Button onClick={() => {
                            trackButtonClickEvent(`login to buy video`)
                            goToLogin()
                        }} variant="contained" color="primary">
                            {t('app.loginToBuyLabel')}
                        </Button>
                    )}

                </DialogActions>
            </Dialog>


            <Dialog fullScreen={true} open={isStripeCheckoutDialogOpen} onClose={onCloseStripeCheckoutDialog} maxWidth={'xl'} fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h1">{t('app.purchaseVideoLabel')}</Typography>
                        <Button onClick={onCloseStripeCheckoutDialog} color="primary">
                            {t('app.cancelButtonLabel')}
                        </Button>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {stripeCheckoutOptions.clientSecret !== "" && (
                        <Box>
                            <EmbeddedCheckoutProvider
                                stripe={stripePromise}
                                options={stripeCheckoutOptions}
                            >
                                <EmbeddedCheckout />
                            </EmbeddedCheckoutProvider>
                        </Box>

                    )}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default Video;