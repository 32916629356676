import React, {useCallback, useEffect, useState} from "react";
import {
    Box,
    Card,
    CardActionArea, CardActions, CardContent, CardMedia,
    Chip, Dialog, DialogActions, DialogContent, DialogTitle,
    Divider, Drawer, IconButton,
    ListItem,
    ListItemAvatar, ListItemIcon,
    ListItemText, Select, Tabs, TextField,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {LoadingButton, Skeleton} from "@mui/lab";
import {StorageService} from "../../services/StorageService";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {ProgramService} from "../../services/ProgramService";
import {getSupportedLocale, LOCALE_DE} from "../../utils/locales";
import CircleIcon from '@mui/icons-material/Circle';
import dayjs from "dayjs";
import Tab from "@mui/material/Tab";
import { CreditCard, CalendarMonth} from "@mui/icons-material";
import {loadStripe} from "@stripe/stripe-js";
import {TransactionsService} from "../../services/TransactionsService";
import {EmbeddedCheckoutProvider, EmbeddedCheckout} from "@stripe/react-stripe-js";
import {displayAlertDialog} from "../../utils/alertDialogs";
import {UserClassPassService} from "../../services/UserClassPassService";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {BookingsService} from "../../services/BookingsService";
import Vimeo from "@u-wave/react-vimeo";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import {
    trackBookingExistsEvent,
    trackBookingInsufficientClassPassesEvent, trackBookingSuccessfulEvent,
    trackButtonClickEvent, trackClassIntroVideoPlayedEvent,
    trackModalViewEvent,
    trackPageViewEvent, trackPurchaseInitiatedEvent, trackPurchaseSuccessfulEvent,
    trackRequestFailedEvent, trackVideoErrorEvent
} from "../../utils/tracking";
import Link from "@mui/material/Link";
import {ScheduleService} from "../../services/ScheduleService";



const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const localizer = momentLocalizer(moment);

const Schedules = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();


    const [isLoading, setIsLoading] = useState(false);
    const [schedules, setSchedules] = useState([]);
    const [programs, setPrograms] = useState([]);

    const [calendarSchedules, setCalendarSchedules] = useState([]);
    const [selectedSchedule, setSelectedSchedule] = useState();
    const [isScheduleDialogOpen, setIsScheduleDialogOpen] = useState(false);

    const isNotMobile = useMediaQuery('(min-width:1200px)');
    const scrollCalendarTo12PM = new Date(new Date().setUTCHours(12));

    const user = StorageService.loadUser();
    const appLocale = StorageService.loadAppLocale();

    useEffect(
        async ()  => {
            trackPageViewEvent('schedules', `/schedules`);

            await getPrograms();
        },
        [],
    );

    useEffect(
        async ()  => {
            await getSchedules();
        },
        [programs],
    );

    const goToLogin = () => {
        navigate('/login');
    }

    const getPrograms = async () => {

        setIsLoading(true);
        const response =  await ProgramService.getPublishedPrograms(user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setPrograms(data.programs);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const getSchedules = async () => {
        setIsLoading(true);
        const response =  await ScheduleService.getSchedules(user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                const calSchedules = [];
                for (const schedule of data.schedules) {
                    const scheduleProgram = programs.find(program => program.uid === schedule.programUid)
                    calSchedules.push({
                        ...schedule,
                        start: new Date(schedule.startTime),
                        end: new Date(schedule.endTime),
                        title: scheduleProgram?.name,
                        program: scheduleProgram
                    });
                }
                setSchedules(data.schedules);
                setCalendarSchedules(calSchedules);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const displayScheduleDialog = (shouldDisplay) => {
        if(shouldDisplay) {
            trackModalViewEvent(`Book class modal: ${selectedSchedule?.program.name} from schedules`)
        }

        setIsScheduleDialogOpen(shouldDisplay);
    }

    const handleSelectSchedule = (schedule) => {
        setSelectedSchedule(schedule);
        displayScheduleDialog(true)
    };

    const viewClass = (selectedSchedule) => {
        navigate(`/classes/${selectedSchedule.program.uid}`, { state: selectedSchedule });
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        return {
            style: {
                backgroundColor: theme.palette.secondary.main,
                borderRadius: '4px',
                opacity: 0.8,
                color: theme.palette.secondary.contrastText,
                border: 'none',
                display: 'block'
            }
        };
    };

    return (

        <React.Fragment>

            <Typography
                variant="h1"
                mb={.25}
                sx={{ fontWeight: 'bold', textTransform:'uppercase' }}
            >
                {t('app.schedulesLabel')}
            </Typography>

            <Divider/>
            <Box mt={5}>
                <Box>

                {isLoading && (
                    <>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>

                    </>
                )}

                {!isLoading && calendarSchedules?.length > 0 && (
                    <Box>
                        <Card>
                            <CardContent>
                                <Calendar
                                    localizer={localizer}
                                    events={calendarSchedules}
                                    selectable
                                    defaultView={isNotMobile ? 'week' : 'day'}
                                    culture={appLocale.locale === LOCALE_DE ? 'de' : 'en'}
                                    scrollToTime={scrollCalendarTo12PM}
                                    onSelectEvent={event => handleSelectSchedule(event)}
                                    style={{height: 600}}
                                    startAccessor="start"
                                    endAccessor="end"
                                    eventPropGetter={eventStyleGetter}
                                    defaultDate={
                                        // navigate to the day of the first schedule in the schedules, if the schedule is empty navigate to the current day
                                        calendarSchedules?.length > 0 ?
                                            calendarSchedules[calendarSchedules?.length - 1]?.start :
                                            new Date()
                                    }
                                    // style={{
                                    //     height: '100vh',
                                    //     fontSize: '14px',
                                    //     fontFamily: 'Arial, sans-serif',
                                    // }}
                                    // components={{
                                    //     event: Event,
                                    //     eventWrapper: EventWrapper,
                                    // }}
                                    // eventPropGetter={() => ({
                                    //     style: {
                                    //         backgroundColor: 'pink',
                                    //         color: 'black',
                                    //     },
                                    // })}
                                />
                            </CardContent>
                        </Card>
                    </Box>
                )}

                {
                    !isLoading &&
                    // This would just display an empty calendar if there are no events
                    calendarSchedules?.length < 1 &&
                    (
                    <Box>
                        <Card>
                            <CardContent>
                                <Calendar
                                    localizer={localizer}
                                    events={calendarSchedules}
                                    selectable
                                    defaultView={isNotMobile ? 'week' : 'day'}
                                    culture={appLocale.locale === LOCALE_DE ? 'de' : 'en'}
                                    scrollToTime={scrollCalendarTo12PM}
                                    onSelectEvent={event => handleSelectSchedule(event)}
                                    style={{height: 600}}
                                    startAccessor="start"
                                    endAccessor="end"
                                    defaultDate={
                                        // navigate to the day of the first schedule in the schedules, if the schedule is empty navigate to the current day
                                        calendarSchedules?.length > 0 ?
                                            calendarSchedules[calendarSchedules?.length - 1]?.start :
                                            new Date()
                                    }
                                    // style={{
                                    //     height: '100vh',
                                    //     fontSize: '14px',
                                    //     fontFamily: 'Arial, sans-serif',
                                    // }}
                                    // components={{
                                    //     event: Event,
                                    //     eventWrapper: EventWrapper,
                                    // }}
                                    // eventPropGetter={() => ({
                                    //     style: {
                                    //         backgroundColor: 'pink',
                                    //         color: 'black',
                                    //     },
                                    // })}
                                />
                            </CardContent>
                        </Card>
                    </Box>
                )}


                </Box>
            </Box>

            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 800, borderRadius: 0 }}}
                maxWidth="xs"
                open={isScheduleDialogOpen}
            >
                <DialogContent>
                    <ListItemAvatar sx={{mb: 2, width: 400, overflow: "hidden"}}>
                        <img
                            width={"400"}
                            height={"250"}
                            alt={""}
                            style={{verticalAlign: 'middle'}}
                            src={selectedSchedule?.program.image}
                        />
                    </ListItemAvatar>
                    <Typography variant={'h1'}>
                        {/*<CircleIcon  sx={{fontSize: 16, paddingRight: '2px', paddingTop: '3px'}} color={'info'} />*/}
                        {selectedSchedule?.title}
                    </Typography>
                    <Typography variant={'h5'}>
                        <CalendarMonthIcon sx={{fontSize: 16 , paddingRight: '2px', paddingTop: '3px'}} color={'action'}/>
                        {dayjs(selectedSchedule?.start).format('dddd, MMMM D')} {dayjs(selectedSchedule?.start).format('HH:mm')} - {dayjs(selectedSchedule?.end).format('HH:mm')}
                    </Typography>

                    <Typography variant={'body1'}>

                        {/*{appLocale.locale === LOCALE_DE ? selectedSchedule?.program?.description : selectedSchedule?.program?.description_en}*/}

                        {appLocale.locale === LOCALE_DE ? (
                            selectedSchedule?.program?.description.length > 200
                                ? `${selectedSchedule?.program?.description.substring(0, 197)}...`
                                : selectedSchedule?.program?.description
                        ) : (
                            selectedSchedule?.program?.description_en
                                ? (selectedSchedule?.program?.description_en?.length > 200
                                    ? `${selectedSchedule?.program?.description_en.substring(0, 197)}...`
                                    : selectedSchedule?.program?.description_en)
                                : (selectedSchedule?.program?.description?.length > 200
                                    ? `${selectedSchedule?.program?.description.substring(0, 197)}...`
                                    : selectedSchedule?.program?.description)
                        )}

                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        trackButtonClickEvent(`cancel book class: ${selectedSchedule?.program.name}`)
                        displayScheduleDialog(false)
                        setSelectedSchedule(null);

                    }}>
                        {t('app.cancelLabel')}
                    </Button>

                    <Button onClick={() => {
                        viewClass(selectedSchedule)
                    }} variant="contained" color="primary">
                        {t('app.viewClassLabel')}
                    </Button>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default Schedules;