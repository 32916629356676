import Swal from "sweetalert2";


export const displayAlertDialog = async (t, titleLabel, alertIcon = 'info', dismissActionFunction) => {
    Swal.fire({
        text: t(titleLabel),
        icon: alertIcon,
        showCancelButton: false,
        cancelButtonText: t('app.dismissLabel'),
        reverseButtons: false,
    }).then(async (result) => {
        if (result.value) {

            if (result.value) {
                if (dismissActionFunction) {
                    dismissActionFunction();
                }
            }

        } else if (
            result.dismiss === Swal.DismissReason.cancel
        ) {
            // Do Nothing
        }
    });
};

export const displayPromptDialog = async (t, titleLabel, confirmButtonLabel,
                                          confirmActionFunction,
                                          dismissActionFunction,
                                          alertIcon = 'question') => {
    Swal.fire({
        text: t(titleLabel),
        icon: alertIcon,
        showCancelButton: true,
        confirmButtonText: t(confirmButtonLabel),
        cancelButtonText: t('app.dismissLabel'),
        reverseButtons: true,
        confirmButtonColor: 'success',
    }).then(async (result) => {
        if (result.value) {

            await confirmActionFunction();
        } else if (
            result.dismiss === Swal.DismissReason.cancel
        ) {
            if (dismissActionFunction) {
                dismissActionFunction();
            }
        }
    });
};