import React, {useEffect, useState} from 'react';
import {
    Box,
    Card, CardActionArea,
    CardContent, CardMedia, Chip,
    Divider,
    Grid,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {Skeleton} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import {StorageService} from "../../services/StorageService";
import {CourseService} from "../../services/CourseService";
import {useTranslation} from "react-i18next";
import Vimeo from "@u-wave/react-vimeo";
import {VideoService} from "../../services/VideoService";
import {trackButtonClickEvent, trackPageViewEvent} from "../../utils/tracking";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {LOCALE_DE} from "../../utils/locales";


const AllVideos = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [videos, setVideos] = useState([]);

    const user = StorageService.loadUser();
    const appLocale = StorageService.loadAppLocale();


    useEffect(
        async ()  => {
            trackPageViewEvent('videos', '/videos');

            await getVideos();
        },
        [],
    );

    const getVideos = async () => {

        setIsLoading(true);
        const response =  await VideoService.getOnDemandVideos(user?.token, 1, 200);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setVideos(data.videos);
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const viewVideo = (uid) => {
        navigate(`/videos/${uid}`);

    }

    return (
        <React.Fragment>
            <Typography
                variant="h1"
                mb={.25}
                sx={{ fontWeight: 'bold', textTransform:'uppercase' }}
            >
                <Button
                    onClick={() => navigate(-1)}
                    size={"small"}
                    startIcon={<ArrowBackIcon />}
                    sx={{marginLeft: -2}}
                >
                    {t("app.backButtonLabel")}
                </Button>
                {t('app.allVideosLabel')}
            </Typography>

            <Divider/>

            <Box mt={5}>

                <Box>
                    {
                        isLoading && (
                            <>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </>
                        )
                    }

                    {!isLoading && videos?.length < 1 && (

                        <Card>
                            <CardContent>
                                <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Typography variant={'h4'}>
                                        {t('app.noVideosAvailableLabel')}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    )}

                    {!isLoading && videos?.length > 0 && (
                        <>

                            <Grid container spacing={3.75} mb={3}>

                                {videos.map( (video, index) =>
                                    (
                                        <>
                                            <Grid item key={index} xs={12} sm={6} lg={4}>
                                                <Card onClick={() => {viewVideo(video.uid)}}>
                                                    <CardActionArea>
                                                        <Vimeo
                                                            video={video.link}
                                                            autopause
                                                            showByline={false}
                                                            showTitle={false}
                                                            autopip
                                                            pip
                                                            responsive={true}
                                                            controls={false}
                                                        />
                                                        <CardContent sx={{height: 50, marginBottom: 15}}>
                                                            <Typography gutterBottom variant="h5" component="div">
                                                                {appLocale.locale === LOCALE_DE ?
                                                                    video.name :
                                                                    video?.name_en ?
                                                                        video?.name_en : video?.name
                                                                }

                                                                {video.isFree === 1 && (
                                                                    <Chip sx={{marginLeft: 1}} label={t('app.freeLabel')} color={'secondary'} size={'small'}/>
                                                                )}
                                                            </Typography>

                                                            <Typography variant="body2" color="text.secondary">
                                                                {appLocale.locale === LOCALE_DE ? (
                                                                    video.description.length > 100
                                                                        ? `${video.description.substring(0, 97)}...`
                                                                        : video.description
                                                                ) : (
                                                                    video?.description_en
                                                                        ? (video?.description_en?.length > 100
                                                                            ? `${video?.description_en.substring(0, 97)}...`
                                                                            : video?.description_en)
                                                                        : (video?.description?.length > 100
                                                                            ? `${video?.description.substring(0, 97)}...`
                                                                            : video?.description)
                                                                )}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>

                                                    {/*<CardActions sx={{marginTop: 5}}>*/}
                                                    {/*    <Button sx={{marginLeft: 0}} size={"small"} variant={'contained'} onClick={() => {viewVideo(video.uid)}}>*/}
                                                    {/*        {t('app.viewButtonLabel')}*/}

                                                    {/*    </Button>*/}
                                                    {/*</CardActions>*/}
                                                </Card>
                                            </Grid>
                                        </>
                                    )
                                )}
                            </Grid>
                        </>
                    )}
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default AllVideos;