import {APIService} from './APIService';

export class ScheduleService extends APIService {
    static async getSchedules(accessToken) {
        return await this.makeRequest(
            `api/schedules`,
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }

    static async getUpcomingSchedules(accessToken) {
        return await this.makeRequest(
            `api/schedules/upcoming`,
            null,
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken
            },
            APIService.METHOD_GET
        );
    }

}
