import {APIService} from './APIService';

export class UserInfoService extends APIService {
  static async createUserInfo(
      phoneNumber,
      addressLineOne,
      addressLineTwo,
      city,
      region,
      postalCode,
      country,
      fitForSports,
      whereDidYouHearAboutUs,
      acceptInjuryLiability,
      agreeToEmailCommunication,
      accessToken,
  ) {
    return await this.makeRequest(
      'api/user-info',
      {
          phoneNumber,
          addressLineOne,
          addressLineTwo,
          city,
          region,
          postalCode,
          country,
          fitForSports,
          whereDidYouHearAboutUs,
          acceptInjuryLiability,
          agreeToEmailCommunication,
      },
      {
        'x-access-token': accessToken,
        'Content-Type': 'application/json',
      },
    );
  }

}
