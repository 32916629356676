import React, {useEffect, useState} from 'react';
import {
    Box,
    Card, CardActionArea, CardActions,
    CardContent, CardMedia, Chip,
    Divider,
    Grid, ListItem, ListItemIcon, ListItemText,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {Skeleton} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import {StorageService} from "../../services/StorageService";
import {CourseService} from "../../services/CourseService";
import {useTranslation} from "react-i18next";
import Vimeo from "@u-wave/react-vimeo";
import {VideoService} from "../../services/VideoService";
import {trackButtonClickEvent, trackPageViewEvent} from "../../utils/tracking";
import {ScheduleService} from "../../services/ScheduleService";
import {LOCALE_DE} from "../../utils/locales";
import dayjs from "dayjs";
import {SectionService} from "../../services/SectionService";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";



const Home = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [courses, setCourses] = useState([]);
    const [videos, setVideos] = useState([]);
    const [upcomingSchedules, setUpcomingSchedules] = useState([]);
    const [sections, setSections] = useState([]);


    const user = StorageService.loadUser();
    const appLocale = StorageService.loadAppLocale();


    useEffect(
        async ()  => {
            trackPageViewEvent('home', '/');

            await getUpcomingSchedules();
            await getSections();
            await getCourses();
            await getVideos();
        },
        [],
    );


    const getCourses = async () => {

        setIsLoading(true);
        const response =  await CourseService.getOnDemandCourses(user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setCourses(data.courses);
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const getVideos = async () => {

        setIsLoading(true);
        const response =  await VideoService.getOnDemandVideos(user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setVideos(data.videos);
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const getUpcomingSchedules = async () => {

        setIsLoading(true);
        const response =  await ScheduleService.getUpcomingSchedules(user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setUpcomingSchedules(data.programSchedules);
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const getSections = async () => {

        setIsLoading(true);
        const response =  await SectionService.getPublishedSections(user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setSections(data.sectionsContent);
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }



    const viewCourse = (uid) => {
        navigate(`/courses/${uid}`);

    }

    const viewVideo = (uid) => {
        navigate(`/videos/${uid}`);

    }

    const viewClasses = () => {
        trackButtonClickEvent('Book a class');
        navigate(`/classes`);
    }

    const viewClass = (upcomingSchedule) => {
        navigate(`/classes/${upcomingSchedule.program.uid}`,
            { state: {
                    ...upcomingSchedule.schedule,
                    start: new Date(upcomingSchedule.schedule.startTime),
                    end: new Date(upcomingSchedule.schedule.endTime),
                    title: upcomingSchedule?.program.name,
                }
            }
        );
    }

    return (
        <React.Fragment>
            <Typography
                variant="h1"
                mb={.25}
                sx={{ fontWeight: 'bold', textTransform:'uppercase' }}
            >
                {t('app.homeLabel')}
            </Typography>
            <Typography variant={'h6'} color={'text.secondary'}>

            </Typography>

            <Divider/>

            <Box mt={5}>

                <Box>
                    {
                        isLoading && (
                            <>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </>
                        )
                    }



                    {!isLoading && (

                        <Box mb={3}>
                            <Grid container spacing={3.75} >
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Box>
                                        <ListItem sx={{marginLeft: -2}}>
                                            <ListItemIcon>
                                                <OndemandVideoIcon color={'secondary'} />
                                            </ListItemIcon>
                                            <ListItemText
                                                sx={{marginLeft: -3}}
                                                primary={
                                                    <Typography variant={'body1'}
                                                                sx={{fontWeight: 'bold'}}>
                                                        {t('app.welcomeVideoLabel')}
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <Card>
                                            <CardActionArea>
                                                <Vimeo
                                                    video={'https://player.vimeo.com/video/1044318739'}
                                                    autopause
                                                    showByline={false}
                                                    showTitle={false}
                                                    autopip
                                                    pip
                                                    responsive={true}
                                                    controls={true}
                                                    speed={true}
                                                />
                                            </CardActionArea>
                                        </Card>

                                    </Box>

                                </Grid>
                            </Grid>
                        </Box>
                    )}

                    {!isLoading && upcomingSchedules?.length > 0 && (

                        <>
                            <Box mb={3}>
                                <Typography variant={'subtitle2'} >
                                    {t('app.upcomingClassesLabel').toUpperCase()}

                                    <Button
                                        onClick={viewClasses}
                                        size={"small"}
                                        sx={{marginLeft: 1}}
                                        color={'secondary'}
                                    >
                                        {t('app.AllClassesLabel')}
                                    </Button>
                                </Typography>
                            </Box>

                            <Grid container spacing={3.75} mb={3}>

                                {upcomingSchedules.map( (upcomingSchedule, index) =>
                                    (
                                        <>
                                            <Grid item key={index} xs={12} sm={6} lg={3}>
                                                <Card
                                                      onClick={() => {viewClass(upcomingSchedule)}}
                                                >
                                                    <CardActionArea>
                                                        <CardMedia
                                                            component="img"
                                                            height="150"
                                                            image={upcomingSchedule.program?.image ? upcomingSchedule.program.image : "https://drehmomentpole.de/wp-content/uploads/2023/11/polo.webp"}
                                                            alt={upcomingSchedule.program.name}
                                                        />
                                                        <CardContent>
                                                            <Typography gutterBottom variant="h5" component="div">
                                                                {appLocale.locale === LOCALE_DE ? upcomingSchedule.program.name : upcomingSchedule.program.name_en}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {dayjs(upcomingSchedule?.schedule?.startTime).format('MMMM D')} {dayjs(upcomingSchedule?.schedule?.startTime).format('HH:mm')} - {dayjs(upcomingSchedule?.schedule?.endTime).format('HH:mm')}
                                                            </Typography>
                                                        </CardContent>
                                                        <CardActions>
                                                            <Button
                                                                variant={"contained"}
                                                                onClick={() => {viewClass(upcomingSchedule)}}
                                                                size={'small'}
                                                            >
                                                                {t('app.bookClassLabel')}
                                                            </Button>
                                                        </CardActions>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        </>
                                    )
                                )}
                            </Grid>

                            <Divider />

                        </>
                    )}

                    {!isLoading && sections?.length > 0 && (

                        sections.map( (sectionContent, index) =>
                                (
                                    <>
                                    <Box mb={3} mt={3}>
                                        <Typography variant={'subtitle2'} >
                                            {appLocale.locale === LOCALE_DE ? sectionContent.section.name?.toUpperCase() :
                                                sectionContent.section.name_en?.toUpperCase()}
                                        </Typography>
                                    </Box>
                                        <Grid container spacing={3.75} mb={3}>
                                            {sectionContent.content.map( (section_content, index) =>
                                        (
                                            section_content?.type === 'course' ? (
                                            <>
                                                <Grid item key={index} xs={12} sm={6} lg={4}>
                                                    <Card onClick={() => {viewCourse(section_content?.content?.uid)}}>
                                                        <CardActionArea>
                                                            {section_content?.content?.videos?.length > 0 && (
                                                                <Vimeo
                                                                    video={section_content?.content?.videos[0].link}
                                                                    autopause
                                                                    showByline={false}
                                                                    showTitle={false}
                                                                    autopip
                                                                    pip
                                                                    responsive={true}
                                                                    controls={false}
                                                                />
                                                            )}
                                                            {section_content?.content?.videos.length < 1 && (
                                                                <CardMedia
                                                                    component="img"
                                                                    height="140"
                                                                    // image={course.image}
                                                                    image={'https://mcusercontent.com/2b90d148d2217420a8235d9fd/images/152ab92c-24df-e5f5-ce82-a049af326b45.jpg'}
                                                                    alt={section_content?.content?.name}
                                                                />
                                                            )}
                                                            <CardContent sx={{height: 50, marginBottom: 15}}>

                                                                <Typography gutterBottom variant="h5" component="div">

                                                                    {/*if locale is german, display german title, if its english, display english title if it exists if not display default german title*/}
                                                                    {appLocale.locale === LOCALE_DE ?
                                                                        section_content?.content?.name :
                                                                        section_content?.content?.name_en ?
                                                                            section_content?.content?.name_en : section_content?.content?.name
                                                                    }


                                                                    {section_content?.content?.isFree === 1 && (
                                                                        <Chip sx={{marginLeft: 1}} label={t('app.freeLabel')} color={'secondary'} size={'small'}/>
                                                                    )}
                                                                </Typography>

                                                                <Typography variant="body2" color="text.secondary">
                                                                    {/*{*/}
                                                                    {/*    section_content?.content?.description.length > 100*/}
                                                                    {/*        ? `${section_content?.content?.description.substring(0, 97)}...`*/}
                                                                    {/*        : section_content?.content?.description*/}
                                                                    {/*}*/}
                                                                    {/*if locale is german, display german description, if its english, display english description if it exists if not display default german description*/}

                                                                    {appLocale.locale === LOCALE_DE ? (
                                                                        section_content?.content?.description?.length > 100
                                                                            ? `${section_content?.content?.description.substring(0, 97)}...`
                                                                            : section_content?.content?.description
                                                                    ) : (
                                                                        section_content?.content?.description_en
                                                                            ? (section_content?.content?.description_en?.length > 100
                                                                                ? `${section_content?.content?.description_en.substring(0, 97)}...`
                                                                                : section_content?.content?.description_en)
                                                                            : (section_content?.content?.description?.length > 100
                                                                                ? `${section_content?.content?.description.substring(0, 97)}...`
                                                                                : section_content?.content?.description)
                                                                    )}
                                                                </Typography>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                            </>

                                            ) : (
                                            <>
                                                <Grid item key={index} xs={12} sm={6} lg={4}>
                                                    <Card onClick={() => {viewVideo(section_content?.content?.uid)}}>
                                                        <CardActionArea>
                                                            <Vimeo
                                                                video={section_content?.content.link}
                                                                autopause
                                                                showByline={false}
                                                                showTitle={false}
                                                                autopip
                                                                pip
                                                                responsive={true}
                                                                controls={false}
                                                            />
                                                            <CardContent sx={{height: 50, marginBottom: 15}}>
                                                                <Typography gutterBottom variant="h5" component="div">
                                                                    {appLocale.locale === LOCALE_DE ?
                                                                        section_content?.content?.name :
                                                                        section_content?.content?.name_en ?
                                                                            section_content?.content?.name_en : section_content?.content?.name
                                                                    }

                                                                    {section_content?.content?.isFree === 1 && (
                                                                        <Chip sx={{marginLeft: 1}} label={t('app.freeLabel')} color={'secondary'} size={'small'}/>
                                                                    )}
                                                                </Typography>

                                                                <Typography variant="body2" color="text.secondary">
                                                                    {appLocale.locale === LOCALE_DE ? (
                                                                        section_content?.content?.description?.length > 100
                                                                            ? `${section_content?.content?.description.substring(0, 97)}...`
                                                                            : section_content?.content?.description
                                                                    ) : (
                                                                        section_content?.content?.description_en
                                                                            ? (section_content?.content?.description_en?.length > 100
                                                                                ? `${section_content?.content?.description_en.substring(0, 97)}...`
                                                                                : section_content?.content?.description_en)
                                                                            : (section_content?.content?.description?.length > 100
                                                                                ? `${section_content?.content?.description.substring(0, 97)}...`
                                                                                : section_content?.content?.description)
                                                                    )}
                                                                </Typography>

                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                            </>

                                            )
                                        )
                                    )}
                                    </Grid>
                                    <Divider />
                                    </>
                        ))
                    )}

                    {!isLoading && courses?.length > 0 && (
                        <>
                            <Box mb={3} mt={3} id="on-demand-courses">
                                <Typography variant={'subtitle2'} >
                                    {t('app.onDemandCoursesLabel').toUpperCase()}

                                    <Button
                                        onClick={() => navigate('/courses')}
                                        size={"small"}
                                        sx={{marginLeft: 1}}
                                        color={'secondary'}
                                    >
                                        {t('app.viewAllLabel')}
                                    </Button>
                                </Typography>
                            </Box>

                        <Grid container spacing={3.75} mb={3}>

                            {courses.map( (course, index) =>
                                (
                                    <>
                                        <Grid item key={index} xs={12} sm={6} lg={4}>
                                            <Card onClick={() => {viewCourse(course.uid)}}>
                                                <CardActionArea>
                                                    {course.videos.length > 0 && (
                                                        <Vimeo
                                                            video={course.videos[0].link}
                                                            autopause
                                                            showByline={false}
                                                            showTitle={false}
                                                            autopip
                                                            pip
                                                            responsive={true}
                                                            controls={false}
                                                        />
                                                    )}
                                                    {course.videos.length < 1 && (
                                                        <CardMedia
                                                            component="img"
                                                            height="140"
                                                            // image={course.image}
                                                            image={'https://mcusercontent.com/2b90d148d2217420a8235d9fd/images/152ab92c-24df-e5f5-ce82-a049af326b45.jpg'}
                                                            alt={course.name}
                                                        />
                                                    )}
                                                    <CardContent sx={{height: 50, marginBottom: 15}}>
                                                        <Typography gutterBottom variant="h5" component="div">

                                                            {appLocale.locale === LOCALE_DE ?
                                                                course.name :
                                                                course?.name_en ?
                                                                    course?.name_en : course?.name
                                                            }

                                                            {course.isFree === 1 && (
                                                                <Chip sx={{marginLeft: 1}} label={t('app.freeLabel')} color={'secondary'} size={'small'}/>
                                                            )}
                                                        </Typography>

                                                        <Typography variant="body2" color="text.secondary">
                                                            {/*{*/}
                                                            {/*    course.description.length > 100*/}
                                                            {/*    ? `${course.description.substring(0, 97)}...`*/}
                                                            {/*    : course.description*/}
                                                            {/*}*/}

                                                            {appLocale.locale === LOCALE_DE ? (
                                                                course.description.length > 100
                                                                    ? `${course.description.substring(0, 97)}...`
                                                                    : course.description
                                                            ) : (
                                                                course?.description_en
                                                                    ? (course?.description_en?.length > 100
                                                                        ? `${course?.description_en.substring(0, 97)}...`
                                                                        : course?.description_en)
                                                                    : (course?.description?.length > 100
                                                                        ? `${course?.description.substring(0, 97)}...`
                                                                        : course?.description)
                                                            )}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>

                                                {/*<CardActions sx={{marginTop:5}}>*/}
                                                {/*    <Button sx={{marginLeft: 0}} size={"small"} variant={'contained'} onClick={() => {viewCourse(course.uid)}}>*/}
                                                {/*        {t('app.viewButtonLabel')}*/}

                                                {/*    </Button>*/}
                                                {/*</CardActions>*/}
                                            </Card>
                                        </Grid>
                                    </>
                                )
                            )}
                        </Grid>

                        <Divider />

                        </>
                    )}


                    {!isLoading && videos?.length > 0 && (

                        <>
                            <Box mb={3} mt={3} id="on-demand-videos">
                                <Typography variant={'subtitle2'} >
                                    {t('app.onDemandVideosLabel').toUpperCase()}

                                    <Button
                                        onClick={() => navigate('/videos')}
                                        size={"small"}
                                        color={'secondary'}
                                        sx={{marginLeft: 1}}
                                    >
                                        {t('app.viewAllLabel')}
                                    </Button>
                                </Typography>
                            </Box>

                            <Grid container spacing={3.75} mb={3}>

                                {videos.map( (video, index) =>
                                    (
                                        <>
                                            <Grid item key={index} xs={12} sm={6} lg={4}>
                                                <Card onClick={() => {viewVideo(video.uid)}}>
                                                    <CardActionArea>
                                                        <Vimeo
                                                            video={video.link}
                                                            autopause
                                                            showByline={false}
                                                            showTitle={false}
                                                            autopip
                                                            pip
                                                            responsive={true}
                                                            controls={false}
                                                        />
                                                        <CardContent sx={{height: 50, marginBottom: 15}}>
                                                            <Typography gutterBottom variant="h5" component="div">
                                                                {appLocale.locale === LOCALE_DE ?
                                                                    video.name :
                                                                    video?.name_en ?
                                                                        video?.name_en : video?.name
                                                                }

                                                                {video.isFree === 1 && (
                                                                    <Chip sx={{marginLeft: 1}} label={t('app.freeLabel')} color={'secondary'} size={'small'}/>
                                                                )}
                                                            </Typography>

                                                            <Typography variant="body2" color="text.secondary">
                                                                {/*{video.description.length > 100*/}
                                                                {/*    ? `${video.description.substring(0, 97)}...`*/}
                                                                {/*    : video.description*/}
                                                                {/*}*/}

                                                                {appLocale.locale === LOCALE_DE ? (
                                                                    video.description.length > 100
                                                                        ? `${video.description.substring(0, 97)}...`
                                                                        : video.description
                                                                ) : (
                                                                    video?.description_en
                                                                        ? (video?.description_en?.length > 100
                                                                            ? `${video?.description_en.substring(0, 97)}...`
                                                                            : video?.description_en)
                                                                        : (video?.description?.length > 100
                                                                            ? `${video?.description.substring(0, 97)}...`
                                                                            : video?.description)
                                                                )}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>

                                                    {/*<CardActions sx={{marginTop: 5}}>*/}
                                                    {/*    <Button sx={{marginLeft: 0}} size={"small"} variant={'contained'} onClick={() => {viewVideo(video.uid)}}>*/}
                                                    {/*        {t('app.viewButtonLabel')}*/}

                                                    {/*    </Button>*/}
                                                    {/*</CardActions>*/}
                                                </Card>
                                            </Grid>
                                        </>
                                    )
                                )}
                            </Grid>
                        </>
                    )}
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default Home;